import { useEffect, useState } from 'react'
import { Button, Card, Container, Table, Modal } from 'react-bootstrap'
import styled from 'styled-components'

import { Advert } from '../../../../../shared/Interfaces'
import { Firestore } from '../../../Firebase/Firebase'
import dayjs from '../../../Helpers/DayjsHelper'
import { AppColors } from '../../../Ressources/AppColors'
import { UiFormTextarea, UiTopContainer } from '../../UI'

const icons = {
  preview: (
    <svg
      aria-hidden="true"
      focusable="false"
      data-prefix="fas"
      data-icon="binoculars"
      className="svg-inline--fa fa-binoculars fa-w-16"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <path
        fill="currentColor"
        d="M416 48c0-8.84-7.16-16-16-16h-64c-8.84 0-16 7.16-16 16v48h96V48zM63.91 159.99C61.4 253.84 3.46 274.22 0 404v44c0 17.67 14.33 32 32 32h96c17.67 0 32-14.33 32-32V288h32V128H95.84c-17.63 0-31.45 14.37-31.93 31.99zm384.18 0c-.48-17.62-14.3-31.99-31.93-31.99H320v160h32v160c0 17.67 14.33 32 32 32h96c17.67 0 32-14.33 32-32v-44c-3.46-129.78-61.4-150.16-63.91-244.01zM176 32h-64c-8.84 0-16 7.16-16 16v48h96V48c0-8.84-7.16-16-16-16zm48 256h64V128h-64v160z"
      ></path>
    </svg>
  ),
  online: (
    <svg
      aria-hidden="true"
      focusable="false"
      data-prefix="fas"
      data-icon="plug"
      className="svg-inline--fa fa-plug fa-w-12"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 384 512"
    >
      <path
        fill="currentColor"
        d="M320,32a32,32,0,0,0-64,0v96h64Zm48,128H16A16,16,0,0,0,0,176v32a16,16,0,0,0,16,16H32v32A160.07,160.07,0,0,0,160,412.8V512h64V412.8A160.07,160.07,0,0,0,352,256V224h16a16,16,0,0,0,16-16V176A16,16,0,0,0,368,160ZM128,32a32,32,0,0,0-64,0v96h64Z"
      ></path>
    </svg>
  ),
  trash: (
    <svg
      aria-hidden="true"
      focusable="false"
      data-prefix="far"
      data-icon="trash-alt"
      className="svg-inline--fa fa-trash-alt fa-w-14"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 448 512"
    >
      <path
        fill="currentColor"
        d="M268 416h24a12 12 0 0 0 12-12V188a12 12 0 0 0-12-12h-24a12 12 0 0 0-12 12v216a12 12 0 0 0 12 12zM432 80h-82.41l-34-56.7A48 48 0 0 0 274.41 0H173.59a48 48 0 0 0-41.16 23.3L98.41 80H16A16 16 0 0 0 0 96v16a16 16 0 0 0 16 16h16v336a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128h16a16 16 0 0 0 16-16V96a16 16 0 0 0-16-16zM171.84 50.91A6 6 0 0 1 177 48h94a6 6 0 0 1 5.15 2.91L293.61 80H154.39zM368 464H80V128h288zm-212-48h24a12 12 0 0 0 12-12V188a12 12 0 0 0-12-12h-24a12 12 0 0 0-12 12v216a12 12 0 0 0 12 12z"
      ></path>
    </svg>
  ),
}

const ManageBanners = () => {
  const emptyAdvert = {
    createdAt: { nanoseconds: 0, seconds: 0 },
    online: false,
    text: '',
  }

  const [isLoading, setIsLoading] = useState(true)

  const [adverts, setAdverts] = useState<Advert[]>([])
  const [advertPreview, setAdvertPreview] = useState<Advert>()
  const [showModal, setShowModal] = useState(false)
  const [newAdvert, setNewAdvert] = useState<Advert>(emptyAdvert)

  const handleOnline = (advert: Advert) => {
    adverts.map((item) => {
      item.online = item.id === advert.id
      Firestore.collection('banners').doc(item.id).set(item)
      setAdvertPreview(advert)
      setAdverts(
        adverts.map((a) => {
          a.online = a.id === advert.id
          return a
        })
      )
    })
  }

  const handleDelete = (advert: Advert) => {
    Firestore.collection('banners')
      .doc(advert.id)
      .delete()
      .then(() => {
        setAdverts(adverts.filter((a) => a.id !== advert.id))
      })
  }

  const handleCreate = () => {
    const now = new Date()
    const item = newAdvert
    item.createdAt.seconds = now.getTime() / 1000
    Firestore.collection('banners')
      .doc()
      .set(item)
      .then(() => {
        setNewAdvert(emptyAdvert)
        setAdverts([...adverts, item])
        setShowModal(false)
      })
  }

  useEffect(() => {
    Firestore.collection('banners')
      .orderBy('online', 'desc')
      .get()
      .then((snapshot) => {
        snapshot.docs.map((item) => {
          const banner = item.data() as Advert
          banner.id = item.id
          banner.online && setAdvertPreview(banner)
          adverts &&
            setAdverts((prevState) => {
              return [...prevState, banner]
            })
        })
      })
      .finally(() => setIsLoading(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <UiTopContainer isLoading={isLoading} contentClassName="w-100 mw-100">
      <Container fluid>
        {!isLoading && (
          <>
            <Card className="mt-2">
              <Card.Header>Preview annonce</Card.Header>
              <Card.Body className="p-3">
                <OnlineAdvert>{advertPreview?.text}</OnlineAdvert>
              </Card.Body>
            </Card>
            <div className="mt-2 d-flex justify-content-end">
              <Button variant="secondary" onClick={() => setShowModal(true)}>
                Ajouter une actu
              </Button>
            </div>
            <ListAdverts striped bordered className="mt-2">
              <tbody>
                {adverts
                  .sort((n1, n2) => {
                    if (n1.createdAt > n2.createdAt && n1.online) {
                      return -1
                    }
                    if (n1.createdAt < n2.createdAt && n2.online) {
                      return 1
                    }
                    return 0
                  })
                  .map((advert, index) => {
                    return (
                      <tr key={`tr-${index}`}>
                        <td className={advert.online ? 'active' : undefined}>
                          #{index}
                        </td>
                        <td>
                          {dayjs(
                            new Date(advert.createdAt.seconds! * 1000)
                          ).format('DD/MM/YYYY')}
                        </td>
                        <td>{advert.text}</td>
                        <td>
                          <div className="d-flex">
                            <span
                              className="preview"
                              onClick={() => setAdvertPreview(advert)}
                            >
                              {icons.preview}
                            </span>
                            <span
                              className={`ml-2 ${
                                advert.online ? 'online' : 'offline'
                              }`}
                              style={advert.online ? {} : { cursor: 'pointer' }}
                              onClick={() =>
                                advert.online === false && handleOnline(advert)
                              }
                            >
                              {icons.online}
                            </span>
                            <span
                              className={`ml-2 trash ${
                                advert.online ? '' : 'active'
                              }`}
                              onClick={() =>
                                advert.online === false && handleDelete(advert)
                              }
                            >
                              {icons.trash}
                            </span>
                          </div>
                        </td>
                      </tr>
                    )
                  })}
              </tbody>
            </ListAdverts>
          </>
        )}
      </Container>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Ajout d'une actualité</Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex flex-column">
          <UiFormTextarea
            name="text"
            label="Contenu de l'actu"
            rows={5}
            value={newAdvert.text}
            onChange={(e) =>
              setNewAdvert({ ...newAdvert, text: e.target.value })
            }
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={handleCreate}
            disabled={newAdvert.text.length === 0}
          >
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </UiTopContainer>
  )
}

export default ManageBanners

const OnlineAdvert = styled(Card.Text)`
  background-color: ${AppColors.green};
  color: #fff;
  padding: 10px;
  border-radius: 10px;
  max-height: 70px;
  overflow-x: auto;
  height: 70px;
`

const ListAdverts = styled(Table)`
  .active {
    background-color: ${AppColors.green};
    color: #fff;
  }

  tbody {
    td {
      span.preview {
        color: ${AppColors.orange};
        width: 30px;
        cursor: pointer;
      }
      span.online {
        color: ${AppColors.green};
        width: 25px;
      }

      span.offline {
        color: ${AppColors.greyDark};
        width: 25px;
      }

      span.trash {
        width: 25px;
        color: ${AppColors.greyDark};
      }

      span.trash.active {
        background-color: unset;
        color: #000;
        cursor: pointer;
      }
    }
  }
`
