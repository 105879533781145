const StatusTitleHelper = (title: string) => {
  switch (title) {
    case 'without':
      return 'Sans réservation'
    case 'pending':
      return 'En attente'
    case 'accepted':
      return 'Validé'
    case 'deferred':
      return 'Reporté'
    case 'finished':
      return 'Terminé'
    case 'canceled':
      return 'Annulé'
    default:
      return 'Tous'
  }
}

const OperationButtonStatusHelper = (title: string) => {
  switch (title) {
    case 'without':
      return 'réserver'
    case 'pending':
      return 'décaler'
    case 'accepted':
      return 'annuler'
    case 'deferred':
      return 'réserver'
    case 'canceled':
      return 'reprogrammer'
    default:
      return ''
  }
}

export { StatusTitleHelper, OperationButtonStatusHelper }
