import { Route, Switch } from 'react-router-dom'

import ManageBanners from '../Components/Pages/Admin/ManageBanners'
import ManageDocuments from '../Components/Pages/Admin/ManageDocuments'
import ManageUsers from '../Components/Pages/Admin/ManageUsers'
import ManageWastesSites from '../Components/Pages/Admin/ManageWastesSites'
import AdminPage from '../Components/Pages/Dashboard/AdminPage'
import { AdminDataProvider } from '../Provider/AdminDataProvider'

export const AdminDashboardRoutes = () => {
  return (
    <AdminDataProvider>
      <Switch>
        <Route exact path="/dashboard">
          <AdminPage />
        </Route>
        <Route exact path="/dashboard/users">
          <ManageUsers />
        </Route>
        <Route exact path="/dashboard/actuality">
          <ManageBanners />
        </Route>
        <Route exact path="/dashboard/documents">
          <ManageDocuments />
        </Route>
        <Route exact path="/dashboard/wastesites">
          <ManageWastesSites />
        </Route>
      </Switch>
    </AdminDataProvider>
  )
}
