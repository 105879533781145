import { useContext, useEffect, useState } from 'react'
import { Container, Button, ListGroup, Modal } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import {
  Booking,
  BookingRequest,
  BookingResponse,
  Worksite,
} from '../../../../../shared/Interfaces'
import { Firestore, Functions, Storage } from '../../../Firebase/Firebase'
import {
  OperationButtonStatusHelper,
  StatusTitleHelper,
} from '../../../Helpers/BookingHelper'
import dayjs from '../../../Helpers/DayjsHelper'
import { showToastError } from '../../../Helpers/ToastHelper'
import { AuthContext } from '../../../Provider/AuthProvider'
import { AppColors } from '../../../Ressources/AppColors'
import { IconAdvert, IconSearch } from '../../../Ressources/AppIcons'
import { UiButton, UiFormControl, UiTopContainer } from '../../UI'

interface Props {
  advert: string
}

const initialFiltersValues = {
  all: true,
  without: false,
  pending: false,
  accepted: false,
  deferred: false,
  finished: false,
  canceled: false,
}

const SiteManagerPage = (props: Props) => {
  const history = useHistory()

  const [reloadData, setReloadData] = useState(true)

  const { user } = useContext(AuthContext)
  const [isLoading, setIsLoading] = useState(true)
  const [filters, setFilters] = useState(initialFiltersValues)
  const [activeFilter, setActiveFilter] = useState('all')
  const [showAdvert, setShowAdvert] = useState<boolean>(
    sessionStorage.getItem('advert') === null ||
      sessionStorage.getItem('advert') === 'true'
  )

  const [initialBookings, setInitialBookings] = useState<Booking[]>([])
  const [bookings, setBookings] = useState<Booking[]>([])
  const [searchValue, setSearchValue] = useState('')

  useEffect(() => {
    const fetchBookings = async () => {
      Firestore.collection('worksites')
        .where('owner', '==', user?.uid)
        .get()
        .then(async (snapshot) => {
          const results = await Promise.all(
            snapshot.docs.map(async (item) => {
              const data = item.data() as BookingResponse
              if (!data) {
                return
              }
              const worksite = item.data() as Worksite
              worksite.uid = item.id
              const requests = await Firestore.collection(
                `worksites/${item.id}/bookings`
              )
                .orderBy('updatedAt', 'desc')
                .get()

              return {
                worksite,
                requests: requests.docs.map((booking) => {
                  const data = booking.data() as BookingRequest
                  data.requestId = booking.id
                  return data
                }),
              }
            })
          )
          const bookings = results.filter((v) => v != null) as Booking[]
          setInitialBookings(bookings)
          setBookings(bookings)
        })
        .catch(() =>
          showToastError(
            'Un problème est survenu durant la récupération des prestations'
          )
        )
        .finally(() => {
          preloadWeeksCloudFunction()
          setIsLoading(false)
        })
    }

    const preloadWeeksCloudFunction = async () => {
      await Functions.httpsCallable('GetBookingAvailableWeeks')({
        wakeUp: 'true',
      })
    }

    fetchBookings().finally(() => setReloadData(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadData])

  useEffect(() => {
    if (activeFilter === 'all') {
      setBookings(initialBookings)
    } else if (activeFilter === 'without') {
      setBookings(
        initialBookings.filter((booking) => booking.requests.length === 0)
      )
    } else {
      setBookings(
        initialBookings.map((booking) => {
          const item = { ...booking }
          const itemRequests = [...item.requests]

          item.requests = itemRequests.filter(
            (request) => request.status === activeFilter
          )

          return item
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeFilter])

  const handleFiltersClick = (key: string, value: boolean) => {
    setFilters({ ...initialFiltersValues, all: false, [key]: value })
    setActiveFilter(key)
  }

  const handleCloseAdvert = () => {
    setShowAdvert(false)
    sessionStorage.setItem('advert', 'false')
  }

  const handlerSearch = (search: string) => {
    const string = search.toLocaleLowerCase()
    setSearchValue(string)
    if (string.length === 0) {
      setBookings(initialBookings)
    } else {
      setBookings(
        initialBookings.filter(
          (booking) =>
            booking.worksite.orderNumber.toLocaleLowerCase().includes(string) ||
            booking.worksite.customerFirstName
              .toLocaleLowerCase()
              .includes(string) ||
            booking.worksite.customerLastname
              .toLocaleLowerCase()
              .includes(string)
        )
      )
    }
  }

  const handlerResetSearch = () => {
    setBookings(initialBookings)
    setFilters(initialFiltersValues)
    setSearchValue('')
  }

  const handlerCancel = (booking: Booking, request: BookingRequest) => {
    setIsLoading(true)
    Functions.httpsCallable('SendMailCancelDemand')({
      worksiteId: booking.worksite.uid,
      requestId: request.requestId,
    })
      .then(() => {
        Firestore.collection(
          `calendar/${request.yearRequested}/S${request.weekRequested}`
        )
          .doc(request.requestId)
          .delete()
        Firestore.collection(`worksites/${booking.worksite.uid}/bookings`)
          .doc(request.requestId)
          .delete()

        Storage.ref(`${booking.worksite.uid}/P${request.phase}/client`)
          .listAll()
          .then((res) => {
            res.items.forEach((itemRef) => itemRef.delete())
          })
      })
      .finally(() => {
        setReloadData(true)
      })
  }

  return (
    <>
      <PageContainer
        showBackground={initialBookings.length === 0 && !isLoading}
        className={`d-flex flex-column w-100 mw-100 ${
          initialBookings.length === 0
            ? 'justify-content-center align-items-center'
            : 'p-0 h-100'
        }`}
        contentClassName="m-0"
        isLoading={isLoading}
      >
        {!isLoading && (
          <>
            {(initialBookings.length === 0 && (
              <div className="d-flex flex-column justify-content-center h-100 p-5">
                <EmptyTitle>
                  Réserver <span>facilement</span> et <span>rapidement</span>{' '}
                  une prestation
                </EmptyTitle>
                <CreateWorkingSiteButton bottom={false} />
              </div>
            )) || (
              <>
                <UiFormControl
                  type="text"
                  name="search"
                  value={searchValue}
                  label=""
                  placeholder="Rechercher nom de chantier, n° de commande"
                  required={false}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    handlerSearch(event.target.value)
                  }
                  withAppend={true}
                  withPrepend={true}
                  prependValue={<IconSearch title="rechercher" />}
                  appendValue={
                    <span style={{ fontWeight: 'bold', cursor: 'pointer' }}>
                      x
                    </span>
                  }
                  appendOnClick={handlerResetSearch}
                  className="pr-1 pl-1"
                />
                <div className="filter-buttons">
                  {Object.entries(filters).map(([key, value]) => {
                    return (
                      <FilterButton
                        title={key}
                        key={key}
                        selected={value}
                        onClick={() =>
                          !value && handleFiltersClick(key, !value)
                        }
                      />
                    )
                  })}
                </div>
                <BookingsList className="mt-3">
                  {bookings.map((booking, index) => {
                    if (
                      activeFilter !== 'all' &&
                      activeFilter !== 'without' &&
                      booking.requests.length === 0
                    ) {
                      return <></>
                    }
                    return (
                      <BookingItem
                        filter={activeFilter}
                        key={`booking-item-${index}`}
                        booking={booking}
                        onWorksiteClick={() =>
                          history.push({
                            pathname: `/worksite/${booking.worksite.uid}/summary`,
                            state: {
                              booking,
                            },
                          })
                        }
                        onBookingClick={(
                          request: BookingRequest | undefined
                        ) => {
                          if (booking.requests.length === 0) {
                            history.push({
                              pathname: `/booking/${booking.worksite.uid}/add`,
                            })
                          } else {
                            history.push(
                              `/booking/${booking.worksite.uid}/phase/${request?.phase}/request/${request?.requestId}/week`
                            )
                          }
                        }}
                        onBookingCancel={(item, request) =>
                          handlerCancel(item, request)
                        }
                      />
                    )
                  })}
                </BookingsList>
              </>
            )}
          </>
        )}
      </PageContainer>
      {initialBookings.length > 0 && <CreateWorkingSiteButton bottom={true} />}
      <Advert
        advert={props.advert}
        show={showAdvert}
        onClose={handleCloseAdvert}
      />
    </>
  )
}

const FilterButton = ({
  title,
  selected,
  onClick,
}: {
  title: string
  selected: boolean
  onClick: () => void
}) => {
  return (
    <UiButton
      element={Button}
      variant="secondary"
      type="button"
      onClick={onClick}
      value={StatusTitleHelper(title)}
      disabled={false}
      className={`filter ${
        selected ? 'filter-active' : 'filter-inactive'
      } p-0 m-1`}
    />
  )
}

const BookingItem = ({
  booking,
  onWorksiteClick,
  onBookingClick,
  onBookingCancel,
  filter,
}: {
  booking: Booking
  onWorksiteClick: () => void
  onBookingClick: (request?: BookingRequest) => void
  onBookingCancel: (booking: Booking, request: BookingRequest) => void
  filter: string
}) => {
  const [showCancelModal, setShowCancelModal] = useState(false)

  return (
    <>
      {(booking.requests.length == 0 && (
        <BookingNoRequest
          booking={booking}
          onWorksiteClick={onWorksiteClick}
          onBookingClick={onBookingClick}
        />
      )) ||
        booking.requests.map(
          (request, index) =>
            (filter === request.status || filter === 'all') && (
              <>
                <BookingWithRequest
                  booking={booking}
                  request={request}
                  onWorksiteClick={onWorksiteClick}
                  onBookingClick={() => onBookingClick(request)}
                  onBookingCancel={() => setShowCancelModal(true)}
                  key={`booking-request-${index}`}
                />
                {showCancelModal && (
                  <CancelModal
                    onConfirm={() => {
                      setShowCancelModal(false)
                      onBookingCancel(booking, request)
                    }}
                    onCloseModal={() => setShowCancelModal(false)}
                    key={`cancel-booking-request-${index}`}
                  />
                )}
              </>
            )
        )}
    </>
  )
}

const CancelModal = ({
  onConfirm,
  onCloseModal,
}: {
  onConfirm: () => void
  onCloseModal: () => void
}) => {
  return (
    <Modal show={true} size="lg" centered>
      <Modal.Header className="d-flex flex-column">
        <Modal.Title>Suppression de la prestation</Modal.Title>
        <Modal.Body>
          Etes vous certain de vouloir annuler cette prestation ? (cette action
          est irréversible)
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-around w-100">
          <Button
            variant="secondary"
            onClick={onCloseModal}
            style={{ width: 100 }}
          >
            NON
          </Button>
          <Button variant="primary" onClick={onConfirm} style={{ width: 100 }}>
            OUI
          </Button>
        </Modal.Footer>
      </Modal.Header>
    </Modal>
  )
}

const BookingWithRequest = ({
  booking,
  request,
  onWorksiteClick,
  onBookingClick,
  onBookingCancel,
}: {
  booking: Booking
  request: BookingRequest
  onWorksiteClick: () => void
  onBookingClick: () => void
  onBookingCancel: () => void
}) => {
  const staffDate = request.staffDate ? request.staffDate.split('/') : undefined
  return (
    <ListGroup.Item>
      <div className="d-flex justify-content-between align-items-center">
        <div
          className="d-flex flex-column left-column"
          onClick={onWorksiteClick}
        >
          <span className="text-break pr-1">
            <span className="badge badge-pill badge-primary mr-2">
              <>{`P${request.phase}`}</>
            </span>
            {booking.worksite.customerFirstName}{' '}
            {booking.worksite.customerLastname}
          </span>
          <span className="text-break pr-1">
            {staffDate
              ? `${dayjs()
                  .set('year', +staffDate[2]!)
                  .set('month', +staffDate[1]!)
                  .set('date', +staffDate[0]!)
                  .subtract(1, 'month')
                  .format('DD/MM/YYYY')}`
              : `Semaine ${request.weekRequested}`}
            {request.status !== 'finished' && (
              <> ({StatusTitleHelper(request.status)})</>
            )}
          </span>
        </div>
        {request.status !== 'finished' && request.status !== 'accepted' && (
          <UiButton
            element={ActionButton}
            variant="linkactions"
            type="button"
            onClick={onBookingClick}
            disabled={false}
            value={OperationButtonStatusHelper(request.status)}
          />
        )}
        {request.status === 'accepted' && (
          <UiButton
            element={ActionButton}
            variant="linkactions"
            type="button"
            onClick={onBookingCancel}
            disabled={
              staffDate
                ? dayjs(`${staffDate[2]}-${staffDate[1]}-${staffDate[0]}`).diff(
                    dayjs(),
                    'day'
                  ) <= 0
                : false
            }
            value="Annuler"
          />
        )}
        {request.status === 'finished' && (
          <UiButton
            element={ActionButtonFinished}
            variant="linkactions finished"
            type="button"
            onClick={() => {}}
            disabled={true}
            value="Terminé"
          />
        )}
      </div>
    </ListGroup.Item>
  )
}

const BookingNoRequest = ({
  booking,
  onWorksiteClick,
  onBookingClick,
}: {
  booking: Booking
  onWorksiteClick: () => void
  onBookingClick: () => void
}) => {
  return (
    <ListGroup.Item>
      <div className="d-flex justify-content-between align-items-center">
        <div
          className="d-flex flex-column left-column"
          onClick={onWorksiteClick}
        >
          <span className="text-break pr-1">
            <span className="badge badge-pill badge-primary mr-2">
              <span className="p-1">-</span>
            </span>
            {booking.worksite.customerFirstName}{' '}
            {booking.worksite.customerLastname}
          </span>
        </div>
        <span className="status" onClick={onBookingClick}>
          Réserver
        </span>
      </div>
    </ListGroup.Item>
  )
}

const CreateWorkingSiteButton = ({ bottom }: { bottom: boolean }) => {
  const history = useHistory()
  return (
    <UiButton
      element={bottom ? CreateButton : EmptyListCreateButton}
      variant="secondary"
      type="button"
      onClick={() => history.push('/worksite/add')}
      disabled={false}
      value="Créer une fiche chantier"
      className={'text-uppercase btn-full'}
    />
  )
}

export const Advert = ({
  advert,
  show,
  onClose,
}: {
  advert: string
  show: boolean
  onClose: () => void
}) =>
  (show && (
    <AdvertContainer className="d-flex flex-row align-items-center w-100 mw-100 position-relative">
      <span className="close-advert position-absolute" onClick={onClose}>
        x
      </span>
      <div className="d-flex align-items-start">
        <IconAdvert title="annonce" />
      </div>
      <span className="text mt-1 mb-2 w-80 d-flex justify-content-center align-items-center">
        {advert}
      </span>
    </AdvertContainer>
  )) || <></>

export default SiteManagerPage

const PageContainer = styled(UiTopContainer)<{ showBackground: boolean }>`
  background-image: ${(props) =>
    props.showBackground ? 'url(/images/background_HP.png)' : 'none'};
  background-size: cover;
  background-repeat: no-repeat;

  .content {
    padding-right: 10px;
    padding-left: 10px;
    margin-right: auto;
    margin-left: auto;
  }

  .filter {
    width: 135px;
    border-radius: 10px;
    line-height: 16px;
    flex: 0 0 auto;
  }

  .filter-active {
    background-color: ${AppColors.orange} !important;
    border: unset;
  }
  .filter-inactive {
    background-color: ${AppColors.greyDark} !important;
    color: #000;
    border: unset;
  }

  .filter-buttons {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
  }

  .status {
    border: 1px solid ${AppColors.orange};
    color: ${AppColors.orange};
    font-weight: bold;
    padding: 5px;
    border-radius: 5px;
    text-transform: uppercase;
    width: 120px;
    text-align: center;
    font-size: 12px;
    cursor: pointer;
  }

  .status-end {
    border: 1px solid ${AppColors.green};
    color: ${AppColors.green};
  }

  .status-new {
    border-color: ${AppColors.greyDark};
  }
`
const EmptyTitle = styled.h1`
  color: #fff;

  span {
    font-weight: bold;
  }
`

const BookingsList = styled(ListGroup)`
  .list-group-item {
    padding: 5px 10px;
    border: 0px;
    .left-column {
      cursor: pointer;
    }
  }
`

const EmptyListCreateButton = styled(Button)`
  border-radius: 4px;
  margin-top: 22px;
`

const CreateButton = styled(Button)`
  flex: 0 0 auto;
  height: 50px;
`

const ActionButton = styled(Button)`
  font-weight: bold;
  padding: 5px;
  border-radius: 5px;
  text-transform: uppercase;
  width: 120px;
  text-align: center;
  font-size: 12px;

  :disabled {
    border: 1px solid ${AppColors.orange};
  }
`

const ActionButtonFinished = styled(ActionButton)`
  border: 1px solid ${AppColors.green};
  color: ${AppColors.green};

  :disabled {
    border: 1px solid ${AppColors.green};
  }
`

const AdvertContainer = styled(Container)`
  background-color: ${AppColors.green};
  color: #fff;

  svg {
    width: 40px;
  }

  .text {
    padding-left: 15px;
    overflow-x: auto;
    height: 70px;
    max-height: 70px;
    width: 85%;
  }

  .close-advert {
    right: 7px;
    top: 3px;
    color: #fff;
    cursor: pointer;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
  }
`
