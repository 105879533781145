import { useContext, useState, useEffect } from 'react'
import { Image, Container, Button, Alert, Row } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { Auth } from '../../Firebase/Firebase'
import { AuthContext } from '../../Provider/AuthProvider'
import { AppColors } from '../../Ressources/AppColors'
import { UiFormControl, UiButton } from '../UI'

interface UserData {
  email: string
  password: string
}

export const LoginPage = () => {
  const authContext = useContext(AuthContext)
  const history = useHistory()
  const [values, setValues] = useState<UserData>({
    email: '',
    password: '',
  })
  const [formIsValid, setFormIsValid] = useState(false)
  const [lostPassword, setLostPassword] = useState(false)
  const [lostPasswordSend, setPasswordSend] = useState(false)

  const [error, setError] = useState<string | undefined>(authContext.error)

  useEffect(() => {
    setFormIsValid(
      !lostPassword
        ? emailIsValid(values.email) && values.password.length > 0
        : emailIsValid(values.email)
    )
  }, [lostPassword, values])

  const emailIsValid = (email: string) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist()
    setError(undefined)
    setValues((values) => ({
      ...values,
      [event.target.name]: event.target.value,
    }))
  }

  const handleSubmit = (event: any) => {
    event.preventDefault()
    setError(undefined)
    Auth.signInWithEmailAndPassword(values.email, values.password)
      .then((res) => {
        authContext.setUser(res)
        history.push('/dashboard')
      })
      .catch((_error) => {
        setError("Erreur lors de l'authentification")
      })
  }

  const handleResetSubmit = (event: any) => {
    event.preventDefault()
    Auth.sendPasswordResetEmail(values.email).then(() => {
      setLostPassword(false)
      setPasswordSend(true)
    })
  }

  return (
    <LoginContainer className="d-flex justify-content-center align-items-center">
      <Container>
        <Row className="d-flex align-items-center mt-5 mb-5">
          <div className="col-sm col-md">
            <LogoImage
              src="/images/logo.png"
              className="logo mx-auto d-block img-thumbnail"
            />
          </div>
          <div className="col-sm col-md">
            {error && <Alert variant="danger">{error}</Alert>}
            {lostPasswordSend && (
              <Alert variant="success">
                Si votre adresse e-mail existe, un lien de réinitialisation vous
                à été envoyé
              </Alert>
            )}
            <UiFormControl
              type="email"
              name="email"
              value={values.email}
              label="Adresse e-mail"
              placeholder="Adresse e-mail"
              onChange={handleChange}
              required={true}
            />
            {!lostPassword && (
              <UiFormControl
                type="password"
                name="password"
                value={values.password}
                label="Mot de passe"
                placeholder="Mot de passe"
                onChange={handleChange}
                required={true}
              />
            )}
            <span
              className="lost-password d-flex flex-row-reverse mb-2 pr-1"
              onClick={() => setLostPassword(true)}
            >
              J'ai perdu mon mot de passe
            </span>
            <UiButton
              element={SubmitButton}
              variant="secondary"
              type="submit"
              onClick={(e) =>
                lostPassword ? handleResetSubmit(e) : handleSubmit(e)
              }
              disabled={!formIsValid}
              value={`${!lostPassword ? 'CONNEXION' : 'VALIDER'}`}
            />
            {lostPassword && (
              <UiButton
                variant="primary"
                type="button"
                onClick={() => {
                  setPasswordSend(false)
                  setLostPassword(false)
                }}
                disabled={false}
                value="ANNULER"
                className="w-100 mt-2"
              />
            )}
          </div>
        </Row>
      </Container>
    </LoginContainer>
  )
}

const LoginContainer = styled(Container)`
  background-image: url('/images/background_authentification.png');
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;

  @media (min-width: 720px) {
    background-image: url('/images/background_authentification_full.png');
  }

  .container {
    background-color: #fff;
    border-radius: 8px;
    max-width: 400px;

    .row {
      flex-direction: column;
    }

    .lost-password {
      cursor: pointer;
      color: ${AppColors.green};
    }
  }

  label {
    font-weight: bold;
  }
`

export const LogoImage = styled(Image)`
  border: unset;
  width: 300px;
  @media (max-width: 1023.9px) {
    margin-bottom: 50px;
    padding: unset;
    border: unset;
    border-radius: unset;
  }
`
const SubmitButton = styled(Button)`
  width: 100%;
`
