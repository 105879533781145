import { useContext } from 'react'
import { Alert } from 'react-bootstrap'
import { Link, Route, RouteProps } from 'react-router-dom'

import { Role } from '../../../shared/Interfaces'
import { AuthContext } from '../Provider/AuthProvider'

interface Props extends RouteProps {
  roles: Role[]
}

const RouteFilter = (props: Props) => {
  const { authenticated, appUser } = useContext(AuthContext)

  if (!authenticated || !appUser) {
    return null
  }

  if (!props.roles.includes(appUser.role)) {
    return (
      <Alert variant="danger">
        Vous n'êtes pas autorisé à accéder à cette page,{' '}
        <Link to="/dashboard">retour</Link>
      </Alert>
    )
  }

  return <Route {...props} />
}

export default RouteFilter
