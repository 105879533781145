import { Redirect, Route, Switch } from 'react-router-dom'

import {
  BookingPage,
  DashboardPage,
  NotFoundPage,
  AddWorksitePage,
} from '../Components/Pages'
import BookingWeekPage from '../Components/Pages/Booking/BookingWeekPage'
import MyAccountPage from '../Components/Pages/MyAccount/MyAccountPage'
import RequestSummaryPage from '../Components/Pages/Team/RequestSummaryPage'
import SummaryWorksitePage from '../Components/Pages/Worksite/SummaryWorksitePage'

import RouteFilter from './RouteFilter'

const ApplicationRoutes = () => (
  <Switch>
    <Route exact path="/dashboard*">
      <DashboardPage />
    </Route>
    <RouteFilter exact path="/account" roles={['site-manager']}>
      <MyAccountPage />
    </RouteFilter>
    <RouteFilter exact path="/worksite/add" roles={['site-manager']}>
      <AddWorksitePage />
    </RouteFilter>
    <RouteFilter
      exact
      path="/worksite/:worksiteId/summary"
      roles={['site-manager']}
    >
      <SummaryWorksitePage />
    </RouteFilter>
    <RouteFilter exact path="/booking/:worksiteId/add" roles={['site-manager']}>
      <BookingPage />
    </RouteFilter>
    <RouteFilter
      exact
      path="/booking/:worksiteId/phase/:phaseId/request/:requestId/week"
      roles={['site-manager']}
    >
      <BookingWeekPage />
    </RouteFilter>
    <RouteFilter
      exact
      path="/worksite/:worksiteId/request/:requestId/summary"
      roles={['team']}
    >
      <RequestSummaryPage />
    </RouteFilter>
    <Route exact path="/">
      <Redirect to="/dashboard" />
    </Route>
    <Route path="*">
      <NotFoundPage />
    </Route>
  </Switch>
)

export default ApplicationRoutes
