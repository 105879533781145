import React, { useContext } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import { LoginPage, MaintenanceMode } from '../Components/Pages'
import { UiLoader } from '../Components/UI'
import { AuthContext } from '../Provider/AuthProvider'

import ApplicationRoutes from './ApplicationRoutes'

const Routes = () => {
  const { authenticated, loadingAuthState, appUser, user, isMaintenanceMode } =
    useContext(AuthContext)

  if (loadingAuthState || (!appUser && user)) {
    return <UiLoader />
  }

  if (isMaintenanceMode) {
    return <MaintenanceMode />
  }
  if (!authenticated) {
    return (
      <Switch>
        <Route exact path="/auth">
          <LoginPage />
        </Route>
        <Route path="/">
          <Redirect to="/auth" />
        </Route>
      </Switch>
    )
  }

  return <ApplicationRoutes />
}

export default Routes
