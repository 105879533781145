import { useContext, useEffect, useState } from 'react'
import {
  Button,
  Card,
  Container,
  ListGroup,
  Modal,
  Tab,
  Tabs,
} from 'react-bootstrap'
import { useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { v4 as uuidv4 } from 'uuid'

import { Timestamp } from '../../../../../shared/@types/firebase-timestamp'
import {
  AppUser,
  AppUserRequest,
  BookingRequest,
  Company,
  Worksite,
} from '../../../../../shared/Interfaces'
import { Auth, Firestore, Functions, Storage } from '../../../Firebase/Firebase'
import { resizeImage } from '../../../Helpers/ImageHelper'
import { showToastError, showToastSuccess } from '../../../Helpers/ToastHelper'
import { getTimestamp } from '../../../Helpers/Various'
import { AuthContext } from '../../../Provider/AuthProvider'
import { AppColors } from '../../../Ressources/AppColors'
import { IconCheck } from '../../../Ressources/AppIcons'
import {
  UiButton,
  UiButtonFile,
  UiFormInput,
  UiFormSelect,
  UiFormTextarea,
  UiHeader,
  UiPhotoContainer,
  UiTopContainer,
} from '../../UI'
import UIWorksiteAndRequestor from '../../UI/UIWorksiteAndRequestor'

interface WasteSite {
  name: string
  address: string
  id: string
}

interface Waste {
  name: string
  quantity: number
  site?: string
}

interface RouteParams {
  worksiteId: string
  requestId: string
}

const RequestSummaryPage = () => {
  const history = useHistory()
  const { requestId, worksiteId } = useParams<RouteParams>()

  const { appUser, authenticated } = useContext(AuthContext)
  const [isLoading, setIsLoading] = useState(true)
  const [selectedTab, setSelectedTab] = useState('info')

  const [worksite, setWorksite] = useState<Worksite>()
  const [booking, setBooking] = useState<BookingRequest>()
  const [siteManager, setSiteManager] = useState<AppUser>()
  const [clientImages, setClientImages] = useState<string[]>([])

  const [teamImages, setTeamImages] = useState<File[]>([])
  const [teamImagesUrl, setTeamImagesUrl] = useState<
    { name: string; url: string }[]
  >([])

  const [showModal, setShowModal] = useState(false)

  const [wastes, setWastes] = useState<Waste[]>([])
  const [wasteSites, setWasteSites] = useState<WasteSite[]>([
    {
      id: '',
      name: 'Choisir une déchèterie',
      address: '',
    } as WasteSite,
  ])

  const [wastListIsValid, setWastListIsValid] = useState(false)

  const [teamNote, setTeamNote] = useState('')

  const [isSubmit, setIsSubmit] = useState(false)

  const [wasteList, setWasteList] = useState<string[]>([])

  const wastesAreValid = () => {
    let isValid = true

    wastes.map((w) => {
      if (isValid) {
        if (
          w.quantity <= 0 ||
          !w.site ||
          w.site?.length === 0 ||
          w.site?.toLocaleLowerCase() === 'choisir une déchèterie'
        ) {
          isValid = false
        }
      }
    })
    setWastListIsValid(wastes.length === 0 ? false : isValid)
  }

  useEffect(() => {
    wastesAreValid()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wastes])

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()
    if (!event.target || !event.target.files) {
      return
    }
    const file = event.target.files[0]
    let resizedImage = file

    const image = new Image()
    image.src = URL.createObjectURL(file)

    image.onload = async () => {
      if (image.width > 1024) {
        const height = (1024 * image.height) / image.width
        resizedImage = await resizeImage(file, 1024, height)
      }

      setTeamImages([...teamImages, resizedImage])
      setTeamImagesUrl([
        ...teamImagesUrl,
        { name: file.name, url: URL.createObjectURL(resizedImage) },
      ])

      const fileExt = file.name.split('.').pop()
      const fileId = uuidv4()
      const ref = Storage.ref(
        `${worksiteId}/P${booking?.phase}/team/${fileId}.${fileExt}`
      )
      ref.put(resizedImage).catch(() => {
        showToastError(
          'Un problème est survenu durant la sauvegarde des photos'
        )
      })
    }
  }

  const deleteImage = (url: string) => {
    const image = teamImagesUrl.find((image) => image.url === url)
    setTeamImagesUrl(teamImagesUrl.filter((item) => item != image))
    setTeamImages(teamImages.filter((item) => item.name != image?.name))

    Storage.refFromURL(url).delete()
  }

  const handleWastesSubmit = (wasteItems?: Waste[]) => {
    const data: Waste[] = []
    const items = wasteItems ? wasteItems : wastes

    items.map((w) => {
      const site = wasteSites.find((s) => s.name === w.site)
      data.push({
        name: w.name,
        quantity: w.quantity,
        site: site?.id,
      })
    })

    if (data.length > 0) {
      Firestore.collection('wastes')
        .doc(requestId)
        .set({ wastes: data })
        .then(() => {
          setShowModal(false)
        })
        .catch(() =>
          showToastError(
            'Un problème est survenu durant la sauvegarde des dechets'
          )
        )
    } else {
      Firestore.collection('wastes')
        .doc(requestId)
        .delete()
        .catch(() =>
          showToastError(
            'Un problème est survenu durant la suppression des dechets'
          )
        )
    }
  }

  const handleWasteDelete = (waste: Waste) => {
    const items = wastes.filter((w) => w.name != waste.name)
    setWastes(items)
    handleWastesSubmit(items)
  }

  const handleFinishRequest = async () => {
    setIsLoading(true)
    setIsSubmit(true)
    await Functions.httpsCallable('GeneratePdf')({
      worksiteId,
      wasteListId: requestId,
      teamId: appUser?.id,
      phase: booking?.phase,
    }).then((response) => {
      setIsLoading(false)
      if (response.data === 'DONE') {
        const bookingUpdate: {
          status: string
          teamNote?: string
          updatedAt: Timestamp
        } = {
          status: 'finished',
          updatedAt: getTimestamp(),
        }
        if (teamNote.length > 0) {
          bookingUpdate.teamNote = teamNote
        }

        Firestore.collection(`worksites/${worksiteId}/bookings`)
          .doc(requestId)
          .update(bookingUpdate)
        showToastSuccess(
          wastes.length > 0
            ? 'Le rapport à été généré et le client prévenu  de la fin de la prestation'
            : 'Le client à été prévenu de la fin de la prestation'
        )
        booking && setBooking({ ...booking, status: 'finished', teamNote })
      } else {
        showToastError('Impossible de générer le rapport')
      }
    })
  }

  useEffect(() => {
    if (requestId && worksiteId) {
      Firestore.collection('worksites')
        .doc(worksiteId)
        .get()
        .then(async (doc) => {
          const worksite = doc.data() as Worksite
          setWorksite(worksite)
          const requestDoc = await Firestore.collection(
            `worksites/${worksiteId}/bookings`
          )
            .doc(requestId)
            .get()
          const request = requestDoc.data() as BookingRequest
          setBooking(request)

          const siteManagerDoc = await Firestore.collection('users')
            .doc(request.owner)
            .get()
          const siteManagerData = siteManagerDoc.data() as AppUserRequest

          const companyDoc = await Firestore.collection('companies')
            .doc(siteManagerData.company!)
            .get()

          setSiteManager({
            ...siteManagerData,
            company: companyDoc.data() && (companyDoc.data() as Company),
          })

          const storageRef = Storage.ref(
            `${worksiteId}/P${request.phase}/client`
          )
          storageRef.listAll().then((res) => {
            res.items.forEach((itemRef) => {
              itemRef
                .getDownloadURL()
                .then((url) => {
                  setClientImages((imagesUrl) => {
                    return [...imagesUrl, url]
                  })
                })
                .catch(() =>
                  showToastError(
                    // eslint-disable-next-line quotes
                    "Un problème est survenu durant la récupération de l'une des photos"
                  )
                )
            })
          })

          const storageRefTeam = Storage.ref(
            `${worksiteId}/P${request.phase}/team`
          )
          storageRefTeam.listAll().then((res) => {
            res.items.forEach((itemRef) => {
              itemRef
                .getDownloadURL()
                .then((url) => {
                  setTeamImages((imagesUrl) => {
                    return [...imagesUrl, url]
                  })
                  setTeamImagesUrl((teamImagesUrl) => {
                    return [...teamImagesUrl, { name: itemRef.name, url }]
                  })
                })
                .catch(() =>
                  showToastError(
                    // eslint-disable-next-line quotes
                    "Un problème est survenu durant la récupération de l'une des photos"
                  )
                )
            })
          })

          setIsLoading(false)
        })
        .catch(() =>
          showToastError(
            'Un problème est survenu durant la récupération des chantiers'
          )
        )
    }
  }, [requestId, worksiteId])

  useEffect(() => {
    setIsLoading(true)
    const fetchData = async () => {
      Firestore.collection('waste-sites')
        .where('active', '==', true)
        .get()
        .then(async (snapshot) => {
          const results = await Promise.all(
            snapshot.docs.map((item) => {
              const data = item.data() as WasteSite
              if (!data) {
                return
              }

              data.id = item.id

              return data
            })
          )

          const items = results.filter((v) => v != null) as WasteSite[]
          setWasteSites((prevState) => {
            return prevState.concat(
              items.sort((a1, b1) => {
                if (a1.name < b1.name) {
                  return -1
                }
                if (a1.name > b1.name) {
                  return 1
                }
                return 0
              })
            )
          })

          await fetchWastes(items)
          await fetchWasteList()
        })
        .catch(() =>
          showToastError(
            'Un problème est survenu durant la récupération des déchetteries'
          )
        )
    }

    const fetchWastes = async (wasteSites: WasteSite[]) => {
      await Firestore.doc(`wastes/${requestId}`)
        .get()
        .then((snapshot) => {
          const data = snapshot.data() as { wastes: Waste[] }
          if (!data) {
            return
          }
          data.wastes.map((item) => {
            const site = wasteSites.find((w) => w.id === item.site)
            setWastes((prevState) => [
              ...prevState,
              { name: item.name, quantity: item.quantity, site: site?.name },
            ])
          })
        })
        .catch(() =>
          showToastError(
            'Un problème est survenu durant la récupération des dechets'
          )
        )
    }

    const fetchWasteList = async () => {
      const items = await Firestore.collection('job-data')
        .doc('waste-items')
        .get()

      const wastes = items.data()

      wastes && setWasteList(wastes.items as string[])
    }

    fetchData().then(() => setIsLoading(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <UiHeader
        isDashboardHeader={false}
        isAuthenticated={authenticated}
        history={history}
        role={appUser?.role}
        firesbaseAuth={Auth}
        title={`Chantier de ${worksite?.customerFirstName} ${worksite?.customerLastname}`}
        callbackHandler={() => history.goBack()}
      />
      <UiTopContainer isLoading={isLoading}>
        <TabsContainer
          defaultActiveKey={selectedTab}
          onSelect={(k: any) => setSelectedTab(k)}
        >
          <Tab eventKey="info" title="Informations">
            <div className="d-flex flex-column">
              <span className="bg-grey mb-2">PHASE {booking?.phase}</span>
              <UiPhotoContainer
                imagesUrl={clientImages}
                containerHeight={150}
              />
              {booking && booking.note && (
                <span className="m-3">
                  <b>Remarque du client</b> : {booking.note}
                </span>
              )}
              {booking && booking.staffNote && (
                <span className="m-3">
                  <b>Remarque de Servibat</b> : {booking.staffNote}
                </span>
              )}
              {worksite && (
                <UIWorksiteAndRequestor
                  worksite={worksite}
                  siteManager={siteManager}
                  siteManagerFull={false}
                  rounded={false}
                  bordered={false}
                  accordionClassName="mt-2"
                />
              )}
            </div>
          </Tab>
          <Tab eventKey="worksite" title="Fiche chantier">
            <div className="d-flex flex-column">
              <span className="bg-grey mb-2">FICHE CHANTIER</span>
              <UiFormTextarea
                name="note"
                label="Remarques (facultatif)"
                rows={2}
                className="pr-2 pl-2"
                value={teamNote}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  booking?.status != 'finished'
                    ? setTeamNote(e.target.value)
                    : undefined
                }
                readonly={booking?.status === 'finished'}
              />
              <div className="m-2">
                {booking?.status != 'finished' && (
                  <UiButtonFile
                    name="photo"
                    label=""
                    buttonTitle="Ajouter des photos"
                    onChange={handleFileChange}
                  />
                )}
                <UiPhotoContainer
                  imagesUrl={teamImagesUrl.map((image) => image.url)}
                  onDeleteImage={
                    booking?.status != 'finished' ? deleteImage : undefined
                  }
                  className="mb-2 mt-2"
                />
              </div>
            </div>
            <div className="d-flex flex-column">
              <span className="bg-grey">DECHETS</span>
              {booking?.status != 'finished' && booking?.phase! < 6 && (
                <UiButton
                  variant="outline-secondary"
                  type="button"
                  onClick={() => setShowModal(true)}
                  disabled={false}
                  value="sélectionner les déchets"
                  className="text-uppercase m-2"
                />
              )}
              <WasteSummary
                wastes={wastes}
                onDeleteHandler={(waste) => handleWasteDelete(waste)}
                isFinished={booking?.status === 'finished'}
              />
              <Modal
                show={showModal}
                onHide={() => setShowModal(false)}
                centered
                size="xl"
                fullscreen="true"
                scrollable={true}
              >
                <WaterModalHeader closeButton>
                  <Modal.Title>Sélectionner les déchets</Modal.Title>
                </WaterModalHeader>
                <WasteModalBody>
                  <div className="buttons-group">
                    <WasteButtonsManager
                      wasteList={wasteList}
                      wastes={wastes}
                      setWastes={(wastes: Waste[]) => setWastes(wastes)}
                    />
                  </div>
                  <div className="list-group mt-2">
                    <WasteListManager
                      wastes={wastes}
                      setWastes={(waste: Waste[]) => setWastes(waste)}
                      wasteSites={wasteSites}
                    />
                  </div>
                </WasteModalBody>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={() => handleWastesSubmit()}
                    disabled={!wastListIsValid}
                  >
                    Valider
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          </Tab>
        </TabsContainer>
      </UiTopContainer>
      {selectedTab === 'worksite' && booking?.status != 'finished' && (
        <Container className="d-flex flex-column pr-0 pl-0 w-100 mw-100">
          <UiButton
            variant="secondary"
            type="button"
            onClick={handleFinishRequest}
            disabled={isSubmit}
            value={'Envoyer au client'}
            className="btn-full text-uppercase"
          />
        </Container>
      )}
    </>
  )
}

const WasteButtonsManager = ({
  wasteList,
  wastes,
  setWastes,
}: {
  wasteList: string[]
  wastes: Waste[]
  setWastes: (waste: Waste[]) => void
}) => {
  return (
    <>
      {wasteList.map((item, index) => {
        const actualWaste = wastes.find((w) => w.name === item)
        return (
          <Button
            key={`waste-button-${index}`}
            className="d-flex align-items-center text-capitalize"
            onClick={() => {
              if (actualWaste) {
                setWastes(wastes.filter((w) => w.name != item))
              } else {
                setWastes([...wastes, { name: item, quantity: 0 }])
              }
            }}
            variant={`${
              wastes.find((w) => w.name === item) ? 'secondary' : 'link'
            }`}
          >
            {actualWaste && <IconCheck title="" />}
            {item}
          </Button>
        )
      })}
    </>
  )
}

const WasteListManager = ({
  wastes,
  setWastes,
  wasteSites,
}: {
  wastes: Waste[]
  setWastes: (wastes: Waste[]) => void
  wasteSites: { name: string; address: string }[]
}) => {
  return (
    <>
      {wastes.map((item, index) => {
        return (
          <Card className="mb-2" key={`waste-card-${index}`}>
            <Card.Header>{item.name}</Card.Header>
            <Card.Body>
              <Card.Text className="d-flex justify-content-between align-items-center">
                Quantité (KG)
                <WasteQtContainer>
                  <UiFormInput
                    type="number"
                    name="quantity"
                    value={item.quantity.toString()}
                    placeholder="Saisissez la quantité"
                    required={false}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setWastes(
                        wastes.map((w) => {
                          if (w.name === item.name) {
                            return {
                              name: item.name,
                              quantity: +e.target.value,
                              site: item.site,
                            }
                          }
                          return w
                        })
                      )
                    }}
                    inputKey={`quantity-${item.name}`}
                  />
                </WasteQtContainer>
              </Card.Text>
              <Card.Text className="d-flex justify-content-between align-items-center">
                Décheterie
                <WasteSelect
                  name="wasteSite"
                  label=""
                  options={wasteSites.map((item) => {
                    const numberPattern = /\d+/g
                    const postalCode = item.address
                      .match(numberPattern)
                      ?.join('|')
                      .split('|')
                      .pop()
                    const city = item.address.split(postalCode!).pop()
                    return {
                      key: `${item.name} ${
                        postalCode ? `(${postalCode} ${city})` : ''
                      }`,
                      value: item.name,
                    }
                  })}
                  selectedOption={item.site}
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                    const site = e.target.value.split('(')[0].trim()
                    setWastes(
                      wastes.map((w) => {
                        if (w.name === item.name) {
                          return {
                            name: item.name,
                            quantity: item.quantity,
                            site,
                          }
                        }
                        return w
                      })
                    )
                  }}
                  className="ml-2"
                />
              </Card.Text>
            </Card.Body>
          </Card>
        )
      })}
    </>
  )
}

const WasteSummary = ({
  wastes,
  onDeleteHandler,
  isFinished,
}: {
  wastes: Waste[]
  onDeleteHandler: (waste: Waste) => void
  isFinished: boolean
}) => {
  return (
    (wastes.length > 0 && (
      <>
        <ListGroup className="m-2">
          {wastes.map((w, index) => {
            return (
              w.quantity &&
              w.name &&
              w.site && (
                <ListGroup.Item
                  className="d-flex justify-content-between align-items-center pl-2 pr-2"
                  key={`waste-summary-${index}`}
                >
                  <div
                    className="d-flex"
                    style={{ width: isFinished ? '100%' : '85%' }}
                  >
                    <span
                      className="d-flex align-items-center text-capitalize mr-2"
                      style={{
                        width: '50%',
                      }}
                    >
                      {w.name} ({w.quantity} kg)
                    </span>
                    <span
                      className="d-flex align-items-center"
                      style={{
                        width: '50%',
                      }}
                    >
                      {w.site}
                    </span>
                  </div>
                  {!isFinished && (
                    <div className="ml-auto ml-2">
                      <Button
                        variant="danger"
                        style={{ minHeight: 'unset' }}
                        onClick={() => onDeleteHandler(w)}
                      >
                        X
                      </Button>
                    </div>
                  )}
                </ListGroup.Item>
              )
            )
          })}
        </ListGroup>
      </>
    )) || <></>
  )
}

export default RequestSummaryPage

const TabsContainer = styled(Tabs)`
  width: 100%;
  .nav-link {
    width: 50%;
    text-align: center;
    border: 0px !important;
    color: #000;
    text-transform: uppercase;
  }

  .nav-link.active {
    border-bottom: 2px solid ${AppColors.green} !important;
    color: ${AppColors.green};
  }
`

const WaterModalHeader = styled(Modal.Header)`
  background-color: ${AppColors.green};
  color: #fff;
`

const WasteModalBody = styled(Modal.Body)`
  .buttons-group {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;

    button {
      flex: 0 0 auto;
      margin-right: 5px;
      border-radius: 10px;
      padding: 0 20px;

      svg {
        margin-right: 8px;
      }
    }

    button.btn-link {
      background-color: ${AppColors.greyDark};
    }
  }
`

const WasteQtContainer = styled.div`
  width: 100px;
`
const WasteSelect = styled(UiFormSelect)`
  margin-bottom: 0;
`
