import { useContext, useState } from 'react'
import { Card, ListGroup } from 'react-bootstrap'
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import dayjs from '../../../Helpers/DayjsHelper'
import { TeamDataContext } from '../../../Provider/TeamDataProvider'
import { AppColors } from '../../../Ressources/AppColors'
import { UiButton, UiTopContainer } from '../../UI'

import { Advert } from './SiteManagerPage'

interface Props {
  advert: string
}

const TeamPage = (props: Props) => {
  const {
    appUsers,
    activeUser,
    setActiveUser,
    activeDate,
    setActiveDate,
    agenda,
  } = useContext(TeamDataContext)
  const teams = appUsers.filter((user) => user.role === 'team')
  const history = useHistory()
  const [showAdvert, setShowAdvert] = useState<boolean>(
    sessionStorage.getItem('advert') === null ||
      sessionStorage.getItem('advert') === 'true'
  )

  const handleCloseAdvert = () => {
    setShowAdvert(false)
    sessionStorage.setItem('advert', 'false')
  }

  return (
    <>
      <UiTopContainer className="ml-1 mr-1">
        <TeamsList horizontal className="rounded-0">
          {teams.map((user, index) => {
            return (
              <ListGroup.Item
                key={`team-list-item-${index}`}
                onClick={() => setActiveUser(user)}
                className={`border-0 rounded-0 ${
                  activeUser?.id === user.id && 'user-active'
                }`}
              >
                {user.lastName.toUpperCase()} {user.firstName[0].toUpperCase()}.
              </ListGroup.Item>
            )
          })}
        </TeamsList>
        <Calendar
          value={activeDate}
          onChange={setActiveDate}
          className="w-100 border-0"
          locale="fr-FR"
        />
        <Card>
          <Card.Body>
            <Card.Title className="text-capitalize">
              {dayjs(activeDate!).format('dddd DD MMMM YYYY')}
            </Card.Title>
            <PhasesList>
              {(agenda.length > 0 &&
                agenda.map((item, index) => {
                  return (
                    <UiButton
                      variant={
                        item.lockReason
                          ? 'danger'
                          : item.request.status === 'finished'
                          ? 'primary'
                          : 'light'
                      }
                      type="button"
                      onClick={() => {
                        if (!item.lockReason) {
                          history.push(
                            `/worksite/${item.worksite.uid}/request/${item.request.requestId}/summary`
                          )
                        }
                      }}
                      disabled={false}
                      value={
                        item.lockReason
                          ? item.lockReason
                          : `Phase ${item.request.phase}`
                      }
                      key={`calendar-button-${index}`}
                    />
                  )
                })) || <span>Aucune phase de programmée aujourd'hui</span>}
            </PhasesList>
          </Card.Body>
        </Card>
      </UiTopContainer>
      <Advert
        advert={props.advert}
        show={showAdvert}
        onClose={handleCloseAdvert}
      />
    </>
  )
}

export default TeamPage

const TeamsList = styled(ListGroup)`
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  .list-group-item {
    flex: 0 0 auto;
  }
  .user-active {
    color: ${AppColors.green};
    border-bottom: 2px solid ${AppColors.green} !important;
  }
`

const PhasesList = styled(Card.Text)`
  margin-top: 10px;
  button {
    margin: 0 5px 5px 0;
  }
`
