import styled from 'styled-components'

import { AppColors } from '../../Ressources/AppColors'

const UiLoader = () => (
  <LoaderContainer className="overlay">
    <div className="overlay__inner">
      <div className="overlay__content">
        <span className="spinner"></span>
      </div>
    </div>
  </LoaderContainer>
)

export default UiLoader

const LoaderContainer = styled.div`
  .overlay {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    background: #fff;
  }

  .overlay__inner {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
  }

  .overlay__content {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .spinner {
    width: 75px;
    height: 75px;
    display: inline-block;
    border-width: 2px;
    border-color: rgba(255, 255, 255, 0.05);
    border-top-color: ${AppColors.green};
    animation: spin 1s infinite linear;
    border-radius: 100%;
    border-style: solid;
  }

  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
`
