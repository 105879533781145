import { useEffect, useState } from 'react'
import { Container, Table, Button, Card } from 'react-bootstrap'
import styled from 'styled-components'
import { v4 as uuidv4 } from 'uuid'

import { ServibatDocument } from '../../../../../shared/Interfaces'
import { Firestore, Storage } from '../../../Firebase/Firebase'
import { showToastError } from '../../../Helpers/ToastHelper'
import { AppColors } from '../../../Ressources/AppColors'
import { UiButtonFile, UiFormControl, UiTopContainer } from '../../UI'

const icons = {
  preview: (
    <svg
      aria-hidden="true"
      focusable="false"
      data-prefix="fas"
      data-icon="binoculars"
      className="svg-inline--fa fa-binoculars fa-w-16"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <path
        fill="currentColor"
        d="M416 48c0-8.84-7.16-16-16-16h-64c-8.84 0-16 7.16-16 16v48h96V48zM63.91 159.99C61.4 253.84 3.46 274.22 0 404v44c0 17.67 14.33 32 32 32h96c17.67 0 32-14.33 32-32V288h32V128H95.84c-17.63 0-31.45 14.37-31.93 31.99zm384.18 0c-.48-17.62-14.3-31.99-31.93-31.99H320v160h32v160c0 17.67 14.33 32 32 32h96c17.67 0 32-14.33 32-32v-44c-3.46-129.78-61.4-150.16-63.91-244.01zM176 32h-64c-8.84 0-16 7.16-16 16v48h96V48c0-8.84-7.16-16-16-16zm48 256h64V128h-64v160z"
      ></path>
    </svg>
  ),
  trash: (
    <svg
      aria-hidden="true"
      focusable="false"
      data-prefix="far"
      data-icon="trash-alt"
      className="svg-inline--fa fa-trash-alt fa-w-14"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 448 512"
    >
      <path
        fill="currentColor"
        d="M268 416h24a12 12 0 0 0 12-12V188a12 12 0 0 0-12-12h-24a12 12 0 0 0-12 12v216a12 12 0 0 0 12 12zM432 80h-82.41l-34-56.7A48 48 0 0 0 274.41 0H173.59a48 48 0 0 0-41.16 23.3L98.41 80H16A16 16 0 0 0 0 96v16a16 16 0 0 0 16 16h16v336a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128h16a16 16 0 0 0 16-16V96a16 16 0 0 0-16-16zM171.84 50.91A6 6 0 0 1 177 48h94a6 6 0 0 1 5.15 2.91L293.61 80H154.39zM368 464H80V128h288zm-212-48h24a12 12 0 0 0 12-12V188a12 12 0 0 0-12-12h-24a12 12 0 0 0-12 12v216a12 12 0 0 0 12 12z"
      ></path>
    </svg>
  ),
  edit: (
    <svg
      aria-hidden="true"
      focusable="false"
      data-prefix="far"
      data-icon="edit"
      className="svg-inline--fa fa-edit fa-w-18"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 576 512"
    >
      <path
        fill="currentColor"
        d="M402.3 344.9l32-32c5-5 13.7-1.5 13.7 5.7V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V112c0-26.5 21.5-48 48-48h273.5c7.1 0 10.7 8.6 5.7 13.7l-32 32c-1.5 1.5-3.5 2.3-5.7 2.3H48v352h352V350.5c0-2.1.8-4.1 2.3-5.6zm156.6-201.8L296.3 405.7l-90.4 10c-26.2 2.9-48.5-19.2-45.6-45.6l10-90.4L432.9 17.1c22.9-22.9 59.9-22.9 82.7 0l43.2 43.2c22.9 22.9 22.9 60 .1 82.8zM460.1 174L402 115.9 216.2 301.8l-7.3 65.3 65.3-7.3L460.1 174zm64.8-79.7l-43.2-43.2c-4.1-4.1-10.8-4.1-14.8 0L436 82l58.1 58.1 30.9-30.9c4-4.2 4-10.8-.1-14.9z"
      ></path>
    </svg>
  ),
  upload: (
    <svg
      aria-hidden="true"
      focusable="false"
      data-prefix="fas"
      data-icon="file-upload"
      className="svg-inline--fa fa-file-upload fa-w-12"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 384 512"
    >
      <path
        fill="currentColor"
        d="M224 136V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm65.18 216.01H224v80c0 8.84-7.16 16-16 16h-32c-8.84 0-16-7.16-16-16v-80H94.82c-14.28 0-21.41-17.29-11.27-27.36l96.42-95.7c6.65-6.61 17.39-6.61 24.04 0l96.42 95.7c10.15 10.07 3.03 27.36-11.25 27.36zM377 105L279.1 7c-4.5-4.5-10.6-7-17-7H256v128h128v-6.1c0-6.3-2.5-12.4-7-16.9z"
      ></path>
    </svg>
  ),
}

const ManageDocuments = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [reloadData, setReloadData] = useState(false)
  const [documents, setDocuments] = useState<ServibatDocument[]>([])
  const [showLeftPanel, setShowLeftPanel] = useState(false)
  const [editedDocument, setEditedDocument] = useState<ServibatDocument>()

  const handleOnEditOrCreate = (document?: ServibatDocument) => {
    setShowLeftPanel(true)
    setEditedDocument(document)
  }
  const handleOnDelete = (document: ServibatDocument) => {
    setIsLoading(true)
    Storage.ref(`docs-servibat/${document.name}`)
      .delete()
      .then(() => {
        Firestore.collection('internal-docs')
          .doc(document.id)
          .delete()
          .then(() => {
            setReloadData(true)
          })
          .catch(() => {
            setIsLoading(false)
            showToastError(
              'Un problème est survenu durant la suppression du document'
            )
          })
      })
      .catch(() => {
        setIsLoading(false)
        showToastError(
          'Un problème est survenu durant la suppression du fichier'
        )
      })
  }

  useEffect(() => {
    const fetchDocuments = async () => {
      Firestore.collection('internal-docs')
        .get()
        .then(async (snapshot) => {
          const documents: ServibatDocument[] = []
          const results = await Promise.all(
            snapshot.docs.map(async (item) => {
              const data = item.data() as ServibatDocument
              if (!data) {
                return
              }

              const fileExt = data.name.split('.').pop()
              const clearName = data.title.split(' ').join('_').toLowerCase()
              data.clearName = `${clearName}.${fileExt}`
              data.id = item.id
              await Storage.ref(`docs-servibat/${data.name}`)
                .getDownloadURL()
                .then((url: string) => {
                  data.url = url
                })
              return data
            })
          )
          results.forEach((result) => {
            if (result) {
              documents.push(result)
            }
          })
          setDocuments(documents)
        })
        .catch(() =>
          showToastError(
            'Un problème est survenu durant la récupération des documents'
          )
        )
        .finally(() => {
          setIsLoading(false)
          setReloadData(false)
        })
    }

    fetchDocuments()
  }, [reloadData])

  return (
    <>
      <UiTopContainer isLoading={isLoading} contentClassName="w-90 mw-90 pt-3">
        <Container fluid>
          {!isLoading && (
            <div className="d-flex">
              {showLeftPanel && (
                <DocumentAddOrEdit
                  document={editedDocument}
                  onClosePanel={() => {
                    setEditedDocument(undefined)
                    setShowLeftPanel(false)
                  }}
                  reloadData={(value: boolean) => setReloadData(value)}
                />
              )}
              <DocumentsTable
                documents={documents}
                handleOnEditOrCreate={handleOnEditOrCreate}
                handleOnDelete={handleOnDelete}
                thereIsDocumentEdited={!editedDocument}
              />
            </div>
          )}
        </Container>
      </UiTopContainer>
    </>
  )
}

const DocumentsTable = ({
  documents,
  handleOnEditOrCreate,
  handleOnDelete,
  thereIsDocumentEdited,
}: {
  documents: ServibatDocument[]
  handleOnEditOrCreate: (document?: ServibatDocument) => void
  handleOnDelete: (document: ServibatDocument) => void
  thereIsDocumentEdited: boolean
}) => {
  return (
    <div className="d-flex flex-column w-100">
      <div className="d-flex justify-content-end">
        <Button
          variant="secondary"
          onClick={() => handleOnEditOrCreate()}
          disabled={!thereIsDocumentEdited}
        >
          Ajouter un document
        </Button>
      </div>
      <ListDocuments className="mt-2" striped bordered>
        <thead>
          <th>#</th>
          <th>Nom</th>
          <th>Actions</th>
        </thead>
        <tbody>
          {documents.map((document, index) => {
            return (
              <tr key={`tr-${index}`}>
                <td>{index + 1}</td>
                <td>{document.title}</td>
                <td>
                  <div className="d-flex">
                    <span className="preview">
                      <a
                        href={document.url}
                        target="_blank"
                        title={document.title}
                      >
                        {icons.preview}
                      </a>
                    </span>
                    <span
                      className="ml-2 edit"
                      onClick={() => handleOnEditOrCreate(document)}
                    >
                      {icons.edit}
                    </span>
                    <span
                      className={'ml-2 trash'}
                      onClick={() => handleOnDelete(document)}
                    >
                      {icons.trash}
                    </span>
                  </div>
                </td>
              </tr>
            )
          })}
        </tbody>
      </ListDocuments>
    </div>
  )
}

const DocumentAddOrEdit = ({
  document,
  onClosePanel,
  reloadData,
}: {
  document?: ServibatDocument
  onClosePanel: () => void
  reloadData: (value: boolean) => void
}) => {
  const emptyDocument: ServibatDocument = {
    name: '',
    clearName: '',
    title: '',
    url: undefined,
  }

  const [currentDocument, setCurrentDocument] = useState<ServibatDocument>(
    document ? document : emptyDocument
  )
  const [file, setFile] = useState<File>()
  const [isSubmit, setIsSubmit] = useState(false)

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()
    if (!event.target || !event.target.files) {
      return
    }
    const file = event.target.files[0]
    const ext = file.name.split('.').pop()
    const uid = uuidv4()
    const storageFile = new File([file], `${uid}.${ext}`)
    setFile(storageFile)
  }

  const handleSave = () => {
    if (file) {
      setIsSubmit(true)
      const ref = Storage.ref(`docs-servibat/${file.name}`)
      ref
        .put(file)
        .then((snapshot) => {
          if (snapshot.ref) {
            Firestore.collection('internal-docs')
              .doc(currentDocument.id)
              .set({
                name: file.name,
                title: currentDocument.title,
              })
              .then(() => {
                onClosePanel()
                reloadData(true)
              })
              .catch(() => {
                showToastError(
                  "Une erreur c'est produite durant la sauvegarde du fichier"
                )
              })
          }
        })
        .catch(() => {
          showToastError(
            "Une erreur c'est produite durant la sauvegarde du fichier"
          )
        })
    } else {
      Firestore.collection('internal-docs')
        .doc(currentDocument.id)
        .update({
          title: currentDocument.title,
        })
        .then(() => {
          onClosePanel()
          reloadData(true)
        })
        .catch(() => {
          showToastError(
            "Une erreur c'est produite durant la sauvegarde du fichier"
          )
        })
    }
  }

  return (
    <div
      className="d-flex mr-4"
      style={{ minHeight: 300, width: '40%', marginTop: 55 }}
    >
      <Card className="w-100">
        <Card.Header>
          {(!document && "Ajout d'un document") ||
            `Edition de ${currentDocument?.title}`}
        </Card.Header>
        <Card.Text className="m-3">
          <UiFormControl
            type="text"
            name="name"
            value={currentDocument?.title || ''}
            label="Nom du document"
            onChange={(e) =>
              setCurrentDocument({ ...currentDocument, title: e.target.value })
            }
            required={true}
            placeholder="Nom qui apparaitra"
          />
          <UiButtonFile
            name="file"
            label=""
            buttonTitle="Sélectionner"
            onChange={handleFileChange}
          />
          {file && file.name && (
            <span>
              <b>Ref :</b> {file?.name.split('.')[0]}
            </span>
          )}
          <Button
            className="w-100 mt-5"
            onClick={handleSave}
            disabled={
              (!currentDocument.id && (!currentDocument.title || !file)) ||
              isSubmit
            }
          >
            {isSubmit
              ? 'Veuillez patienter...'
              : document?.url
              ? 'Mettre à jour'
              : 'Valider'}
          </Button>
          <Button
            className="w-100 mt-1"
            variant="secondary"
            onClick={onClosePanel}
            disabled={isSubmit}
          >
            {document?.url ? 'Fermer' : 'Annuler'}
          </Button>
        </Card.Text>
      </Card>
    </div>
  )
}

export default ManageDocuments

const ListDocuments = styled(Table)`
  tbody {
    td {
      span.preview {
        a {
          color: ${AppColors.orange};
        }

        width: 30px;
        cursor: pointer;
      }

      span.trash {
        width: 25px;
        cursor: pointer;
        color: ${AppColors.red};
      }

      span.edit {
        width: 33px;
        color: ${AppColors.green};
        cursor: pointer;
      }
    }
  }
`
