import React from 'react'
import { Button } from 'react-bootstrap'

interface Props {
  element?: React.ElementType
  variant: string
  type: string
  onClick: (event: any) => void
  disabled: boolean
  value: string | JSX.Element
  className?: string
}

const UiButton = (props: Props) => {
  return (
    <Button
      as={props.element && props.element}
      variant={props.variant}
      type={props.type}
      onClick={props.onClick}
      disabled={props.disabled}
      className={props.className}
    >
      {props.value}
    </Button>
  )
}

export default UiButton
