import React from 'react'

interface Props {
  title: string
  width?: string
  height?: string
}

const AppIconAccount = (props: Props) => (
  <svg
    width={props.width || '16'}
    height={props.height || '20'}
    viewBox="0 0 16 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>{props.title}</title>
    <path
      d="M16 19.0909C16 19.593 15.602 20 15.1111 20C14.6202 20 14.2222 19.593 14.2222 19.0909C14.2222 15.5764 11.4364 12.7273 8 12.7273C4.56356 12.7273 1.77778 15.5764 1.77778 19.0909C1.77778 19.593 1.37981 20 0.888888 20C0.397969 20 0 19.593 0 19.0909C0 14.5722 3.58172 10.9091 8 10.9091C12.4183 10.9091 16 14.5722 16 19.0909ZM8 10C5.29994 10 3.11111 7.76142 3.11111 5C3.11111 2.23858 5.29994 0 8 0C10.7001 0 12.8889 2.23858 12.8889 5C12.8889 7.76142 10.7001 10 8 10ZM8 8.18182C9.71821 8.18182 11.1111 6.75727 11.1111 5C11.1111 3.24273 9.71821 1.81818 8 1.81818C6.28178 1.81818 4.88889 3.24273 4.88889 5C4.88889 6.75727 6.28178 8.18182 8 8.18182Z"
      fill="white"
    />
  </svg>
)

const AppIconBack = (props: Props) => (
  <svg
    width={props.width || '24'}
    height={props.height || '24'}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>{props.title}</title>
    <path
      d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12Z"
      fill="white"
    />
    <path
      d="M12 8L8 12L12 16"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

const IconCheck = (props: Props) => (
  <svg
    width={props.width || '14'}
    height={props.height || '14'}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>{props.title}</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14ZM10.8536 5.35355C11.0488 5.15829 11.0488 4.84171 10.8536 4.64645C10.6583 4.45118 10.3417 4.45118 10.1464 4.64645L6.5 8.29289L4.85355 6.64645C4.65829 6.45118 4.34171 6.45118 4.14645 6.64645C3.95118 6.84171 3.95118 7.15829 4.14645 7.35355L6.14645 9.35355C6.34171 9.54882 6.65829 9.54882 6.85355 9.35355L10.8536 5.35355Z"
      fill="white"
    />
  </svg>
)

const IconDownload = (props: Props) => (
  <svg
    width={props.width || '20'}
    height={props.height || '18'}
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>{props.title}</title>
    <path
      d="M11.2437 3.41421V13C11.2437 13.5523 10.796 14 10.2437 14C9.69142 14 9.2437 13.5523 9.2437 13V3.41417L6.7081 5.94971C6.31757 6.34023 5.6844 6.34023 5.29388 5.9497C4.90337 5.55917 4.90337 4.926 5.2939 4.53548L9.5366 0.292885C9.92713 -0.0976315 10.5603 -0.0976278 10.9508 0.292893L15.1934 4.53549C15.5839 4.92602 15.5839 5.55918 15.1934 5.94971C14.8029 6.34023 14.1697 6.34023 13.7792 5.94971L11.2437 3.41421ZM0 11C0 10.4477 0.447715 10 1 10C1.55228 10 2 10.4477 2 11C2 11.9757 2.00051 14.3872 2.001 15C2.001 15.5515 2.44948 16 3.001 16H17.001C17.5526 16 18.001 15.5516 18.001 15V11C18.001 10.4477 18.4487 10 19.001 10C19.5533 10 20.001 10.4477 20.001 11V15C20.001 16.6561 18.6572 18 17.001 18H3.001C1.34492 18 0.00100008 16.6561 0.0010004 15.0008C0.000507464 14.3881 0 11.9762 0 11Z"
      fill="#FF8A00"
    />
  </svg>
)

const IconAdvert = (props: Props) => (
  <svg
    width={props.width || '40'}
    height={props.height || '40'}
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>{props.title}</title>
    <g clipPath="url(#clip0)">
      <path
        d="M13.9967 1.3124C17.3893 1.3124 20.5718 2.63136 22.967 5.02648C25.3621 7.42161 26.681 10.6107 26.681 13.9967C26.681 16.3722 26.0183 18.6885 24.7715 20.6965L24.4697 21.1821L24.614 21.7333L25.6705 25.6705L21.7333 24.614L21.1821 24.4697L20.6965 24.7715C18.6885 26.0248 16.3722 26.6876 13.9967 26.6876C10.6107 26.6876 7.42161 25.3686 5.02648 22.9735C2.63136 20.5718 1.3124 17.3893 1.3124 13.9967C1.3124 10.6107 2.63136 7.42161 5.02648 5.02648C7.42161 2.63136 10.6107 1.3124 13.9967 1.3124ZM13.9967 0C6.2667 0 0 6.2667 0 13.9967C0 21.7333 6.2667 27.9934 13.9967 27.9934C16.7134 27.9934 19.2463 27.2191 21.3921 25.8805L27.5275 27.521L25.887 21.3921C27.2257 19.2463 28 16.7134 28 13.9967C28 6.2667 21.7333 0 13.9967 0Z"
        fill="white"
      />
      <path
        d="M13.9967 5.7811C14.9679 5.7811 15.7553 6.56854 15.7553 7.53971C15.7553 8.51088 14.9679 9.29832 13.9967 9.29832C13.0255 9.29832 12.2381 8.51088 12.2381 7.53971C12.2381 6.56854 13.0255 5.7811 13.9967 5.7811Z"
        fill="white"
      />
      <path
        d="M17.0743 20.7228H10.9257V18.0848H12.2381V12.8156H10.9257V10.1776H15.7553V12.8156V18.0848H17.0743V20.7228Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="28" height="28" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

const IconSearch = (props: Props) => (
  <svg
    width={props.width || '20'}
    height={props.height || '20'}
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>{props.title}</title>
    <circle cx="5.5" cy="5.5" r="3" stroke="black" />
    <path
      d="M7.70709 8L9.5 9.79289"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

const IconBookingCross = (props: Props) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>{props.title}</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM31.0607 15.3464C31.6464 14.7606 31.6464 13.8108 31.0607 13.2251C30.4749 12.6393 29.5251 12.6393 28.9393 13.2251L18.5714 23.593L13.9178 18.9393C13.332 18.3536 12.3823 18.3536 11.7965 18.9393C11.2107 19.5251 11.2107 20.4749 11.7965 21.0607L17.5108 26.7749C18.0966 27.3607 19.0463 27.3607 19.6321 26.7749L31.0607 15.3464Z"
      fill="#009A2E"
    />
  </svg>
)

const IconSelectorLeft = (props: Props) => (
  <svg
    width={props.width || '6'}
    height={props.width || '8'}
    viewBox="0 0 6 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>{props.title}</title>
    <path
      d="M5 1L1 4L5 7"
      stroke="#FF8A00"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

const IconSelectorRight = (props: Props) => (
  <svg
    width={props.width || '6'}
    height={props.width || '8'}
    viewBox="0 0 6 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>{props.title}</title>
    <path
      d="M1 7L5 4L1 1"
      stroke="#FF8A00"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

const IconLogout = (props: Props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>{props.title}</title>
    <path
      d="M6.76637 6C5.25315 6.97531 4.10493 8.39942 3.49287 10.0601C2.8808 11.7207 2.83765 13.529 3.36986 15.2155C3.90206 16.9019 4.98112 18.3761 6.4462 19.4185C7.91129 20.4608 9.68396 21.0154 11.5 20.9997C13.316 21.0154 15.0887 20.4608 16.5538 19.4185C18.0189 18.3761 19.0979 16.9019 19.6301 15.2155C20.1623 13.529 20.1192 11.7207 19.5071 10.0601C18.8951 8.39942 17.7469 6.97531 16.2336 6"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 4V12"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export {
  AppIconAccount,
  AppIconBack,
  IconCheck,
  IconDownload,
  IconAdvert,
  IconSearch,
  IconBookingCross,
  IconSelectorLeft,
  IconSelectorRight,
  IconLogout,
}
