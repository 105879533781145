/* eslint-disable react-hooks/rules-of-hooks */
import firebase from 'firebase/app'

import firebaseConfig from '../firebaseConfig.json'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'
import 'firebase/storage'

const Firebase = firebase.initializeApp(firebaseConfig)

function initializeAuth(): firebase.auth.Auth {
  if (!Firebase.auth) {
    throw new Error('Something must be missing in firebase.')
  }

  return Firebase.auth()
}

firebase.firestore().settings({
  ignoreUndefinedProperties: true,
})

export const Auth = initializeAuth()
export const Firestore = Firebase.firestore()
export const Storage = Firebase.storage()
export const ReportStorage = Firebase.storage('servibat-reports')

export const Functions = Firebase.functions('europe-west1')
if (process.env.REACT_APP_USE_EMULATORS) {
  // Add here if any other emulators are needed.
  console.warn('Firebase started in emulator mode')
  Functions.useEmulator('localhost', 5001)
  Firestore.useEmulator('localhost', 8080)
  Auth.useEmulator('http://localhost:9099')
}
