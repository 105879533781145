import { useContext, useEffect, useState } from 'react'
import { Accordion, Button, Card, Container, Modal } from 'react-bootstrap'
import { useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components'

import { BookingRequest } from '../../../../../shared/Interfaces'
import { Auth, Firestore, Functions } from '../../../Firebase/Firebase'
import dayjs from '../../../Helpers/DayjsHelper'
import { showToastError } from '../../../Helpers/ToastHelper'
import { getTimestamp } from '../../../Helpers/Various'
import { AuthContext } from '../../../Provider/AuthProvider'
import { AppColors } from '../../../Ressources/AppColors'
import { IconBookingCross } from '../../../Ressources/AppIcons'
import { UiButton, UiHeader, UiTopContainer } from '../../UI'

interface RouteParams {
  worksiteId: string
  requestId: string
}

const BookingWeekPage = () => {
  const history = useHistory()

  const { worksiteId, requestId } = useParams<RouteParams>()

  const { appUser, authenticated } = useContext(AuthContext)

  const [weeks, setWeeks] = useState<any[]>()
  const [isLoading, setIsLoading] = useState(true)
  const [weekSelected, setWeekSelected] = useState<number | undefined>()
  const [yearSelected, setYearSelected] = useState<number | undefined>()
  const [booking, setBooking] = useState<BookingRequest>()
  const [showModal, setShowModal] = useState(false)
  const [buttonIsActive, setButtonIsActive] = useState(false)
  const [isDeferralDate, setIsDeferralDate] = useState(false)

  const weeksGetter = async (year: string, minimalWeek?: string) => {
    setIsLoading(true)
    const response = await Functions.httpsCallable('GetBookingAvailableWeeks')({
      year,
      slots: booking?.phase === 5 ? '5' : '1',
      minimalWeek,
    })

    setWeeks(response.data)
    setIsLoading(false)
  }

  useEffect(() => {
    if (requestId) {
      Firestore.collection(`worksites/${worksiteId}/bookings/`)
        .doc(requestId)
        .get()
        .then((doc) => {
          const data = doc.data() as BookingRequest
          setBooking(data)
          setWeekSelected(data.weekRequested)
          setYearSelected(data.yearRequested)
          setIsDeferralDate(
            data.weekRequested !== undefined && data.yearRequested !== undefined
          )
          // TODO : Calculer la bonne semaine suivant le précédent booking.
          //const minimalWeek = (dayjs().week() + 5).toString()
          weeksGetter(
            data.yearRequested
              ? data.yearRequested.toString()
              : dayjs().get('year').toString()
            //minimalWeek
          ).then(() => setIsLoading(false))
        })
        .catch(() =>
          showToastError(
            'Un problème est survenu durant la récupération des prestations'
          )
        )
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestId, worksiteId])

  const handleWeekSelected = (year: number, week: number) => {
    setYearSelected(year)
    setWeekSelected(week)
    setButtonIsActive(true)
  }

  const handleYearChanged = (year: number) => {
    setYearSelected(year)
    setWeekSelected(undefined)
    weeksGetter(year.toString())
    setButtonIsActive(false)
  }

  const handleSubmit = async () => {
    if (yearSelected && weekSelected) {
      setButtonIsActive(false)
      setIsLoading(true)
      await Firestore.collection(`worksites/${worksiteId}/bookings`)
        .doc(requestId)
        .update({
          yearRequested: yearSelected,
          weekRequested: weekSelected,
          status: 'pending',
          updatedAt: getTimestamp(),
        })
        .catch(() =>
          showToastError(
            'Un problème est survenu durant la mise à jour de la prestation'
          )
        )

      const doc = Firestore.collection('calendar')
        .doc(yearSelected.toString())
        .collection(`S${weekSelected}`)
        .doc(requestId)

      if (doc.id) {
        doc.delete().catch(() =>
          showToastError(
            // eslint-disable-next-line quotes
            "Un problème est survenu lors de la suppression de l'ancienne demande"
          )
        )
      }
      Firestore.collection('calendar')
        .doc(yearSelected.toString())
        .collection(`S${weekSelected}`)
        .doc(requestId)
        .set({ worksite: worksiteId, lock: false, createdAt: getTimestamp() })
        .catch(() =>
          showToastError(
            'Un problème est survenu durant la planification de la prestation'
          )
        )

      Functions.httpsCallable('SendMailNewDemand')({
        worksiteId,
        week: weekSelected.toString(),
        isDeferralDate,
      })

      setIsLoading(false)
      setShowModal(true)
    }
  }

  return (
    <>
      <UiHeader
        isDashboardHeader={false}
        isAuthenticated={authenticated}
        history={history}
        role={appUser?.role}
        firesbaseAuth={Auth}
        title="Choisir une semaine"
      />
      <UiTopContainer className="pr-0 pl-0 w-100 mw-100" isLoading={isLoading}>
        <AccordionContainer
          defaultActiveKey={yearSelected?.toString() || dayjs().format('YYYY')}
          className="mb-1"
        >
          {Array.from(
            { length: 3 },
            (_x, i) => +dayjs().add(i, 'year').format('YYYY')
          ).map((year, yearIndex) => {
            return (
              <Card key={yearIndex}>
                <Accordion.Toggle
                  as={Card.Header}
                  eventKey={year.toString()}
                  onClick={() => {
                    handleYearChanged(year)
                  }}
                >
                  {year}
                </Accordion.Toggle>
                <Accordion.Collapse eventKey={year.toString()}>
                  <Card.Body className="p-0">
                    {weeks?.map(
                      (
                        month: { date: dayjs.Dayjs; weeks: number[] },
                        weekIndex
                      ) => {
                        if (!month) {
                          return
                        }
                        return (
                          <div className="d-flex flex-column" key={weekIndex}>
                            <p
                              className="w-100 mw-100 p-2 mb-0"
                              style={{ backgroundColor: '#eee' }}
                            >
                              {dayjs(month.date).format('MMMM')}
                            </p>
                            <ButtonContainer className="m-2">
                              {month.weeks.map((week: number, weekIndex) => {
                                return (
                                  <Button
                                    key={weekIndex}
                                    variant="link"
                                    className={`mr-1 mb-1 ${
                                      weekSelected === week ? 'selected' : ''
                                    }`}
                                    onClick={() =>
                                      handleWeekSelected(year, week)
                                    }
                                  >
                                    Semaine {week}
                                  </Button>
                                )
                              })}
                            </ButtonContainer>
                          </div>
                        )
                      }
                    )}
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            )
          })}
        </AccordionContainer>
      </UiTopContainer>
      <ActionButtons className="d-flex flex-column m-0 p-0 mw-100">
        <UiButton
          element={Button}
          variant="secondary"
          type="button"
          onClick={handleSubmit}
          disabled={!buttonIsActive}
          value="Valider la réservation"
          className="text-uppercase btn-full"
        />
      </ActionButtons>
      <ModalContainer show={showModal} centered size="lg">
        <Modal.Body>
          <IconBookingCross title="fermer" />
          <p className="phase">
            Phase {booking?.phase} en attente de validation
          </p>
          <p className="summary">
            Nous avons bien pris en compte la réservation.
            <br />
            <br />
            Nous reviendrons prochainement vers vous pour vous proposer une date
            en semaine {weekSelected} de {yearSelected}.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <UiButton
            element={Button}
            variant="primary"
            type="button"
            onClick={() => history.push('/dashboard')}
            disabled={false}
            value="D’accord"
            className="text-uppercase w-100"
          />
        </Modal.Footer>
      </ModalContainer>
    </>
  )
}

export default BookingWeekPage

const AccordionContainer = styled(Accordion)`
  .card-header {
    cursor: pointer;
  }
`

const ButtonContainer = styled.div`
  button {
    min-width: 110px;
    color: #000;
    text-decoration: none;
    :hover,
    :focus {
      text-decoration: none;
    }
  }

  .selected {
    background-color: ${AppColors.orange};
    color: #fff;
  }
`
const ActionButtons = styled(Container)`
  flex: 0 0 auto;
  background-color: #fff;
`
const ModalContainer = styled(Modal)`
  .modal-content {
    height: 80vh;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;

    .modal-body {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .phase {
        color: ${AppColors.green};
        margin-top: 30px;
        font-weight: bold;
      }

      .summary {
        text-align: center;
      }
    }
  }
`
