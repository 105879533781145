import './Ressources/scss/app.scss'
import { BrowserRouter as Router } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import { AuthProvider } from './Provider/AuthProvider'
import { Routes } from './Routes'

function App() {
  return (
    <Router>
      <AuthProvider>
        <div className="App">
          <Routes />
          <ToastContainer />
        </div>
      </AuthProvider>
    </Router>
  )
}

export default App
