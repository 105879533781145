import { useContext, useState } from 'react'
import { Button, Card, Col, ListGroup, Row } from 'react-bootstrap'
import styled from 'styled-components'

import { Agenda, AppUser } from '../../../../../shared/Interfaces'
import { StatusTitleHelper } from '../../../Helpers/BookingHelper'
import dayjs from '../../../Helpers/DayjsHelper'
import { AdminDataContext } from '../../../Provider/AdminDataProvider'
import { AppColors } from '../../../Ressources/AppColors'
import {
  AppIconBack,
  IconSelectorLeft,
  IconSelectorRight,
} from '../../../Ressources/AppIcons'
import {
  UiButton,
  UiFormControl,
  UiFormTextarea,
  UiPhotoContainer,
  UiTopContainer,
  UIWorksiteAndRequestor,
} from '../../UI'

const AdminPage = () => {
  const { isLoading } = useContext(AdminDataContext)

  return (
    <PageContainer
      className={'d-flex flex-column w-100 mw-100 p-0 h-100'}
      contentClassName="w-100 mw-100"
      isLoading={isLoading}
    >
      {!isLoading && (
        <Row className="m-0">
          <LeftColumns />
          <RightColumns />
        </Row>
      )}
    </PageContainer>
  )
}

const LeftColumns = () => {
  const {
    showBookingDetails,
    setShowBookingDetails,
    setSelectedAgenda,
    unavailabilityMode,
    setUnavailabilityMode,
    onSelectingUnavailableDate,
    appUsers: users,
    agenda,
  } = useContext(AdminDataContext)

  const requestsPending = agenda.filter((a) => a.request.status === 'pending')
  return (
    <Col className="pl-4 pr-4 pt-4 pr-0 left-column" lg={3} md={12}>
      <UiButton
        element={Button}
        variant="secondary"
        type="button"
        onClick={() => {
          setUnavailabilityMode(!unavailabilityMode)
          onSelectingUnavailableDate(1, '', undefined, undefined, false)
        }}
        disabled={
          showBookingDetails ||
          users.find((u) => u.role === 'team')?.id === undefined
        }
        value={`${
          unavailabilityMode ? 'Terminer' : 'Indiquer les indisponibilités'
        }`}
        className="w-100 small"
      />
      {(!showBookingDetails && !unavailabilityMode && (
        <Card className="mt-4 rounded">
          <CardHeader>Réservations en attente</CardHeader>
          <ListGroup variant="flush">
            {(requestsPending.length > 0 &&
              requestsPending.map((item, index) => {
                const owner = users.find(
                  (user) => user.id === item.worksite.owner
                )
                return (
                  <RequestItem
                    key={`card-request-${index}`}
                    onClick={() => {
                      item.alreadyValidated = item.date !== undefined || false
                      setSelectedAgenda(item)
                      setShowBookingDetails(true)
                    }}
                    className="p-2"
                  >
                    <div className="font-weight-bold">
                      {item.worksite.customerFirstName}{' '}
                      {item.worksite.customerLastname}
                    </div>
                    <div>
                      Reservé par {owner?.firstName} {owner?.lastName} (
                      {owner?.company?.name})
                    </div>
                  </RequestItem>
                )
              })) || (
              <span className="p-2">
                Aucune réservation en attente pour cette semaine
              </span>
            )}
          </ListGroup>
        </Card>
      )) || (
        <>
          {unavailabilityMode && <SelectedUnavailableAgenda />}
          {showBookingDetails && (
            <SelectedAgenda
              setShowBookingDetails={(value) => setShowBookingDetails(value)}
            />
          )}
        </>
      )}
    </Col>
  )
}

const SelectedAgenda = ({
  setShowBookingDetails,
}: {
  setShowBookingDetails: (value: boolean) => void
}) => {
  const {
    selectedAgenda,
    selectedAgendaSiteManager,
    requestValidation,
    setRequestValidation,
    closeWithoutSaving,
  } = useContext(AdminDataContext)

  const siteManager = selectedAgendaSiteManager

  return (
    (selectedAgenda && (
      <>
        <Card className="mt-4 mb-1 rounded">
          <CardHeader
            onClick={() => {
              setShowBookingDetails(false)
              closeWithoutSaving()
            }}
            className="d-flex"
          >
            <AppIconBack title="retour" /> Réservation sélectionnée
          </CardHeader>
          <span className="bg-grey text-uppercase">
            détail de la réservation
          </span>
          <div>
            <p className="pt-3 pl-3">
              Status :{' '}
              <b
                className={
                  selectedAgenda.request.status === 'pending'
                    ? 'text-danger'
                    : 'text-success'
                }
              >
                {StatusTitleHelper(selectedAgenda.request.status).toUpperCase()}
              </b>
            </p>
            <UiFormControl
              type="number"
              name="slots"
              value={requestValidation?.slots.toString() || '1'}
              label="Créneaux occupés"
              placeholder="Créneaux occupés"
              required={true}
              min={1}
              max={5}
              onChange={(e) => {
                requestValidation &&
                  setRequestValidation({
                    ...requestValidation,
                    slots: +e.target.value,
                  })
              }}
              //disabled={selectedAgenda.date != undefined}
              disabled={selectedAgenda.request.status === 'finished'}
              className="ml-3 mr-4 mt-1"
            />
            <UiFormTextarea
              name="note"
              label="Remarques pour l'équipe (facultatif)"
              rows={2}
              className="ml-3 mr-4 mt-1"
              value={requestValidation?.teamNote || ''}
              onChange={(e) =>
                requestValidation &&
                setRequestValidation({
                  ...requestValidation,
                  teamNote: e.target.value,
                })
              }
              readonly={selectedAgenda.request.status === 'finished'}
            />
          </div>
          <span className="bg-grey text-uppercase">
            phase {selectedAgenda.request.phase}
          </span>
          {selectedAgenda.photosUrl && (
            <PhotoContainer
              imagesUrl={selectedAgenda.photosUrl}
              className="mt-2 mb-2"
            />
          )}
          {selectedAgenda.request.note && (
            <span className="mt-2 pr-2 pl-2">
              Remarque client : {selectedAgenda.request.note}
            </span>
          )}
        </Card>
        <UIWorksiteAndRequestor
          worksite={selectedAgenda.worksite}
          siteManager={siteManager!}
          siteManagerFull={true}
          rounded={true}
          bordered={true}
        />
      </>
    )) || <></>
  )
}

const SelectedUnavailableAgenda = () => {
  const {
    unavailableAgenda,
    onSelectingUnavailableDate,
    onSaveUnavailableDate,
    onDeleteExistingUnavailable,
  } = useContext(AdminDataContext)

  const [buttonDisabled, setButtonDisabled] = useState(true)

  const teamName = !unavailableAgenda.team
    ? ''
    : `${unavailableAgenda.team.firstName} ${unavailableAgenda.team.lastName}`

  return (
    <Card className="mt-4 mb-1 rounded">
      <UiFormControl
        type="number"
        name="slots"
        value={unavailableAgenda.slots.toString()}
        label="Créneaux à bloquer"
        placeholder="Créneaux à bloquer"
        required={true}
        min={1}
        max={unavailableAgenda.maxSlots}
        onChange={(e) => {
          setButtonDisabled(false)
          onSelectingUnavailableDate(
            +e.target.value,
            unavailableAgenda.reason,
            unavailableAgenda.date,
            unavailableAgenda.team
          )
        }}
        disabled={false}
        className="ml-3 mr-4 mt-1"
      />
      <UiFormControl
        type="string"
        name="reason"
        value={unavailableAgenda.reason}
        label="Raison"
        placeholder="Raison"
        required={true}
        onChange={(e) => {
          setButtonDisabled(false)
          onSelectingUnavailableDate(
            unavailableAgenda.slots,
            e.target.value,
            unavailableAgenda.date,
            unavailableAgenda.team
          )
        }}
        disabled={false}
        className="ml-3 mr-4 mt-1"
      />
      <UiFormControl
        type="string"
        name="date"
        value={unavailableAgenda?.date || ''}
        label="Date"
        placeholder="Date"
        required={true}
        onChange={() => {}}
        disabled={true}
        className="ml-3 mr-4 mt-1"
      />
      <UiFormControl
        type="string"
        name="team"
        value={teamName}
        label="Equipier"
        placeholder="Equipier"
        required={true}
        onChange={() => {}}
        disabled={true}
        className="ml-3 mr-4 mt-1"
      />
      <UiButton
        variant="secondary"
        type="button"
        onClick={() => {
          onSaveUnavailableDate()
          setButtonDisabled(true)
        }}
        value="Sauvegarder"
        disabled={
          !unavailableAgenda.date ||
          !unavailableAgenda.team ||
          unavailableAgenda.reason.length === 0 ||
          buttonDisabled
        }
      />
      {unavailableAgenda.alreadyValidated && (
        <UiButton
          variant="danger"
          type="button"
          onClick={onDeleteExistingUnavailable}
          value="Supprimer"
          disabled={false}
          className="mt-2"
        />
      )}
    </Card>
  )
}

const RightColumns = () => {
  return (
    <Col className="p-4" lg={9} md={12}>
      <Selectors />
      <Calendar />
    </Col>
  )
}

const Selectors = () => {
  const {
    week,
    setWeek,
    year,
    setYear,
    setIsLoading,
    showBookingDetails,
    selectedAgenda,
    onSaveDate,
    cancelMode,
    setCancelMode,
    reportDate,
  } = useContext(AdminDataContext)
  return (
    <>
      <ContainerSelectors className="d-flex justify-content-between w-100">
        <div className="d-flex">
          <ContainerSelector className="d-flex align-items-center">
            <span
              className="selector-minus"
              onClick={() => {
                if (week && year) {
                  if (week - 1 > 0) {
                    setIsLoading(true)
                    setWeek(week - 1)
                  }
                }
              }}
            >
              <IconSelectorLeft
                title="semaine précédente"
                width="14"
                height="16"
              />
            </span>
            <span className="selector-text">Semaine {week}</span>
            <span
              className="selector-plus"
              onClick={() => {
                if (week && week + 1 <= dayjs(`${year}-12-31`).week()) {
                  setIsLoading(true)
                  setWeek(week + 1)
                }
              }}
            >
              <IconSelectorRight
                title="semaine suivante"
                width="14"
                height="16"
              />
            </span>
          </ContainerSelector>
          <ContainerSelector className="d-flex align-items-center">
            <span
              className="selector-minus"
              onClick={() => {
                if (year && week && year - 1 >= dayjs().get('year')) {
                  if (
                    year - 1 === dayjs().get('year') &&
                    week < dayjs().week()
                  ) {
                    setWeek(dayjs().week())
                  }
                  setIsLoading(true)
                  setYear(year - 1)
                }
              }}
            >
              <IconSelectorLeft
                title="semaine précédente"
                width="14"
                height="16"
              />
            </span>
            <span className="selector-text">{year}</span>
            <span
              className="selector-plus"
              onClick={() => {
                if (year) {
                  setIsLoading(true)
                  setYear(year + 1)
                }
              }}
            >
              <IconSelectorRight
                title="semaine suivante"
                width="14"
                height="16"
              />
            </span>
          </ContainerSelector>
        </div>
        {showBookingDetails && (
          <>
            {cancelMode && (
              <div className="d-flex align-items-center">
                Proposez une semaine :{' '}
                <span
                  style={
                    !reportDate
                      ? { color: 'red', marginLeft: 5 }
                      : { marginLeft: 5 }
                  }
                >
                  {(reportDate && <b>{reportDate}</b>) || 'Aucune proposition'}
                </span>
              </div>
            )}
            <div>
              {selectedAgenda && (
                <UiButton
                  element={Button}
                  variant="secondary"
                  type="button"
                  onClick={() => setCancelMode(!cancelMode)}
                  disabled={selectedAgenda.request.status === 'finished'}
                  value={
                    (!cancelMode && 'Annuler la reservation') ||
                    'Ne pas annuler'
                  }
                  className="mr-2 text-uppercase"
                />
              )}

              <UiButton
                element={Button}
                variant="secondary"
                type="button"
                onClick={onSaveDate}
                disabled={
                  (selectedAgenda &&
                    selectedAgenda.alreadyValidated &&
                    !cancelMode) ||
                  false
                }
                value="Sauvegarder"
                className="text-uppercase"
              />
            </div>
          </>
        )}
      </ContainerSelectors>
    </>
  )
}

const Calendar = () => {
  const {
    week,
    year,
    appUsers,
    agenda,
    selectedAgenda,
    onSelectingDate,
    unavailabilityMode,
    onSelectingUnavailableDate,
    unavailableAgenda,
  } = useContext(AdminDataContext)
  const w = dayjs().set('year', year!).week(week!)
  const monday = w.weekday(0)

  const teams = appUsers.filter((u) => u.role === 'team' && !u.inactive)

  return (
    <Row className="mt-3 right-column">
      {Array.from({ length: 5 - 0 + 1 }, (_, i) => 0 + i).map((i) => {
        const date = monday.add(i, 'day')
        return (
          <Col
            className="text-center p-2 day-column"
            xs={6}
            lg={2}
            key={`calendar-col-${i}`}
          >
            <span className="font-weight-bold text-capitalize">
              {date.format('ddd DD/MM')}
            </span>

            {teams.map((team, index) => {
              const { slots, slotsLock, userJobs } = getTeamDataForDay({
                team,
                agenda,
                date,
              })

              return (
                <PhasesGroup
                  as="ul"
                  className={`pl-0 mb-0 mt-3 ${
                    (selectedAgenda &&
                      selectedAgenda.date &&
                      selectedAgenda.date === date.format('DD/MM/YYYY') &&
                      team.id === selectedAgenda.team?.id) ||
                    (unavailabilityMode &&
                      team.id === unavailableAgenda?.team?.id &&
                      unavailableAgenda?.date === date.format('DD/MM/YYYY'))
                      ? 'border-red'
                      : 'border-grey'
                  }`}
                  key={`calendar-group-${index}`}
                >
                  <ListGroup.Item
                    as="li"
                    active
                    onClick={() => {
                      if (!unavailabilityMode && selectedAgenda) {
                        onSelectingDate(
                          date.format('DD/MM/YYYY'),
                          team,
                          slots + slotsLock
                        )
                      }
                      if (
                        unavailabilityMode &&
                        5 - (slots + slotsLock) >=
                          (unavailableAgenda?.slots || 1)
                      ) {
                        onSelectingUnavailableDate(
                          unavailableAgenda.slots,
                          unavailableAgenda.reason,
                          date.format('DD/MM/YYYY'),
                          team
                        )
                      }
                    }}
                    style={
                      selectedAgenda || unavailabilityMode
                        ? {
                            cursor: 'pointer',
                          }
                        : {}
                    }
                  >
                    {team.lastName.toUpperCase()}{' '}
                    {team.firstName[0].toUpperCase()}.
                  </ListGroup.Item>
                  <CalandarItems
                    userJobs={userJobs}
                    slots={slots}
                    slotsLock={slotsLock}
                    date={date}
                    team={team}
                  />
                </PhasesGroup>
              )
            })}
          </Col>
        )
      })}
    </Row>
  )
}

const CalandarItems = ({
  userJobs,
  slots,
  slotsLock,
  date,
  team,
}: {
  userJobs: Agenda[]
  slots: number
  slotsLock: number
  date: dayjs.Dayjs
  team: AppUser
}) => {
  const {
    selectedAgenda,
    onCLickExistingBooking,
    unavailabilityMode,
    onCLickExistingUnavailable,
  } = useContext(AdminDataContext)
  if (slotsLock > 5) {
    console.log(userJobs)
    return (
      <>
        {slots} - {slotsLock}
      </>
    )
  }
  return (
    <>
      {userJobs.map((job, index) => {
        return Array(job.slots)
          .fill(1)
          .map((_, jobIndex) => (
            <ListGroup.Item
              as="li"
              onClick={() => {
                if (!unavailabilityMode && !job.lock) {
                  onCLickExistingBooking(userJobs, index)
                }
                if (!selectedAgenda && job.lock) {
                  onCLickExistingUnavailable(job.date!, job.team?.id!)
                }
              }}
              style={
                !unavailabilityMode && !job.lock ? { cursor: 'pointer' } : {}
              }
              key={`item-${date.format('DD-MM-YYYY')}-${
                team.id
              }-${index}-${jobIndex}`}
            >
              <div className={`${job.lock ? 'unavailable' : 'busy'}`}>
                {job.lock ? job.lockReason : `P${job.request.phase}`}
              </div>
            </ListGroup.Item>
          ))
      })}
      {Array(5 - (slots + slotsLock))
        .fill(1)
        .map((_, index) => (
          <ListGroup.Item
            as="li"
            key={`item-${date.format('DD-MM-YYYY')}-${team.id}-${index}`}
          >
            <div className="empty"></div>
          </ListGroup.Item>
        ))}{' '}
    </>
  )
}

const getTeamDataForDay = ({
  team,
  agenda,
  date,
}: {
  team: AppUser
  agenda: Agenda[]
  date: dayjs.Dayjs
}) => {
  let slots = 0
  let slotsLock = 0
  agenda.forEach((a) => {
    if (
      a.date === date.format('DD/MM/YYYY') &&
      a.team &&
      a.team.id === team.id &&
      a.slots
    ) {
      if (!a.lock) {
        slots += a.slots
      } else {
        slotsLock += a.slots
      }
    }
  })

  const userJobs = agenda
    .filter(
      (a) =>
        a.date === date.format('DD/MM/YYYY') && a.team && a.team.id === team.id
    )
    .sort((a1, a2) => {
      if (a1.lock) {
        return 1
      }
      if (a2.lock) {
        return -1
      }
      return 0
    })

  return { slots, slotsLock, userJobs }
}

export default AdminPage

const PageContainer = styled(UiTopContainer)`
  background-color: #eee;

  .border-red {
    border: 1px solid red;
  }

  .border-grey {
    border: 1px solid #c4c4c4;
  }

  .right-column {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
  }

  .left-column {
    .selected-group {
      border-bottom: unset !important;
      .list-group-item {
        border-right: unset !important;
        border-left: unset !important;
        border-bottom: unset !important;
      }
    }
  }
`

const CardHeader = styled(Card.Header)`
  color: ${AppColors.green};
  font-size: 16px;

  svg {
    margin-right: 10px;
    cursor: pointer;
  }
`
const RequestItem = styled(ListGroup.Item)`
  font-size: 14px;
  padding: 5px;
  cursor: pointer;
`
const ContainerSelectors = styled.div`
  background-color: #fff;
  padding: 16px;
  border-radius: 8px;
`

const ContainerSelector = styled.div`
  margin: 0 5px;

  .selector-minus,
  .selector-plus {
    display: flex;
    color: ${AppColors.orange};
    cursor: pointer;
  }

  .selector-text {
    margin: 5px 15px;
  }
`

const PhasesGroup = styled(ListGroup)`
  border-radius: 12px;

  .active {
    background-color: #fff;
    color: ${AppColors.orange};
  }

  li {
    border: 0px;
    padding: 4px;
    div {
      width: 100%;
      height: 25px;
      color: #fff;
      border-radius: 4px;
    }

    .empty {
      border: 1px dashed #c4c4c4;
    }

    .busy {
      background-color: ${AppColors.green};
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .unavailable {
      background-color: ${AppColors.red};
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`

const PhotoContainer = styled(UiPhotoContainer)`
  height: 70px;

  @media (max-width: 992px) {
    height: 130px;
  }
`
