import React, { useContext, useEffect, useState } from 'react'
import {
  ButtonToolbar,
  ButtonGroup,
  Button,
  Form,
  Container,
} from 'react-bootstrap'
import { useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { v4 as uuidv4 } from 'uuid'

import {
  BookingRequest,
  BookingRequestFirstStage,
} from '../../../../../shared/Interfaces'
import { Auth, Firestore, Functions, Storage } from '../../../Firebase/Firebase'
import { resizeImage } from '../../../Helpers/ImageHelper'
import { showToastError } from '../../../Helpers/ToastHelper'
import { getTimestamp } from '../../../Helpers/Various'
import { AuthContext } from '../../../Provider/AuthProvider'
import { AppColors } from '../../../Ressources/AppColors'
import { IconCheck } from '../../../Ressources/AppIcons'
import {
  UiFormLabel,
  UiButton,
  UiFormTextarea,
  UiButtonFile,
  UiHeader,
  UiTopContainer,
  UiPhotoContainer,
} from '../../UI'

interface RouteParams {
  worksiteId: string
}
interface Form {
  firstPhaseAvailable: number
  phase: number
  note?: string
}

const initialFormValues: Form = {
  firstPhaseAvailable: 1,
  phase: 1,
  note: undefined,
}

interface StorageImageUrl {
  name: string
  url: string
  phase: number
}

interface StorageImage {
  file: File
  phase: number
}

const BookingPage = () => {
  const history = useHistory()

  const { worksiteId } = useParams<RouteParams>()

  const { user, appUser, authenticated } = useContext(AuthContext)
  const [formValues, setFormValues] = useState(initialFormValues)
  const [images, setImages] = useState<StorageImage[]>([])
  const [imagesUrl, setImagesUrl] = useState<StorageImageUrl[]>([])

  const [isLoading, setIsLoading] = useState(true)

  const handlePhaseClick = (phase: number) => {
    setFormValues({ ...formValues, phase })
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()
    if (!event.target || !event.target.files) {
      return
    }
    const file = event.target.files[0]
    let resizedImage = file

    const image = new Image()
    image.src = URL.createObjectURL(file)

    image.onload = async () => {
      if (image.width > 1024) {
        const height = (1024 * image.height) / image.width
        resizedImage = await resizeImage(file, 1024, height)
      }

      const phase = formValues.phase
        ? formValues.phase
        : formValues.firstPhaseAvailable
      setImages([...images, { file: resizedImage, phase }])
      setImagesUrl([
        ...imagesUrl,
        { name: file.name, url: URL.createObjectURL(resizedImage), phase },
      ])
    }
  }

  const handleSubmit = () => {
    const booking: BookingRequestFirstStage = {
      phase: formValues.phase
        ? formValues.phase
        : formValues.firstPhaseAvailable,
      note: formValues.note,
      owner: user?.uid!,
      status: 'pending',
      createdAt: getTimestamp(),
    }

    Firestore.collection(`worksites/${worksiteId}/bookings`)
      .add(booking)
      .then((res) => {
        saveImages()
          .then(() => {
            history.push({
              pathname: `/booking/${worksiteId}/phase/${booking.phase}/request/${res.id}/week`,
              state: {
                booking,
              },
            })
          })
          .catch(() =>
            showToastError(
              'Un problème est survenu durant la sauvegarde des photos'
            )
          )
      })
      .catch(() =>
        showToastError(
          // eslint-disable-next-line quotes
          "Un problème est survenu durant l'enregistrement de la prestastion"
        )
      )
  }

  const getImages = (id?: string) => {
    const storageWorksiteId = id ? id : worksiteId
    if (storageWorksiteId) {
      setIsLoading(true)
      const phase = formValues.phase
        ? formValues.phase
        : formValues.firstPhaseAvailable
      const storageRef = Storage.ref(`${storageWorksiteId}/P${phase}/client`)
      storageRef
        .listAll()
        .then((res) => {
          res.items.forEach((itemRef) => {
            itemRef
              .getDownloadURL()
              .then((url) => {
                if (!imagesUrl.find((image) => image.name === itemRef.name)) {
                  setImagesUrl((imagesUrl) => {
                    return [...imagesUrl, { name: itemRef.name, url, phase }]
                  })
                }
              })
              .catch(() =>
                showToastError(
                  'Un problème est survenu durant la récupération des photos'
                )
              )
          })
        })
        .finally(() => setIsLoading(false))
    }
  }

  const saveImages = async () => {
    images.forEach((image) => {
      const fileExt = image.file.name.split('.').pop()
      const fileId = uuidv4()

      const ref = Storage.ref(
        `${worksiteId}/P${image.phase}/client/${fileId}.${fileExt}`
      )
      ref.put(image.file).catch(() => {
        showToastError(
          'Un problème est survenu durant la sauvegarde des photos'
        )
      })
    })
  }

  const deleteImage = async (url: string) => {
    const image = imagesUrl.find((image) => image.url === url)
    if (image) {
      setIsLoading(true)

      const ref = Storage.ref(
        `${worksiteId}/P${image.phase}/client/${image.name}`
      )
      const isFileExist = await ref
        .getDownloadURL()
        .then(() => {
          return true
        })
        .catch(() => {
          return false
        })

      if (isFileExist) {
        ref
          .delete()
          .then(() => {
            setImagesUrl(imagesUrl.filter((item) => item !== image))
            setImages(images.filter((item) => item.file.name !== image.name))
          })
          .catch(() =>
            showToastError(
              'Un problème est survenu durant la suppression de la photo'
            )
          )
          .finally(() => getImages())
      } else {
        setImagesUrl(imagesUrl.filter((item) => item !== image))
        setImages(images.filter((item) => item.file.name !== image.name))
        setIsLoading(false)
      }
    }
  }

  const preloadWeeksCloudFunction = async () => {
    await Functions.httpsCallable('GetBookingAvailableWeeks')({
      wakeUp: 'true',
    })
  }

  useEffect(() => {
    preloadWeeksCloudFunction()
    getImages()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues.phase])

  useEffect(() => {
    if (worksiteId) {
      setFormValues({ ...formValues })
      Firestore.collection(`worksites/${worksiteId}/bookings`)
        .orderBy('createdAt', 'desc')
        .get()
        .then((snapshot) => {
          if (snapshot.docs.length > 0) {
            let lastPhase = 0

            snapshot.docs.map((item) => {
              const data = item.data() as BookingRequest
              if (data.phase < 7 && data.phase > lastPhase) {
                lastPhase = data.phase
              }
            })

            setFormValues({
              ...formValues,
              firstPhaseAvailable: lastPhase >= 6 ? 7 : lastPhase + 1,
              phase: lastPhase >= 6 ? 7 : lastPhase + 1,
              note: undefined,
            })
          } else {
            setFormValues({
              ...formValues,
              firstPhaseAvailable: 1,
              phase: 1,
              note: undefined,
            })
          }
        })
        .catch(() =>
          showToastError(
            'Un problème est survenu durant la récupération des prestations'
          )
        )
        .finally(() => {
          getImages(worksiteId)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [worksiteId])

  return (
    <>
      <UiHeader
        isDashboardHeader={false}
        isAuthenticated={authenticated}
        history={history}
        role={appUser?.role}
        firesbaseAuth={Auth}
        title="Planifier une prestation"
        callbackHandler={() => history.push('/dashboard')}
      />
      <UiTopContainer className="pr-0 pl-0 w-100 mw-100" isLoading={isLoading}>
        <PageContainer>
          <UiFormLabel
            value="Prestation à réserver"
            className="bg-grey pl-3 text-uppercase"
          />
          <ButtonToolbar
            aria-label="selection de la prestation"
            className="pl-2 pr-2"
          >
            <StageGroup>
              {[1, 2, 3, 4, 5, 6, 7].map((phase, index) => {
                const title = phase === 7 ? 'Autre' : `Phase ${phase}`
                return (
                  <ButtonPhase
                    title={title}
                    selected={
                      !formValues.phase
                        ? formValues.firstPhaseAvailable === phase
                        : formValues.phase === phase
                    }
                    disabled={
                      phase <= 6 && !(formValues.firstPhaseAvailable! <= phase)
                    }
                    onClick={() => handlePhaseClick(phase)}
                    key={index}
                  />
                )
              })}
            </StageGroup>
          </ButtonToolbar>
          <UiFormTextarea
            name="note"
            label="Remarques (facultatif)"
            rows={3}
            className="pr-2 pl-2"
            value={formValues.note}
            onChange={(e) =>
              setFormValues({ ...formValues, note: e.target.value })
            }
          />
          <UiFormLabel value="Photos" className="bg-grey pl-3 text-uppercase" />
          <div className="d-flex flex-column pr-2 pl-2">
            <UiButtonFile
              name="photo"
              label=""
              buttonTitle="Selectionner"
              onChange={handleFileChange}
            />
          </div>
          <UiPhotoContainer
            imagesUrl={imagesUrl
              .filter((image) => image.phase === formValues.phase)
              .map((image) => image.url)}
            onDeleteImage={deleteImage}
            className="mb-2"
          />
        </PageContainer>
      </UiTopContainer>
      <ActionButtons className="d-flex flex-column m-0 p-0 mw-100">
        <UiButton
          element={Button}
          variant="secondary"
          type="button"
          onClick={handleSubmit}
          disabled={false}
          value="Planifier la prestation"
          className="text-uppercase btn-full"
        />
      </ActionButtons>
    </>
  )
}

const ButtonPhase = ({
  title,
  selected,
  disabled,
  onClick,
}: {
  title: string
  selected: boolean
  disabled: boolean
  onClick: () => void
}) => (
  <UiButton
    element={StageButton}
    variant="primary"
    type="button"
    onClick={onClick}
    disabled={disabled}
    value={
      selected ? (
        <>
          <IconCheck title="phase sélectionnée" />
          <span>{title}</span>
        </>
      ) : (
        title
      )
    }
    className={
      selected
        ? 'phase-active d-flex justify-content-center align-items-center'
        : 'phase-inactive'
    }
  />
)

export default BookingPage

const PageContainer = styled(Form)`
  text-align: left;

  .phase-active {
    background-color: ${AppColors.orange} !important;
    span {
      margin-left: 5px;
    }
  }

  .phase-inactive {
    background-color: ${AppColors.greyDark} !important;
    color: #000;
  }
`
const StageGroup = styled(ButtonGroup)`
  margin-bottom: 0.5rem;
  flex-wrap: wrap;
`
const StageButton = styled(Button)`
  margin-right: 10px;
  border-radius: 40px !important;
  width: 110px;
  max-width: 110px;
  margin-bottom: 10px;
  border: 0;
`
const ActionButtons = styled(Container)`
  flex: 0 0 auto;
  background-color: #fff;
`
