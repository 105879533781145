import { Route, Switch } from 'react-router-dom'

import TeamPage from '../Components/Pages/Dashboard/TeamPage'
import { TeamDataProvider } from '../Provider/TeamDataProvider'

export const dashboardTeamWithDateParam = '/dashboard/date/:date'

interface Props {
  advert: string
}

export const TeamDashboardRoutes = (props: Props) => {
  return (
    <TeamDataProvider>
      <Switch>
        <Route exact path="/dashboard">
          <TeamPage advert={props.advert} />
        </Route>
        <Route exact path={dashboardTeamWithDateParam}>
          <TeamPage advert={props.advert} />
        </Route>
      </Switch>
    </TeamDataProvider>
  )
}
