import { toast } from 'react-toastify'

const showToastError = (message: string, closeOnClick?: boolean) => {
  toast.error(message, {
    position: 'top-center',
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: closeOnClick || true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  })
}

const showToastSuccess = (message: string, closeOnClick?: boolean) => {
  toast.success(message, {
    position: 'top-center',
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: closeOnClick || true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  })
}

export { showToastError, showToastSuccess }
