import { AppUser } from '../../../shared/Interfaces'
import {} from 'react-router'

import { Auth, Functions } from './Firebase'

async function redirectToEmulatedAuthentication(userId: string | undefined) {
  const { data } = await Functions.httpsCallable('GenerateUserEmulatorToken')({
    userId: userId,
  })

  await Auth.signInWithCustomToken(data.token)
  window.location.href = '/'
  window.location.reload()
}

export const emulateUser = async (user: AppUser): Promise<void> => {
  const userId = user.id
  await redirectToEmulatedAuthentication(userId)
}

export const returnToDefaultUser = async () => {
  const idTokenResult = await Auth.currentUser?.getIdTokenResult()
  if (!idTokenResult?.claims?.actualUser) {
    return
  }
  await redirectToEmulatedAuthentication(idTokenResult.claims.actualUser)
}
