import { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { Advert } from '../../../../shared/Interfaces'
import { Auth, Firestore } from '../../Firebase/Firebase'
import { AuthContext } from '../../Provider/AuthProvider'
import { AdminDashboardRoutes } from '../../Routes/AdminDashboardRoutes'
import { TeamDashboardRoutes } from '../../Routes/TeamDashboardRoutes'
import { UiHeader } from '../UI'

import SiteManagerPage from './Dashboard/SiteManagerPage'

export const DashboardPage = () => {
  const history = useHistory()
  const { authenticated, appUser } = useContext(AuthContext)

  const [advert, setAdvert] = useState('')

  useEffect(() => {
    Firestore.collection('banners')
      .where('online', '==', true)
      .get()
      .then((snapshot) => {
        const advert = snapshot.docs[0].data() as Advert
        setAdvert(advert.text)
      })
  }, [])

  return (
    <>
      <UiHeader
        isDashboardHeader={true}
        history={history}
        isAuthenticated={authenticated}
        role={appUser?.role}
        firesbaseAuth={Auth}
        title="dashboard"
        callbackHandler={() => history.goBack()}
      />
      {appUser?.role === 'promoter' && <>Promoter</>}
      {appUser?.role === 'site-manager' && <SiteManagerPage advert={advert} />}
      {appUser?.role === 'team' && <TeamDashboardRoutes advert={advert} />}
      {appUser?.role === 'admin' && <AdminDashboardRoutes />}
    </>
  )
}
