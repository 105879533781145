import { useEffect, useState } from 'react'
import { Button, Container, Modal, Tab, Table, Tabs } from 'react-bootstrap'
import styled from 'styled-components'

import { WasteSite } from '../../../../../shared/Interfaces'
import { Firestore } from '../../../Firebase/Firebase'
import { AppColors } from '../../../Ressources/AppColors'
import { UiFormControl, UiFormInput, UiTopContainer } from '../../UI'

const icons = {
  trash: (
    <svg
      aria-hidden="true"
      focusable="false"
      data-prefix="far"
      data-icon="trash-alt"
      className="svg-inline--fa fa-trash-alt fa-w-14"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 448 512"
    >
      <path
        fill="currentColor"
        d="M268 416h24a12 12 0 0 0 12-12V188a12 12 0 0 0-12-12h-24a12 12 0 0 0-12 12v216a12 12 0 0 0 12 12zM432 80h-82.41l-34-56.7A48 48 0 0 0 274.41 0H173.59a48 48 0 0 0-41.16 23.3L98.41 80H16A16 16 0 0 0 0 96v16a16 16 0 0 0 16 16h16v336a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128h16a16 16 0 0 0 16-16V96a16 16 0 0 0-16-16zM171.84 50.91A6 6 0 0 1 177 48h94a6 6 0 0 1 5.15 2.91L293.61 80H154.39zM368 464H80V128h288zm-212-48h24a12 12 0 0 0 12-12V188a12 12 0 0 0-12-12h-24a12 12 0 0 0-12 12v216a12 12 0 0 0 12 12z"
      ></path>
    </svg>
  ),
  edit: (
    <svg
      aria-hidden="true"
      focusable="false"
      data-prefix="far"
      data-icon="edit"
      className="svg-inline--fa fa-edit fa-w-18"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 576 512"
    >
      <path
        fill="currentColor"
        d="M402.3 344.9l32-32c5-5 13.7-1.5 13.7 5.7V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V112c0-26.5 21.5-48 48-48h273.5c7.1 0 10.7 8.6 5.7 13.7l-32 32c-1.5 1.5-3.5 2.3-5.7 2.3H48v352h352V350.5c0-2.1.8-4.1 2.3-5.6zm156.6-201.8L296.3 405.7l-90.4 10c-26.2 2.9-48.5-19.2-45.6-45.6l10-90.4L432.9 17.1c22.9-22.9 59.9-22.9 82.7 0l43.2 43.2c22.9 22.9 22.9 60 .1 82.8zM460.1 174L402 115.9 216.2 301.8l-7.3 65.3 65.3-7.3L460.1 174zm64.8-79.7l-43.2-43.2c-4.1-4.1-10.8-4.1-14.8 0L436 82l58.1 58.1 30.9-30.9c4-4.2 4-10.8-.1-14.9z"
      ></path>
    </svg>
  ),
  status: (
    <svg
      aria-hidden="true"
      focusable="false"
      data-prefix="fas"
      data-icon="plug"
      className="svg-inline--fa fa-plug fa-w-12"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 384 512"
    >
      <path
        fill="currentColor"
        d="M320,32a32,32,0,0,0-64,0v96h64Zm48,128H16A16,16,0,0,0,0,176v32a16,16,0,0,0,16,16H32v32A160.07,160.07,0,0,0,160,412.8V512h64V412.8A160.07,160.07,0,0,0,352,256V224h16a16,16,0,0,0,16-16V176A16,16,0,0,0,368,160ZM128,32a32,32,0,0,0-64,0v96h64Z"
      ></path>
    </svg>
  ),
}

const ManageWastesSites = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [reloadData, setReloadData] = useState(false)

  const [originalSites, setOriginalSites] = useState<WasteSite[]>([])
  const [sites, setSites] = useState<WasteSite[]>([])
  const [sirets, setSirets] = useState<string[]>([])

  const [filter, setFilter] = useState<string | undefined>(undefined)
  const [departments, setDepartments] = useState<string[]>([])

  const [wasteItems, setWasteItems] = useState<string[]>([])

  const fetchDepartments = (sites: WasteSite[]) => {
    const departmentArray: string[] = []
    sites.forEach((site) => {
      const m = site.address.match(/\d{5}/)
      const postalCode = m ? m[0] : undefined
      if (postalCode && !departmentArray.includes(postalCode.substr(0, 2))) {
        departmentArray.push(postalCode.substr(0, 2))
      }
    })

    setDepartments(
      departmentArray.sort((a1, b1) => {
        if (a1 < b1) {
          return -1
        }
        if (a1 > b1) {
          return 1
        }
        return 0
      })
    )
    setIsLoading(false)
  }

  useEffect(() => {
    if (!filter) {
      setSites(originalSites)
    } else {
      const filtered = originalSites.filter(
        (site) => site.address.match(/\d{5}/)![0].substr(0, 2) === filter
      )
      setSites(filtered)
    }
  }, [filter, originalSites])

  useEffect(() => {
    const fetchSites = async () => {
      const snapshot = await Firestore.collection('waste-sites').get()
      const results = snapshot.docs.map((item) => {
        const site = item.data() as WasteSite
        site.id = item.id
        site.siret && setSirets([...sirets, site.siret])
        return site
      })
      setSites(
        results.sort((s1, s2) => {
          if (s1.name > s2.name) {
            return 1
          }
          if (s1.name < s2.name) {
            return -1
          }

          return 0
        })
      )
      setOriginalSites(
        results.sort((s1, s2) => {
          if (s1.name > s2.name) {
            return 1
          }
          if (s1.name < s2.name) {
            return -1
          }

          return 0
        })
      )

      const items = await Firestore.collection('job-data')
        .doc('waste-items')
        .get()

      const wastes = items.data()
      wastes && setWasteItems(wastes.items as string[])

      fetchDepartments(results)
      setReloadData(false)
    }

    fetchSites()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadData])

  return (
    <UiTopContainer isLoading={isLoading} contentClassName="w-100 mw-100">
      <Container fluid>
        {!isLoading && (
          <TabsContainer defaultActiveKey="sites" className="mt-2">
            <Tab eventKey="sites" title="Déchéteries">
              <SitesFilters
                departments={departments}
                filter={filter}
                setFilter={(departement) => setFilter(departement)}
              />
              <SitesTable
                sites={sites}
                setSites={(sites: WasteSite[]) => setSites(sites)}
                reloadData={() => setReloadData(true)}
              />
            </Tab>
            <Tab eventKey="waste" title="Déchets">
              <WasteItems
                wastesItems={wasteItems}
                reloadData={() => setReloadData(true)}
              />
            </Tab>
          </TabsContainer>
        )}
      </Container>
    </UiTopContainer>
  )
}

const SitesFilters = ({
  departments,
  filter,
  setFilter,
}: {
  departments: string[]
  filter?: string
  setFilter: (departement?: string) => void
}) => {
  return (
    <div className="mt-3">
      <Button
        variant={!filter ? 'secondary' : 'primary'}
        className="mr-1"
        onClick={() => setFilter(undefined)}
      >
        Tous les départments
      </Button>
      {departments.map((department) => {
        return (
          <Button
            variant={filter && filter === department ? 'secondary' : 'primary'}
            className="mr-1"
            onClick={() => setFilter(department)}
            key={`dep-${department}`}
          >
            {department}
          </Button>
        )
      })}
    </div>
  )
}

const SitesTable = ({
  sites,
  setSites,
  reloadData,
}: {
  sites: WasteSite[]
  reloadData: () => void
  setSites: (sites: WasteSite[]) => void
}) => {
  const [siteEdited, setSiteEdited] = useState<WasteSite>()

  const onDeleteHandler = (site: WasteSite) => {
    Firestore.collection('waste-sites').doc(site.id).delete()
    setSites(sites.filter((s) => s.id !== site.id))
  }

  const onStatusChangeHandler = (site: WasteSite) => {
    Firestore.collection('waste-sites')
      .doc(site.id)
      .update({ active: !site.active })
    reloadData()
  }

  return (
    <div className="d-flex">
      {siteEdited && (
        <EditedSite
          site={siteEdited}
          onClosePanel={() => setSiteEdited(undefined)}
          reloadData={() => {
            reloadData()
          }}
        />
      )}
      <SitesTableContainer striped bordered hover>
        <thead>
          <tr>
            <th>#</th>
            <th>Nom</th>
            <th>Adresse</th>
            <th>Siret</th>
            <th>Téléphone</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan={5}></td>
            <td>
              <Button
                style={{ width: 205 }}
                onClick={() =>
                  setSiteEdited({
                    name: '',
                    address: '',
                    siret: '',
                    phone: '',
                    id: '',
                    active: true,
                  })
                }
                variant={'success'}
                disabled={siteEdited !== undefined}
              >
                Ajouter une déchéterie
              </Button>
            </td>
          </tr>
          {sites.map((site, index) => {
            return (
              <tr key={`tr-${index}`}>
                <td
                  className="text-center"
                  style={
                    site.active
                      ? { color: AppColors.green }
                      : { color: AppColors.red }
                  }
                >
                  <b>{index + 1}</b>
                </td>
                <td
                  style={
                    site.active
                      ? { color: AppColors.green }
                      : { color: AppColors.red }
                  }
                >
                  {site.name}
                </td>
                <td>{site.address}</td>
                <td>{site.siret}</td>
                <td>{site.phone}</td>
                <td>
                  <div className="d-flex">
                    <span
                      className="ml-2 edit"
                      onClick={() => setSiteEdited(site)}
                    >
                      {icons.edit}
                    </span>
                    <span
                      className={'ml-2 trash'}
                      onClick={() => onDeleteHandler(site)}
                    >
                      {icons.trash}
                    </span>
                    <span
                      className={`ml-2 ${site.active ? 'online' : 'offline'}`}
                      onClick={() => onStatusChangeHandler(site)}
                    >
                      {icons.status}
                    </span>
                  </div>
                </td>
              </tr>
            )
          })}
        </tbody>
      </SitesTableContainer>
    </div>
  )
}

const WasteItems = ({
  wastesItems,
  reloadData,
}: {
  wastesItems: string[]
  reloadData: () => void
}) => {
  const [showModal, setShowModal] = useState(false)
  const [selectedItem, setSelectedItems] =
    useState<string | undefined>(undefined)

  const [showAdd, setShowAdd] = useState(false)
  const [addItem, setAddItem] = useState<string>('')

  const handlerAddItem = () => {
    Firestore.collection('job-data')
      .doc('waste-items')
      .set({ items: [...wastesItems, addItem] })
      .then(() => {
        setShowAdd(false)
        setAddItem('')
        reloadData()
      })
  }

  const handlerDeleteItem = () => {
    Firestore.collection('job-data')
      .doc('waste-items')
      .set({ items: wastesItems.filter((w) => w !== selectedItem) })
      .then(() => {
        setShowModal(false)
        reloadData()
      })
  }

  return (
    <div className="d-flex flex-column justify-content-center w-75 ml-auto mr-auto">
      <div className="ml-3 mt-2 d-flex align-items-center">
        <Button
          style={{ width: 205 }}
          onClick={() => setShowAdd(true)}
          variant={'secondary'}
          disabled={showAdd}
          className="mr-5"
        >
          Ajouter un déchet
        </Button>
        {showAdd && (
          <div className="d-flex align-items-center w-100">
            <UiFormInput
              type="text"
              name="add-item"
              placeholder="Nom du déchet"
              value={addItem || ''}
              onChange={(e) => setAddItem(e.target.value)}
              required={true}
            />
            <Button
              style={{ width: 205 }}
              onClick={handlerAddItem}
              variant={'secondary'}
              disabled={addItem.length < 2 || wastesItems.includes(addItem)}
              className="ml-5"
            >
              Valider
            </Button>
          </div>
        )}
      </div>
      <div className="m-2">
        {wastesItems
          .sort((a1, b1) => {
            if (a1 < b1) {
              return -1
            }
            if (a1 > b1) {
              return 1
            }
            return 0
          })
          .map((item) => {
            return (
              <Button
                className="m-2"
                onClick={() => {
                  setSelectedItems(item)
                  setShowModal(true)
                }}
                style={{ minWidth: 150 }}
                disabled={showAdd}
              >
                {item}
              </Button>
            )
          })}
        <Modal show={showModal} centered>
          <Modal.Header className="d-flex flex-column">
            <Modal.Title>Suppression d'un déchet</Modal.Title>
            <Modal.Body>
              Etes-vous certain de vouloir supprimer le déchet{' '}
              <b>{selectedItem}</b> ?
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={() => setShowModal(false)}>
                Non
              </Button>
              <Button variant="danger" onClick={handlerDeleteItem}>
                Oui
              </Button>
            </Modal.Footer>
          </Modal.Header>
        </Modal>
      </div>
    </div>
  )
}

const EditedSite = ({
  site,
  onClosePanel,
  reloadData,
}: {
  site: WasteSite
  onClosePanel: () => void
  reloadData: () => void
}) => {
  const [currentSite, setCurrentSite] = useState(site)
  const [isValid, setIsValid] = useState(true)

  const onSaveHandler = () => {
    const originalSiret =
      (currentSite.siret && currentSite.siret.replace(/\s/g, '')) || undefined
    const originalPhone =
      (currentSite.phone &&
        currentSite.phone.replace(/\s/g, '').replace(/\./g, '')) ||
      undefined

    let formattedSiret = ''
    let formattedPhone = ''

    if (originalSiret) {
      formattedSiret = `${originalSiret.substr(0, 3)} ${originalSiret.substr(
        3,
        3
      )} ${originalSiret.substr(6, 3)} ${originalSiret.substr(9, 5)}`
    }

    if (originalPhone) {
      formattedPhone = `${originalPhone.substr(0, 2)}.${originalPhone.substr(
        2,
        2
      )}.${originalPhone.substr(4, 2)}.${originalPhone.substr(
        6,
        2
      )}.${originalPhone.substr(8, 2)}`
    }

    Firestore.collection('waste-sites')
      .doc()
      .set({
        name: currentSite.name.trim(),
        address: currentSite.address.trim(),
        siret: originalSiret ? formattedSiret : undefined,
        phone: originalPhone ? formattedPhone : undefined,
      })
      .then(() => {
        reloadData()
        onClosePanel()
      })
  }

  useEffect(() => {
    let formIsValid = false

    formIsValid = currentSite.name.length > 0

    if (formIsValid && currentSite.address.length > 0) {
      const match = currentSite.address.match(/\d{5}/)
      formIsValid = match != null
    }

    if (currentSite.siret && formIsValid) {
      const siretClean = currentSite.siret.replace(/\s/g, '')
      formIsValid = siretClean.length === 14
    }
    if (currentSite.phone && formIsValid) {
      formIsValid =
        currentSite.phone.replace(/\s/g, '').replace(/\./g, '').length === 10
    }

    setIsValid(formIsValid)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSite])

  return (
    <div
      style={{
        marginTop: '1rem',
        marginRight: '1rem',
        width: '40%',
      }}
    >
      <UiFormControl
        type="text"
        name="email"
        value={currentSite.name}
        label="Nom"
        onChange={(e) =>
          setCurrentSite({ ...currentSite, name: e.target.value })
        }
        required={true}
        placeholder=""
      />
      <UiFormControl
        type="text"
        name="address"
        value={currentSite.address}
        label="Adresse"
        onChange={(e) =>
          setCurrentSite({ ...currentSite, address: e.target.value })
        }
        required={true}
        placeholder=""
      />
      <UiFormControl
        type="text"
        name="siret"
        value={currentSite.siret || ''}
        label="Siret"
        onChange={(e) =>
          setCurrentSite({ ...currentSite, siret: e.target.value })
        }
        required={true}
        placeholder=""
      />
      <UiFormControl
        type="text"
        name="phone"
        value={currentSite.phone || ''}
        label="Téléphone"
        onChange={(e) =>
          setCurrentSite({ ...currentSite, phone: e.target.value })
        }
        required={true}
        placeholder=""
      />
      <NewSiteHelper site={currentSite} />
      <Button
        className="w-100 mt-5"
        onClick={onSaveHandler}
        disabled={!isValid}
      >
        Valider
      </Button>
      <Button
        className="w-100 mt-1"
        variant="secondary"
        onClick={onClosePanel}
        disabled={false}
      >
        {site.id.length > 0 ? 'Fermer' : 'Annuler'}
      </Button>
    </div>
  )
}

const NewSiteHelper = ({ site }: { site: WasteSite }) => {
  return (
    <ul>
      <li className={site.name.length > 0 ? 'text-success' : 'text-danger'}>
        Le nom ne peut être vide
      </li>
      <li
        className={
          site.address.match(/\d{5}/) !== null ? 'text-success' : 'text-danger'
        }
      >
        L'adresse doit à minima contenir un code postal et une ville
      </li>
      {site.siret && (
        <li
          className={
            site.siret && site.siret.replace(/\s/g, '').length === 14
              ? 'text-success'
              : 'text-danger'
          }
        >
          Le SIRET, si il est saisi, doit contenir 14 chiffres
        </li>
      )}
      {site.phone && (
        <li
          className={
            site.phone &&
            site.phone.replace(/\s/g, '').replace(/\./g, '').length === 10
              ? 'text-success'
              : 'text-danger'
          }
        >
          Le téléphone, si il est saisi, doit contenir 10 chiffres
        </li>
      )}
    </ul>
  )
}

export default ManageWastesSites

const SitesTableContainer = styled(Table)`
  height: 180px;
  margin-top: 1rem;
  td {
    vertical-align: middle;
  }

  tbody {
    tr {
      td {
        span.trash {
          width: 25px;
          cursor: pointer;
          color: ${AppColors.red};
        }

        span.edit {
          width: 33px;
          color: ${AppColors.green};
          cursor: pointer;
        }

        span.online {
          width: 25px;
          color: ${AppColors.green};
          cursor: pointer;
        }

        span.offline {
          width: 25px;
          color: ${AppColors.red};
          cursor: pointer;
        }
      }
    }
  }
`
const TabsContainer = styled(Tabs)`
  .nav-item.nav-link {
    color: ${AppColors.orange};
  }
  .nav-item.nav-link.active {
    color: ${AppColors.green};
  }
`
