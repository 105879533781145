import { useContext, useEffect, useState } from 'react'
import { Button, Container, Table } from 'react-bootstrap'
import styled from 'styled-components'

import {
  AppUser,
  AppUserRequest,
  Company,
  Role,
} from '../../../../../shared/Interfaces'
import { Firestore, Functions } from '../../../Firebase/Firebase'
import { emulateUser } from '../../../Firebase/emulateUser'
import { showToastError, showToastSuccess } from '../../../Helpers/ToastHelper'
import { getTimestamp } from '../../../Helpers/Various'
import { AuthContext } from '../../../Provider/AuthProvider'
import { UiFormControl, UiFormSelect, UiTopContainer } from '../../UI'

const ManageUsers = () => {
  const { appUser } = useContext(AuthContext)

  const [isLoading, setIsLoading] = useState(true)
  const [users, setUsers] = useState<AppUser[]>([])
  const [companies, setCompanies] = useState<Company[]>([])

  const fetchUsers = async () => {
    const response = await Functions.httpsCallable('GetUsers')()
    return response.data as AppUser[]
  }

  const createUser = async (email: string) => {
    const response = await Functions.httpsCallable('CreateUser')({
      email,
    })
    return response.data as {
      success: boolean
      message: string
    }
  }

  const updateOrCreateAppUser = async (user: AppUser) => {
    if (user.id && user.company) {
      const data = {
        ...user,
        company: user.company.id,
      } as AppUserRequest
      Firestore.collection('users')
        .doc(user.id)
        .set(data)
        .then(() => {
          user.email &&
            setUsers((prevState) => {
              return [...prevState.filter((i) => i.id !== user.id), user]
            })
          setIsLoading(false)
          showToastSuccess("L'utilisateur à été créer/mis à jour avec succés")
        })
        .catch(() => {
          setIsLoading(false)
          showToastError("Une erreur c'est produite lors de la mise à jour")
        })
    }
  }

  const onSaveUser = async (user: AppUser) => {
    const userExist = user.id
    if (user) {
      if (users.find((u) => u.email === user.email) && !userExist) {
        showToastError('Il existe déja un utilisateur avec cet email')
        return
      }

      setIsLoading(true)
      if (!userExist && user.email) {
        const response = await createUser(user.email)
        user.id = response.success ? response.message : undefined
        user.inactive = false
        user.createdAt = getTimestamp()
        if (!response.success) {
          showToastError(response.message)
        }
      } else {
        user.updatedAt = getTimestamp()
      }
      user.id && (await updateOrCreateAppUser(user))
    }
  }

  const onSaveCompany = async (company: Company) => {
    if (company) {
      if (companies.find((c) => c.name === company.name)) {
        showToastError('Il existe déja une société avec cet nom')
        return
      }

      setIsLoading(true)
      company.createdAt = getTimestamp()
      await Firestore.collection('companies')
        .add(company)
        .then((response) => {
          company.id = response.id
          companies &&
            setCompanies((prevState) => {
              return [...prevState, company]
            })
        })
        .catch(() => {
          showToastError(
            'Une erreur est survenue lors de la création de la société'
          )
        })
      setIsLoading(false)
    }
  }

  const onChangeUserStatus = async (user: AppUser) => {
    if (user.email) {
      setIsLoading(true)
      const response = await Functions.httpsCallable('ChangeUserStatus')({
        email: user.email,
        disabled: !user.inactive,
      }).then((resp) => {
        return resp.data as {
          success: boolean
          message: string
        }
      })

      if (response.success === false) {
        showToastError(response.message)
      } else {
        setUsers(
          users.map((u) => {
            if (u.id === user.id) {
              u.inactive = !user.inactive
            }
            return u
          })
        )
        Firestore.collection('users')
          .doc(user.id)
          .update({ inactive: user.inactive, updatedAt: getTimestamp() })
      }
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchUsers()
      .then((response) => {
        response.map((user) => {
          setCompanies((prevState) => {
            return user.company
              ? [
                  ...prevState.filter((i) => i.id !== user.company?.id),
                  user.company,
                ]
              : prevState
          })
        })

        setUsers(response)
        setIsLoading(false)
      })
      .catch(() => {
        showToastError(
          'Un problème est survenu durant la récupération des utilisateurs'
        )
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <UiTopContainer isLoading={isLoading} contentClassName="w-100 mw-100">
      <Container fluid>
        {!isLoading && (
          <UsersTable
            currentUser={appUser!}
            // TODO : Waiting for the promoter view this role is disabled
            users={users.filter((user) => user.role !== 'promoter')}
            companies={companies}
            onSaveUser={onSaveUser}
            onSaveCompany={onSaveCompany}
            onChangeUserStatus={onChangeUserStatus}
            onEmulateUser={emulateUser}
          />
        )}
      </Container>
    </UiTopContainer>
  )
}

const UsersTable = ({
  currentUser,
  users,
  companies,
  onSaveUser,
  onSaveCompany,
  onChangeUserStatus,
  onEmulateUser,
}: {
  currentUser: AppUser
  users: AppUser[]
  companies: Company[]
  onSaveUser: (user: AppUser) => void
  onSaveCompany: (company: Company) => void
  onChangeUserStatus: (user: AppUser) => void
  onEmulateUser: (user: AppUser) => void
}) => {
  const [userEdited, setUserEdited] = useState<AppUser>()
  const [newUser, setNewUser] = useState(false)

  const [companyEdited, setCompanyEdited] = useState<Company>()
  const [newCompany, setNewCompany] = useState(false)

  return (
    <div className="d-flex">
      {userEdited && (
        <EditedUser
          currentUser={currentUser}
          user={userEdited}
          companies={companies}
          onSaveUser={(user) => {
            onSaveUser(user)
            setNewUser(false)
          }}
          onCancelCreate={() => {
            setUserEdited(undefined)
            setNewUser(false)
          }}
        />
      )}
      {companyEdited && (
        <EditedCompany
          company={companyEdited}
          onCancelCreate={() => {
            setCompanyEdited(undefined)
            setNewCompany(false)
          }}
          onSaveCompany={(company) => onSaveCompany(company)}
        />
      )}
      <UsersTableContainer striped bordered hover>
        <thead>
          <tr>
            <th>Rôle</th>
            <th>Prénom</th>
            <th>Nom</th>
            <th>Email</th>
            <th>Société</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan={6}>
              <div className="d-flex justify-content-end">
                <Button
                  size="sm"
                  onClick={() => {
                    setUserEdited({
                      firstName: '',
                      lastName: '',
                      phoneNumber: '',
                      role: 'site-manager',
                      createdAt: getTimestamp(),
                    })
                    setNewUser(!newUser)
                  }}
                  variant={newUser ? 'warning' : 'success'}
                  disabled={newUser || userEdited?.id !== undefined}
                >
                  Ajouter un utilisateur
                </Button>
                <Button
                  size="sm"
                  className="ml-2"
                  onClick={() => {
                    setCompanyEdited({
                      name: '',
                      address: '',
                      siret: '',
                      phone: '',
                      mail: '',
                    })
                    setNewCompany(true)
                  }}
                  disabled={newUser || newCompany}
                  variant={newCompany ? 'warning' : 'success'}
                >
                  Ajouter une société
                </Button>
              </div>
            </td>
          </tr>
          {users.map((user, index) => (
            <tr key={index}>
              <td>{RoleToTextRole(user.role)}</td>
              <td>{user.firstName}</td>
              <td>{user.lastName}</td>
              <td>{user.email}</td>
              <td>{user.company?.name}</td>
              <td style={{ width: 350 }}>
                <div className="d-flex ml-4">
                  {(!user?.inactive && (
                    <>
                      <Button
                        disabled={newUser || newCompany}
                        size="sm"
                        onClick={() =>
                          setUserEdited(
                            !userEdited || userEdited.id != user.id
                              ? user
                              : undefined
                          )
                        }
                        style={{ minWidth: 100 }}
                        variant={
                          userEdited && userEdited.id === user.id
                            ? 'warning'
                            : 'success'
                        }
                      >
                        {userEdited && userEdited.id === user.id
                          ? 'Annuler'
                          : 'Editer'}
                      </Button>
                      <Button
                        disabled={
                          newUser || currentUser.id === user.id || newCompany
                        }
                        variant="danger"
                        style={{ marginLeft: 5, minWidth: 100 }}
                        size="sm"
                        onClick={() => onChangeUserStatus(user)}
                      >
                        Désactiver
                      </Button>
                      <Button
                        disabled={currentUser.id === user.id || user.inactive}
                        variant="info"
                        style={{ marginLeft: 5, minWidth: 100 }}
                        size="sm"
                        onClick={() => onEmulateUser(user)}
                      >
                        Emuler
                      </Button>
                    </>
                  )) || (
                    <Button
                      variant="secondary"
                      style={{ width: 205 }}
                      onClick={() => onChangeUserStatus(user)}
                    >
                      Ce compte est désactivé
                    </Button>
                  )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </UsersTableContainer>
    </div>
  )
}

const RoleToTextRole = (role: string) => {
  switch (role) {
    case 'site-manager':
      return 'Chef de chantier'
    case 'team':
      return 'Equipier'
    case 'promoter':
      return 'Promoteur'
    case 'admin':
      return 'Administrateur'
  }
}

const EditedUser = ({
  currentUser,
  user,
  companies,
  onSaveUser,
  onCancelCreate,
}: {
  currentUser: AppUser
  user?: AppUser
  companies: Company[]
  onSaveUser: (user: AppUser) => void
  onCancelCreate: () => void
}) => {
  const companyOptions = GetCompanyOptions(companies)
  const roleOptions = GetRoleOptions()

  const [editedUser, setEditedUser] = useState<AppUser>()
  const [company, setCompany] = useState(
    user?.company?.id || companyOptions[0].value
  )

  useEffect(() => {
    setEditedUser(user)
  }, [user])

  const EventHandler = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    if (editedUser) {
      if (event.target.id === 'company') {
        const c = companies.find((c) => c.name === event.target.value)
        c && c.id && setCompany(c.id)
      } else if (event.target.id === 'role') {
        const role = roleOptions.find((r) => r.key === event.target.value)
        role && setEditedUser({ ...editedUser, role: role.value as Role })
      } else {
        setEditedUser({
          ...editedUser,
          [event.target.name]: event.target.value,
        })
      }
    }
  }

  const SaveHandler = () => {
    editedUser &&
      onSaveUser({
        ...editedUser,
        company: companies.find((c) => c.id === company),
      })
  }

  const newUserIsValid = () => {
    let isValid = false

    if (editedUser) {
      const emailRegex = new RegExp(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
      const numberRegex = new RegExp(/^\d+$/)

      isValid =
        (editedUser.email &&
          emailRegex.test(editedUser.email) &&
          editedUser.firstName.length > 0 &&
          editedUser.lastName.length > 0 &&
          editedUser.function &&
          editedUser.function.length > 0 &&
          editedUser.phoneNumber.length === 10 &&
          numberRegex.test(editedUser.phoneNumber)) ||
        false

      if (editedUser.siret) {
        isValid =
          editedUser.siret.replace(/\s/g, '').length === 14 &&
          numberRegex.test(editedUser.siret.replace(/\s/g, ''))
      }
    }

    return isValid
  }

  return (
    <div
      style={{
        marginTop: '1rem',
        marginRight: '1rem',
      }}
    >
      <p>{editedUser?.id ? 'Edition utilisateur' : 'Création utilisateur'}</p>
      {!editedUser?.id && (
        <UiFormControl
          type="text"
          name="email"
          value={editedUser?.email || ''}
          label="Mail"
          onChange={(e) => EventHandler(e)}
          required={true}
          placeholder=""
        />
      )}
      <UiFormControl
        type="text"
        name="lastName"
        value={editedUser?.lastName || ''}
        label="Nom"
        onChange={(e) => EventHandler(e)}
        required={true}
        placeholder=""
      />
      <UiFormControl
        type="text"
        name="firstName"
        value={editedUser?.firstName || ''}
        label="Prénom"
        onChange={(e) => EventHandler(e)}
        required={true}
        placeholder=""
      />
      <UiFormControl
        type="text"
        name="function"
        value={editedUser?.function || ''}
        label="Fonction"
        onChange={(e) => EventHandler(e)}
        required={true}
        placeholder=""
      />
      <UiFormSelect
        name="company"
        label="Société"
        options={companyOptions}
        onChange={(e) => EventHandler(e)}
        selectedOption={editedUser?.company?.id}
        disabled={currentUser.id === editedUser?.id}
      />
      <UiFormSelect
        name="role"
        label="Rôle"
        options={roleOptions}
        onChange={(e) => EventHandler(e)}
        selectedOption={editedUser?.role}
        disabled={currentUser.id === editedUser?.id}
      />
      <UiFormControl
        type="text"
        name="phoneNumber"
        value={editedUser?.phoneNumber || ''}
        label="N° de téléphone"
        onChange={(e) => EventHandler(e)}
        required={true}
        placeholder=""
      />
      <UiFormControl
        type="text"
        name="siret"
        value={editedUser?.siret || ''}
        label="Siret"
        onChange={(e) => EventHandler(e)}
        required={true}
        placeholder=""
      />
      <UiFormControl
        type="text"
        name="tvaNumber"
        value={editedUser?.tvaNumber || ''}
        label="N° TVA"
        onChange={(e) => EventHandler(e)}
        required={true}
        placeholder=""
      />
      <div className="d-flex">
        <Button
          className="w-100"
          onClick={SaveHandler}
          disabled={!newUserIsValid()}
        >
          Valider
        </Button>
        {!editedUser?.id && (
          <Button
            variant="danger"
            onClick={onCancelCreate}
            style={{ marginLeft: 5 }}
          >
            Annuler
          </Button>
        )}
      </div>
    </div>
  )
}

const EditedCompany = ({
  company,
  onCancelCreate,
  onSaveCompany,
}: {
  company: Company
  onCancelCreate: () => void
  onSaveCompany: (company: Company) => void
}) => {
  const [editedCompany, setEditedCompany] = useState<Company>()

  useEffect(() => {
    setEditedCompany(company)
  }, [company])

  const EventHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (editedCompany) {
      setEditedCompany({
        ...editedCompany,
        [event.target.name]: event.target.value,
      })
    }
  }

  const newCompanyIsValid = () => {
    let isValid = false

    if (editedCompany) {
      const emailRegex = new RegExp(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
      const numberRegex = new RegExp(/^\d+$/)

      isValid =
        (editedCompany.name.length > 2 && editedCompany.address.length > 5) ||
        false

      if (!editedCompany.mail || !emailRegex.test(editedCompany.mail)) {
        isValid = false
      }

      if (editedCompany.phone && isValid) {
        isValid =
          editedCompany.phone.length === 10 &&
          numberRegex.test(editedCompany.phone)
      } else {
        isValid = false
      }

      if (editedCompany.siret && isValid) {
        isValid =
          editedCompany.siret.replace(/\s/g, '').length === 14 &&
          numberRegex.test(editedCompany.siret.replace(/\s/g, ''))
      } else {
        isValid = false
      }
    }

    return isValid
  }

  return (
    <div
      style={{
        marginTop: '1rem',
        marginRight: '1rem',
      }}
    >
      <p>{editedCompany?.id ? 'Edition société' : 'Création société'}</p>
      <UiFormControl
        type="text"
        name="name"
        value={editedCompany?.name || ''}
        label="Nom"
        onChange={(e) => EventHandler(e)}
        required={true}
        placeholder=""
      />
      <UiFormControl
        type="text"
        name="address"
        value={editedCompany?.address || ''}
        label="Adresse"
        onChange={(e) => EventHandler(e)}
        required={true}
        placeholder=""
      />
      <UiFormControl
        type="text"
        name="siret"
        value={editedCompany?.siret || ''}
        label="Siret"
        onChange={(e) => EventHandler(e)}
        required={true}
        placeholder=""
      />
      <UiFormControl
        type="text"
        name="phone"
        value={editedCompany?.phone || ''}
        label="Téléphone"
        onChange={(e) => EventHandler(e)}
        required={true}
        placeholder=""
      />
      <UiFormControl
        type="text"
        name="mail"
        value={editedCompany?.mail || ''}
        label="Mail"
        onChange={(e) => EventHandler(e)}
        required={true}
        placeholder=""
      />
      <div className="d-flex">
        <Button
          className="w-100"
          onClick={() => editedCompany && onSaveCompany(editedCompany)}
          disabled={!newCompanyIsValid()}
        >
          Valider
        </Button>
        {!editedCompany?.id && (
          <Button
            variant="danger"
            onClick={onCancelCreate}
            style={{ marginLeft: 5 }}
          >
            Annuler
          </Button>
        )}
      </div>
    </div>
  )
}

const GetRoleOptions = () => {
  // TODO : Waiting for the promoter view this role is disabled
  return [
    {
      key: 'Chef de chantier',
      value: 'site-manager',
    },
    {
      key: 'Equipier',
      value: 'team',
    },
    // {
    //   key: 'Promoteur',
    //   value: 'promoter',
    // },
    {
      key: 'Administrateur',
      value: 'admin',
    },
  ]
}

const GetCompanyOptions = (companies: Company[]) => {
  const array: { key: string; value: string }[] = []
  companies.map((company) => {
    array.push({
      key: company.name!,
      value: company.id!,
    })
  })
  return array
}

export default ManageUsers

const UsersTableContainer = styled(Table)`
  height: 280px;
  margin-top: 1rem;
  td {
    vertical-align: middle;
  }
`
