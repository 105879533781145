import { useContext } from 'react'
import { useHistory } from 'react-router-dom'

import { Auth } from '../../Firebase/Firebase'
import { AuthContext } from '../../Provider/AuthProvider'
import { UiHeader, UiTopContainer } from '../UI'

const NotFoundPage = () => {
  const history = useHistory()
  const { authenticated, appUser } = useContext(AuthContext)
  return (
    <>
      <UiHeader
        isDashboardHeader={true}
        history={history}
        isAuthenticated={authenticated}
        role={appUser?.role}
        firesbaseAuth={Auth}
      />
      <UiTopContainer className="m-0 p-0">
        <div className="mt-5 p-5 text-center">
          <h1>Oupssss</h1>
          <h2>La page que vous demandez n'existe pas !</h2>
        </div>
      </UiTopContainer>
    </>
  )
}

export default NotFoundPage
