import React, { useContext, useEffect, useState } from 'react'
import { Button, Card, Col, Container, Row, Tab, Tabs } from 'react-bootstrap'
import { useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components'

import {
  Booking,
  BookingRequest,
  Worksite,
} from '../../../../../shared/Interfaces'
import {
  Auth,
  Firestore,
  ReportStorage,
  Storage,
} from '../../../Firebase/Firebase'
import { StatusTitleHelper } from '../../../Helpers/BookingHelper'
import dayjs from '../../../Helpers/DayjsHelper'
import { showToastError } from '../../../Helpers/ToastHelper'
import { getTimestamp } from '../../../Helpers/Various'
import { AuthContext } from '../../../Provider/AuthProvider'
import { AppColors } from '../../../Ressources/AppColors'
import {
  UiButton,
  UiFormControl,
  UiFormSelect,
  UiHeader,
  UiPhotoContainer,
  UiTopContainer,
} from '../../UI'

interface RouteParams {
  worksiteId: string
}

const SummaryWorksitePage = () => {
  const history = useHistory()
  const { worksiteId } = useParams<RouteParams>()

  const { appUser, authenticated } = useContext(AuthContext)
  const [isLoading, setIsLoading] = useState(true)
  const [booking, setBooking] = useState<Booking>()
  const [worksite, setWorksite] = useState<Worksite>()
  const [editMode, setEditMode] = useState(false)
  const [selectedTab, setSelectedTab] = useState('requests')
  const [requestImageUrl, setImagesRequestUrl] = useState<
    { url: string; phase: number }[]
  >([])
  const [reports, setReports] = useState<{ url: string; phase: number }[]>([])

  const floorOptions = [
    {
      key: 'Non',
      value: '0',
    },

    {
      key: 'Oui',
      value: '1',
    },
  ]

  const EditHandler = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    if (worksite) {
      if (event.target.id === 'floor') {
        setWorksite({
          ...worksite,
          floor: event.target.value.toLocaleLowerCase() === 'oui',
        })
      } else {
        setWorksite({ ...worksite, [event.target.name]: event.target.value })
      }
    }
  }

  const SaveHandler = (e: React.MouseEvent) => {
    e.preventDefault()
    const data = { ...worksite }
    data.updatedAt = getTimestamp()
    delete data.uid
    Firestore.collection('worksites')
      .doc(worksiteId)
      .set(data)
      .then(() => {
        setSelectedTab('worksite')
        setIsLoading(false)
      })
      .catch(() =>
        showToastError(
          'Un problème est survenu durant la mise à jour du chantier'
        )
      )
  }

  useEffect(() => {
    if (worksiteId) {
      Firestore.collection('worksites')
        .doc(worksiteId)
        .get()
        .then(async (doc) => {
          const worksite = doc.data() as Worksite
          setWorksite(worksite)
          await Firestore.collection(`worksites/${worksiteId}/bookings`)
            .orderBy('updatedAt', 'desc')
            .get()
            .then((requests) => {
              setBooking({
                worksite,
                requests: requests.docs.map((booking) => {
                  const data = booking.data() as BookingRequest
                  data.requestId = booking.id
                  return data
                }),
              })
              requests.docs.map((item) => {
                const request = item.data() as BookingRequest

                const storageRef = Storage.ref(
                  `${worksiteId}/P${request.phase}/${
                    request.status === 'finished' ? 'team' : 'client'
                  }`
                )
                storageRef.listAll().then((res) => {
                  res.items.forEach((itemRef) => {
                    itemRef.getDownloadURL().then((url) => {
                      setImagesRequestUrl((imagesUrl) => {
                        return [...imagesUrl, { url, phase: request.phase }]
                      })
                    })
                  })
                })

                const pdfName = `${worksite.orderNumber.replace(/\s/g, '_')}-${
                  request.phase
                }.pdf`

                request.status === 'finished' &&
                  ReportStorage.ref(`${worksiteId}/rapport-${pdfName}`)
                    .getDownloadURL()
                    .then((url) => {
                      if (url) {
                        setReports((reports) => {
                          return [...reports, { url, phase: request.phase }]
                        })
                      }
                    })
                    .catch(() => {
                      console.log(
                        'Error PDF : ' + `${worksiteId}/rapport-${pdfName}`
                      )
                    })
              })
            })
            .catch(() =>
              showToastError(
                'Un problème est survenu durant la récupération des prestations'
              )
            )
            .finally(() => {
              setIsLoading(false)
            })
        })
    }
  }, [worksiteId])

  return (
    <>
      <UiHeader
        isDashboardHeader={false}
        isAuthenticated={authenticated}
        history={history}
        role={appUser?.role}
        firesbaseAuth={Auth}
        title={`Chantier de ${booking?.worksite.customerFirstName} ${booking?.worksite.customerLastname}`}
        callbackHandler={() => history.push('/dashboard')}
      />
      <UiTopContainer isLoading={isLoading}>
        <TabsContainer
          defaultActiveKey={selectedTab}
          onSelect={(k: any) => setSelectedTab(k)}
        >
          <Tab eventKey="worksite" title="Fiche chantier">
            <WorksiteRow
              title="N° bon de commande"
              value={worksite?.orderNumber || ''}
              editMode={editMode}
              editModeName="orderNumber"
              editModeLabel="N° du bon de commande"
              type="input"
              onChangeCallback={EditHandler}
            />
            <WorksiteRow
              title="Nom du client"
              value={worksite?.customerFirstName || ''}
              editMode={editMode}
              editModeName="customerFirstName"
              editModeLabel="Nom du client"
              type="input"
              onChangeCallback={EditHandler}
            />
            <WorksiteRow
              title="Prénom du client"
              value={worksite?.customerLastname || ''}
              editMode={editMode}
              editModeName="customerLastname"
              editModeLabel="Prénom du client"
              type="input"
              onChangeCallback={EditHandler}
            />
            <WorksiteRow
              title="Adresse"
              value={worksite?.customerAddress || ''}
              editMode={editMode}
              editModeName="customerAddress"
              editModeLabel="Adresse du chantier"
              type="input"
              onChangeCallback={EditHandler}
            />
            <WorksiteRow
              title="Surface utile"
              value={worksite?.area.toString() || '0'}
              editMode={editMode}
              editModeName="area"
              editModeLabel="Surface utile"
              type="input"
              onChangeCallback={EditHandler}
            />
            <WorksiteRow
              title="Etage"
              value={worksite?.floor ? 'oui' : 'non'}
              editMode={editMode}
              editModeName="floor"
              editModeLabel="Etage"
              type="select"
              selectOptions={floorOptions}
              onChangeCallback={EditHandler}
            />
            <WorksiteRow
              title="Lien Gmap"
              value={worksite?.gpsMapLink || ''}
              editMode={editMode}
              editModeName="gpsMapLink"
              editModeLabel="Lien Google Map (facultatif)"
              editPlaceHolder="Lien Google Map"
              type="input"
              onChangeCallback={EditHandler}
            />
          </Tab>
          <Tab eventKey="requests" title="Phases">
            {booking?.requests.length === 0 && (
              <div className="d-flex flex-column justify-content-center h-100">
                <NoRequests>Il n'y a aucune phase pour ce chantier</NoRequests>
              </div>
            )}
            {booking?.requests.map((request, index) => {
              const report = reports.find((r) => r.phase === request.phase)
              const staffDate = request.staffDate
                ? request.staffDate.split('/')
                : undefined

              return (
                <RequestsContainer
                  className="mt-1 pb-2"
                  key={`request-container-${index}`}
                >
                  <Card.Header>
                    PHASE {request.phase} ({StatusTitleHelper(request.status)})
                  </Card.Header>
                  <Card.Body className="pl-1">
                    <Card.Text className="p-3 request-date mb-0">
                      {staffDate
                        ? `${dayjs()
                            .set('year', +staffDate[2]!)
                            .set('month', +staffDate[1]!)
                            .set('date', +staffDate[0]!)
                            .subtract(1, 'month')
                            .format('DD/MM/YYYY')}`
                        : `SEMAINE ${request.weekRequested}/${request.yearRequested}`}
                    </Card.Text>
                    {request.note && (
                      <Card.Text className="d-flex flex-column pl-3 mb-2">
                        <span className="font-weight-bold">
                          Votre message :
                        </span>
                        {request.note}
                      </Card.Text>
                    )}
                    {request.teamNote && (
                      <Card.Text className="d-flex flex-column pl-3 mb-2">
                        <span className="font-weight-bold">
                          Message de l'intervenant :
                        </span>
                        {request.teamNote}
                      </Card.Text>
                    )}
                  </Card.Body>
                  <UiPhotoContainer
                    imagesUrl={requestImageUrl
                      .filter((item) => item.phase === request.phase)
                      .map((item) => item.url)}
                    containerHeight={150}
                  />
                  {request.status === 'finished' && report && (
                    <ReportButton className="btn btn-full btn-secondary mr-3 ml-3 d-flex justify-content-center align-items-center">
                      <a href={report.url} target="_blank">
                        Télécharger le borderau de déchet
                      </a>
                    </ReportButton>
                  )}
                </RequestsContainer>
              )
            })}
          </Tab>
        </TabsContainer>
      </UiTopContainer>
      {selectedTab === 'worksite' && (
        <ActionButtons className="d-flex flex-column pr-0 pl-0 w-100 mw-100">
          {!editMode && (
            <UiButton
              element={ActionButton}
              variant="secondary"
              type="button"
              onClick={() => {
                history.push({
                  pathname: `/booking/${worksiteId}/add`,
                  state: {
                    booking,
                  },
                })
              }}
              disabled={false}
              value="Réserver une prestation"
              className="btn-full"
            />
          )}
          <UiButton
            element={ActionButton}
            variant="link"
            type="button"
            onClick={(e) => {
              if (editMode) {
                setIsLoading(true)
                SaveHandler(e)
              }
              setEditMode(!editMode)
            }}
            disabled={false}
            value={
              editMode
                ? 'Valider les modifications'
                : 'Modifier la ficher chantier'
            }
            className="mt-0 btn-full"
          />
        </ActionButtons>
      )}
    </>
  )
}

const WorksiteRow = ({
  title,
  value,
  editMode,
  editModeName,
  editModeLabel,
  editPlaceHolder,
  type,
  selectOptions,
  onChangeCallback,
}: {
  title: string
  value: string
  editMode: boolean
  editModeName: string
  editModeLabel: string
  editPlaceHolder?: string
  type: 'input' | 'select'
  onChangeCallback: (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => void
  selectOptions?: { key: string; value: string }[]
}) => {
  return (
    <Row
      className={`mb-1 p-2 m-0 mw-100 ${
        editMode ? 'flex-column m-0' : 'flex-row'
      }`}
    >
      {!editMode && (
        <>
          <Col
            className={`font-weight-bold : ${
              editMode ? 'text-center mb-1' : 'text-left'
            }`}
          >
            {title}
          </Col>
          <Col className="text-left">{value}</Col>
        </>
      )}
      {editMode && (
        <>
          {type === 'input' && (
            <UiFormControl
              type="text"
              name={editModeName}
              value={value}
              label={editModeLabel}
              placeholder={editPlaceHolder || `Saisissez le ${editModeLabel}`}
              required={true}
              onChange={(event) => {
                onChangeCallback(event)
              }}
              className="mb-0"
            />
          )}
          {type === 'select' && (
            <UiFormSelect
              name={editModeName}
              label={editModeLabel}
              options={selectOptions!}
              onChange={(event) => {
                onChangeCallback(event)
              }}
              selectedOption={value === 'oui' ? '1' : '0'}
              className="mb-0"
            />
          )}
        </>
      )}
    </Row>
  )
}

export default SummaryWorksitePage

const TabsContainer = styled(Tabs)`
  width: 100%;
  .nav-link {
    width: 50%;
    text-align: center;
    border: 0px !important;
    color: #000;
    text-transform: uppercase;
  }

  .nav-link.active {
    border-bottom: 2px solid ${AppColors.green} !important;
    color: ${AppColors.green};
  }
`

const ActionButtons = styled(Container)`
  flex: 0 0 auto;
  background-color: #fff;
`
const ActionButton = styled(Button)`
  border: 0px;
  border-radius: 0;
  text-transform: uppercase;
  :nth-child(2) {
    margin-top: 0.5rem;
  }
`

const NoRequests = styled.h3`
  margin: 30% 1rem;
  color: ${AppColors.orange};
  text-align: center;
`
const RequestsContainer = styled(Card)`
  border-radius: unset;
  border-left: unset;
  border-right: unset;

  .card-header {
    font-size: 1rem;
  }

  .card-body {
    padding: 0;

    .request-date {
      color: ${AppColors.orange};
      font-weight: bold;
    }
  }

  .card-img {
    width: 150px;
    height: 100px;
    object-fit: cover;
  }
`
const ReportButton = styled.div`
  a {
    color: #fff;
  }
`
