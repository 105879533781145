import React, { useEffect, useState, useContext } from 'react'
import { matchPath, useHistory } from 'react-router-dom'

import {
  AgendaRequest,
  AppUser,
  AppUserRequest,
  BookingRequest,
  Company,
  Worksite,
} from '../../../shared/Interfaces'
import { AgendaTeam } from '../../../shared/Interfaces/Agenda'
import { Firestore } from '../Firebase/Firebase'
import dayjs from '../Helpers/DayjsHelper'
import { showToastError } from '../Helpers/ToastHelper'
import { dashboardTeamWithDateParam } from '../Routes/TeamDashboardRoutes'

import { AuthContext } from './AuthProvider'

interface RouteParams {
  date?: string
}

type TeamDataContextProps = {
  isLoading: boolean
  appUsers: AppUser[] | []
  activeUser: AppUser | null
  setActiveUser: (user: AppUser) => void
  activeDate: Date | null
  setActiveDate: (date: Date) => void
  agenda: AgendaTeam[] | []
}

export const TeamDataContext = React.createContext<TeamDataContextProps>({
  isLoading: true,
  appUsers: [],
  activeUser: null,
  setActiveUser: () => {},
  activeDate: null,
  setActiveDate: () => {},
  agenda: [],
})

export const TeamDataProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const history = useHistory()

  const match = matchPath<RouteParams>(history.location.pathname, {
    path: dashboardTeamWithDateParam,
  })

  const { date } = match?.params ?? {}

  const { appUser } = useContext(AuthContext)

  const [isLoading, setIsLoading] = useState(true)

  const [appUsers, setAppUsers] = useState<AppUser[]>([])
  const [activeUser, setActiveUser] = useState<AppUser | null>(appUser)

  const [activeDate, setActiveDate] = useState<Date | null>(null)

  const [agenda, setAgenda] = useState<AgendaTeam[]>([])

  useEffect(() => {
    const fetchUsers = async () => {
      Firestore.collection('users')
        .get()
        .then(async (snapshot) => {
          const results = await Promise.all(
            snapshot.docs.map(async (item) => {
              const data = item.data() as AppUserRequest
              if (!data) {
                return
              }
              const { company: companyId, ...user } = data
              const companySnapShot = await Firestore.collection('companies')
                .doc(companyId)
                .get()

              const company = companySnapShot.data() as Company

              return {
                ...user,
                company: { ...company, id: data.company },
                id: item.id,
              }
            })
          )

          const users = results.filter((v) => v != null) as AppUser[]
          setAppUsers(users)
        })
        .catch(() =>
          showToastError(
            'Un problème est survenu durant la récupération des utilisateurs'
          )
        )
    }

    const fetchBookings = async () => {
      if (activeDate) {
        setIsLoading(true)
        const date = dayjs(activeDate)
        Firestore.collection(`calendar/${date.get('year')}/S${date.week()}`)
          .get()
          .then(async (snapshot) => {
            const results = await Promise.all(
              snapshot.docs.map(async (item) => {
                const data = item.data() as AgendaRequest
                if (!data) {
                  return
                }

                if (
                  data.date &&
                  data.team &&
                  activeUser &&
                  data.date === date.format('DD/MM/YYYY') &&
                  data.team === activeUser?.id
                ) {
                  const requestDocument = await Firestore.collection(
                    `worksites/${data.worksite}/bookings`
                  )
                    .doc(item.id)
                    .get()
                  const worksiteDocument = await Firestore.collection(
                    'worksites'
                  )
                    .doc(data.worksite)
                    .get()

                  const request = requestDocument.data() as BookingRequest
                  const worksite = worksiteDocument.data() as Worksite
                  return {
                    request: { ...request, requestId: requestDocument.id },
                    worksite: { ...worksite, uid: data.worksite },
                    team: data.team || '',
                    lockReason:
                      data.worksite === 'virtual-worksite'
                        ? data.lockReason
                        : undefined,
                  }
                }
              })
            )

            const response = results.filter((v) => v != null) as AgendaTeam[]
            setAgenda(response)
          })
          .catch(() =>
            showToastError(
              'Un problème est survenu durant la récupération des demandes'
            )
          )
      }
    }

    fetchUsers()
      .then(() => fetchBookings())
      .finally(() => setIsLoading(false))
  }, [activeDate, activeUser])

  useEffect(() => {
    if (date) {
      const dayjsDate = dayjs(date)
      setActiveDate(dayjsDate.toDate())
    } else {
      setActiveDate(new Date())
    }
  }, [date])

  const updateActiveDate = (date: Date) => {
    const dayjsDate = dayjs(date)
    setActiveDate(date)
    history.replace(`/dashboard/date/${dayjsDate.format('YYYY-MM-DD')}`)
  }

  return (
    <TeamDataContext.Provider
      value={{
        isLoading,
        appUsers,
        activeUser,
        setActiveUser,
        activeDate,
        setActiveDate: updateActiveDate,
        agenda,
      }}
    >
      {children}
    </TeamDataContext.Provider>
  )
}
