import { Form, InputGroup } from 'react-bootstrap'

import { UiFormInput, UiFormLabel } from '..'

interface Props {
  type: string
  name: string
  value: string
  label: string
  placeholder: string
  required: boolean
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  className?: string
  withAppend?: boolean
  appendValue?: string | JSX.Element
  withPrepend?: boolean
  prependValue?: string | JSX.Element
  appendOnClick?: () => void
  disabled?: boolean
  max?: number
  min?: number
}

const UiFormControl = (props: Props) => {
  return (
    <Form.Group
      controlId={`form${props.name}`}
      className={`text-left ${props.className ? `${props.className}` : ''}`}
    >
      {!props.withAppend && !props.withPrepend && (
        <>
          <UiFormLabel value={props.label} />
          <UiFormInput
            type={props.type}
            name={props.name}
            placeholder={props.placeholder}
            value={props.value}
            onChange={props.onChange}
            required={props.required}
            disabled={props.disabled || false}
            max={props.max || undefined}
            min={props.min || undefined}
          />
        </>
      )}
      {props.withAppend &&
        props.appendValue &&
        props.withPrepend &&
        props.prependValue && (
          <>
            <UiFormLabel value={props.label} />
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text>{props.prependValue}</InputGroup.Text>
              </InputGroup.Prepend>
              <UiFormInput
                type={props.type}
                name={props.name}
                placeholder={props.placeholder}
                value={props.value}
                onChange={props.onChange}
                required={props.required}
                disabled={props.disabled || false}
                max={props.max || undefined}
                min={props.min || undefined}
              />
              <InputGroup.Append
                onClick={() => props.appendOnClick && props.appendOnClick()}
              >
                <InputGroup.Text>{props.appendValue}</InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>
          </>
        )}
      {props.withAppend && !props.withPrepend && props.appendValue && (
        <>
          <UiFormLabel value={props.label} />
          <InputGroup>
            <UiFormInput
              type={props.type}
              name={props.name}
              placeholder={props.placeholder}
              value={props.value}
              onChange={props.onChange}
              required={props.required}
              disabled={props.disabled || false}
              max={props.max || undefined}
              min={props.min || undefined}
            />
            <InputGroup.Append>
              <InputGroup.Text>{props.appendValue}</InputGroup.Text>
            </InputGroup.Append>
          </InputGroup>
        </>
      )}
      {props.withPrepend && !props.withAppend && props.prependValue && (
        <>
          <UiFormLabel value={props.label} />
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text>{props.prependValue}</InputGroup.Text>
            </InputGroup.Prepend>
            <UiFormInput
              type={props.type}
              name={props.name}
              placeholder={props.placeholder}
              value={props.value}
              onChange={props.onChange}
              required={props.required}
              disabled={props.disabled || false}
              max={props.max || undefined}
              min={props.min || undefined}
            />
          </InputGroup>
        </>
      )}
    </Form.Group>
  )
}

export default UiFormControl
