import { Col, Row } from 'react-bootstrap'
import styled from 'styled-components'

import { UiLoader } from '.'

import 'react-toastify/dist/ReactToastify.css'

interface Props {
  className?: string
  contentClassName?: string
  isLoading?: boolean
}

const UiTopContainer: React.FC<Props> = ({
  children,
  className,
  contentClassName,
  isLoading,
}) => {
  return (
    <TopContainer className={`${className ? className : ''} m-0`}>
      <Col
        className={`content p-0 ${contentClassName ? contentClassName : ''}`}
        md={12}
        lg={8}
      >
        {isLoading ? <UiLoader /> : children}
      </Col>
    </TopContainer>
  )
}

export default UiTopContainer

const TopContainer = styled(Row)`
  height: 100%;
  overflow: auto;
  .content {
    flex: 1 1 auto;
    margin: 0 auto !important;
  }
`
