import Resizer from 'react-image-file-resizer'

const resizeImage = (
  file: File,
  width: number,
  height: number
): Promise<File> => {
  return new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      width,
      height,
      'JPEG',
      100,
      0,
      (uri) => {
        resolve(uri as File)
      },
      'file'
    )
  })
}

export { resizeImage }
