import dayjs from 'dayjs'
import fr from 'dayjs/locale/fr'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import weekday from 'dayjs/plugin/weekday'

dayjs.locale(fr)
dayjs.extend(weekOfYear)
dayjs.extend(weekday)

export default dayjs
