import { Form } from 'react-bootstrap'

interface Props {
  value: string
  className?: string
}

const UiFormLabel = (props: Props) => {
  return <Form.Label className={props.className}>{props.value}</Form.Label>
}

export default UiFormLabel
