import React from 'react'
import { Form } from 'react-bootstrap'

interface Props {
  name: string
  label: string
  buttonTitle: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const UiButtonFile = (props: Props) => {
  return (
    <Form.Group>
      <Form.File
        type="file"
        id={props.name}
        label={props.label}
        data-browse={props.buttonTitle}
        onChange={props.onChange} //setFileName(e.target.files[0].name)}
        custom
      />
    </Form.Group>
  )
}

export default UiButtonFile
