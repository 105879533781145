import { useState } from 'react'
import { Accordion, Card, ListGroup } from 'react-bootstrap'

import { AppUser, Worksite } from '../../../../shared/Interfaces'
import { Functions } from '../../Firebase/Firebase'

const UIWorksiteAndRequestor = ({
  worksite,
  siteManager,
  siteManagerFull,
  rounded,
  bordered,
  accordionClassName,
}: {
  worksite?: Worksite
  siteManager?: AppUser
  siteManagerFull: boolean
  rounded: boolean
  bordered: boolean
  accordionClassName?: string
}) => {
  const [email, setEmail] = useState<string | undefined>(undefined)

  const getEmail = async (id: string) => {
    const response = await Functions.httpsCallable('GetMailFromUserId')({
      id,
    })
    setEmail(response.data)
  }

  siteManager && siteManager.id && getEmail(siteManager.id)

  return (
    <>
      <Accordion className={accordionClassName && accordionClassName}>
        {worksite && (
          <Card
            className={`${!rounded && 'rounded-0'} ${
              !bordered && 'border-right-0 border-left-0'
            }`}
          >
            <Accordion.Toggle as={Card.Header} eventKey="0">
              Fiche chantier
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <ListGroup className="selected-group">
                <ListGroup.Item
                  className={`d-flex justify-content-between ${
                    !bordered && 'border-right-0 border-left-0'
                  }`}
                >
                  <span className="font-weight-bold">
                    N° de bon de commande
                  </span>
                  <span className="text-right ml-3">
                    {worksite.orderNumber}
                  </span>
                </ListGroup.Item>
                <ListGroup.Item
                  className={`d-flex justify-content-between ${
                    !bordered && 'border-right-0 border-left-0'
                  }`}
                >
                  <span className="font-weight-bold">Nom du client</span>
                  <span className="text-right ml-3">
                    {worksite.customerFirstName}
                  </span>
                </ListGroup.Item>
                <ListGroup.Item
                  className={`d-flex justify-content-between ${
                    !bordered && 'border-right-0 border-left-0'
                  }`}
                >
                  <span className="font-weight-bold">Prénom du client</span>
                  <span className="text-right ml-3">
                    {worksite.customerLastname}
                  </span>
                </ListGroup.Item>
                <ListGroup.Item
                  className={`d-flex justify-content-between ${
                    !bordered && 'border-right-0 border-left-0'
                  }`}
                >
                  <span className="font-weight-bold">Adresse</span>
                  <span className="text-right ml-3">
                    {worksite.customerAddress}
                  </span>
                </ListGroup.Item>
                <ListGroup.Item
                  className={`d-flex justify-content-between ${
                    !bordered && 'border-right-0 border-left-0'
                  }`}
                >
                  <span className="font-weight-bold">Lien Gmap</span>
                  <span className="text-right ml-3">
                    {worksite.gpsMapLink && (
                      <a href={worksite.gpsMapLink}>
                        Cliquer pour localiser sur Gmap
                      </a>
                    )}
                  </span>
                </ListGroup.Item>
                <ListGroup.Item
                  className={`d-flex justify-content-between ${
                    !bordered && 'border-right-0 border-left-0'
                  }`}
                >
                  <span className="font-weight-bold">Surface Utile</span>
                  <span className="text-right ml-3">{worksite.area}</span>
                </ListGroup.Item>
                <ListGroup.Item
                  className={`d-flex justify-content-between ${
                    !bordered && 'border-right-0 border-left-0 border-bottom-0'
                  }`}
                >
                  <span className="font-weight-bold">Etage</span>
                  <span className="text-right ml-3">
                    {worksite.floor ? 'Oui' : 'Non'}
                  </span>
                </ListGroup.Item>
              </ListGroup>
            </Accordion.Collapse>
          </Card>
        )}
        {siteManager && (
          <Card
            className={`${!rounded && 'rounded-0'} ${
              !bordered && 'border-right-0 border-left-0'
            }`}
          >
            <Accordion.Toggle as={Card.Header} eventKey="1">
              Demandeur
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="1">
              <ListGroup className="selected-group">
                <ListGroup.Item
                  className={`d-flex justify-content-between ${
                    !bordered && 'border-right-0 border-left-0'
                  }`}
                >
                  <span className="font-weight-bold">Nom du contact</span>
                  <span className="text-right ml-3">
                    {siteManager.lastName}
                  </span>
                </ListGroup.Item>
                <ListGroup.Item
                  className={`d-flex justify-content-between ${
                    !bordered && 'border-right-0 border-left-0'
                  }`}
                >
                  <span className="font-weight-bold">Prénom du contact</span>
                  <span className="text-right ml-3">
                    {siteManager.firstName}
                  </span>
                </ListGroup.Item>
                <ListGroup.Item
                  className={`d-flex justify-content-between ${
                    !bordered && 'border-right-0 border-left-0'
                  }`}
                >
                  <span className="font-weight-bold">Function</span>
                  <span className="text-right ml-3">
                    {siteManager.function}
                  </span>
                </ListGroup.Item>
                <ListGroup.Item
                  className={`d-flex justify-content-between ${
                    !bordered &&
                    `border-right-0 border-left-0 ${
                      !siteManagerFull && 'border-bottom-0'
                    }`
                  }`}
                >
                  <span className="font-weight-bold">Entreprise</span>
                  <span className="text-right ml-3">
                    {siteManager.company?.name}
                  </span>
                </ListGroup.Item>
                {siteManagerFull && (
                  <>
                    <ListGroup.Item
                      className={`d-flex justify-content-between ${
                        !bordered && 'border-right-0 border-left-0'
                      }`}
                    >
                      <span className="font-weight-bold">Siret</span>
                      <span className="text-right ml-3">
                        {siteManager.siret}
                      </span>
                    </ListGroup.Item>
                    <ListGroup.Item
                      className={`d-flex justify-content-between ${
                        !bordered && 'border-right-0 border-left-0'
                      }`}
                    >
                      <span className="font-weight-bold">N° de TVA</span>
                      <span className="text-right ml-3">
                        {siteManager.tvaNumber}
                      </span>
                    </ListGroup.Item>
                    <ListGroup.Item
                      className={`d-flex justify-content-between ${
                        !bordered && 'border-right-0 border-left-0'
                      }`}
                    >
                      <span className="font-weight-bold">Téléphone</span>
                      <span className="text-right ml-3">
                        {siteManager.phoneNumber}
                      </span>
                    </ListGroup.Item>
                    <ListGroup.Item
                      className={`d-flex justify-content-between ${
                        !bordered &&
                        'border-right-0 border-left-0 border-bottom-0'
                      }`}
                    >
                      <span className="font-weight-bold">E-mail</span>
                      <span className="text-right ml-3">
                        {!email ? 'Recherche en cours...' : email}
                      </span>
                    </ListGroup.Item>
                  </>
                )}
              </ListGroup>
            </Accordion.Collapse>
          </Card>
        )}
      </Accordion>
    </>
  )
}

export default UIWorksiteAndRequestor
