import { Container, Row } from 'react-bootstrap'
import styled from 'styled-components'

import { LogoImage } from './LoginPage'

export const MaintenanceMode = () => {
  return (
    <MaintenanceModeContainer className="d-flex justify-content-center align-items-center">
      <Container>
        <Row className="d-flex flex-column align-items-center mt-5 mb-5">
          <div className="col-sm col-md">
            <LogoImage
              src="/images/logo.png"
              className="logo mx-auto d-block img-thumbnail"
            />
          </div>
          <div className="text-center">
            <h3>
              L'application est en maintenance,
              <br />
              merci de revenir dans quelques minutes !
            </h3>
          </div>
        </Row>
      </Container>
    </MaintenanceModeContainer>
  )
}

const MaintenanceModeContainer = styled(Container)`
  height: 100vh;
`
