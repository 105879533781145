import { useState } from 'react'
import { Row, Col, Image, Modal } from 'react-bootstrap'
import styled from 'styled-components'
interface Props {
  imagesUrl: string[]
  onDeleteImage?: (url: string) => void
  className?: string
  containerHeight?: number
}

const UiPhotoContainer = (props: Props) => {
  const [imageUrl, setImageUrl] = useState('')
  const [show, setShow] = useState(false)

  return (
    (props.imagesUrl.length > 0 && (
      <>
        <Container
          className={`m-0 p-0 ${props.className ? props.className : ''}`}
          height={props.containerHeight || 250}
        >
          {props.imagesUrl.map((image, index) => (
            <>
              <Col
                className="position-relative pl-3"
                key={`photo-container-col-${index}`}
                xs={6}
                md={4}
                lg={3}
              >
                <Image
                  src={image}
                  onClick={() => {
                    setImageUrl(image)
                    setShow(true)
                  }}
                />
                {props.onDeleteImage && (
                  <span
                    className="position-absolute"
                    onClick={() =>
                      props.onDeleteImage && props.onDeleteImage(image)
                    }
                  >
                    x
                  </span>
                )}
              </Col>
            </>
          ))}
          <ImageModal
            url={imageUrl}
            show={show}
            handleClose={() => setShow(false)}
          />
        </Container>
      </>
    )) || <></>
  )
}

const ImageModal = ({
  url,
  show,
  handleClose,
}: {
  url: string
  show: boolean
  handleClose: () => void
}) => (
  <Modal show={show} onHide={handleClose} keyboard={true} centered size="lg">
    <ModalBody>
      <Image src={url} width="100%" />
    </ModalBody>
  </Modal>
)

export default UiPhotoContainer

const Container = styled(Row)<{ height: number }>`
  width: 100%;
  height: ${(props) => props.height}px;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
  }

  span {
    right: 25px;
    top: 10px;
    color: #fff;
    background-color: #000;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 700;
    border-radius: 50%;
    padding: 6px 15px;
  }
`

const ModalBody = styled(Modal.Body)`
  padding: 5px;
`
