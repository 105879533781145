import { Form } from 'react-bootstrap'

interface Props {
  name: string
  label: string
  rows: number
  className?: string
  value?: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  readonly?: boolean
}

const UiFormTextarea = (props: Props) => {
  return (
    <Form.Group controlId={props.name} className={props.className}>
      <Form.Label>{props.label}</Form.Label>
      <Form.Control
        as="textarea"
        rows={props.rows}
        value={props.value}
        onChange={props.onChange}
        readOnly={props.readonly || false}
      />
    </Form.Group>
  )
}

export default UiFormTextarea
