import { User } from '@firebase/auth-types'
import React, { useEffect, useState } from 'react'

import { AppUser, AppUserRequest, Company } from '../../../shared/Interfaces'
import { Auth, Firestore } from '../Firebase/Firebase'

type AuthContextProps = {
  user: User | null
  isEmulated: boolean
  appUser: AppUser | null
  authenticated: boolean
  loadingAuthState: boolean
  setUser: any
  error: string | undefined
  isMaintenanceMode: boolean
}

export const AuthContext = React.createContext<AuthContextProps>({
  user: null,
  setUser: null,
  appUser: null,
  isEmulated: false,
  authenticated: false,
  loadingAuthState: false,
  error: undefined,
  isMaintenanceMode: false,
})

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [user, setUser] = useState<User | null>(null)
  const [appUser, setAppUser] = useState(null as AppUser | null)
  const [loadingAuthState, setLoadingAuthState] = useState(true)
  const [error, setError] = useState<string | undefined>(undefined)
  const [isMaintenanceMode, setIsMaintenanceMode] = useState(false)
  const [isEmulated, setIsEmulated] = useState(false)

  useEffect(() => {
    const fetchMaintenanceMode = async () => {
      const result = await Firestore.collection('job-data')
        .doc('application')
        .get()
      const data = result.data()
      setIsMaintenanceMode(data?.isMaintenanceMode || false)
    }

    fetchMaintenanceMode()

    Auth.onAuthStateChanged((user) => {
      setError(undefined)
      setUser(user)
      setLoadingAuthState(false)
      user?.getIdTokenResult().then((token) => {
        const actualUserId = token.claims.actualUser
        setIsEmulated(actualUserId != null && user?.uid != actualUserId)
      })
    })
  }, [])

  useEffect(() => {
    if (!user) {
      setAppUser(null)
      return
    }
    const userId = user.uid
    Firestore.collection('users')
      .doc(user.uid)
      .onSnapshot({
        next: async (snapshot) => {
          const data = snapshot.data() as AppUserRequest
          if (!data) {
            return
          }
          const { company: companyId, ...user } = data
          const companySnapShot = await Firestore.collection('companies')
            .doc(companyId)
            .get()

          const company = companySnapShot.data() as Company
          setAppUser({ ...user, company, id: userId })
        },
        error: (_error) => {
          setError('Erreur durant la récupération de votre profil utilisateur')
        },
      })
  }, [user])
  return (
    <AuthContext.Provider
      value={{
        user,
        appUser,
        setUser,
        authenticated: user !== null,
        isEmulated,
        loadingAuthState,
        error,
        isMaintenanceMode,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
