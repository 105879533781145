import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { Timestamp } from '../../../shared/@types/firebase-timestamp'
import {
  Agenda,
  AgendaRequest,
  AppUser,
  AppUserRequest,
  BookingRequest,
  Company,
  Worksite,
} from '../../../shared/Interfaces'
import { UnavailableAgenda } from '../../../shared/Interfaces/Agenda'
import { AdminRequestValidation } from '../../../shared/Interfaces/Booking'
import { Firestore, Functions, Storage } from '../Firebase/Firebase'
import dayjs from '../Helpers/DayjsHelper'
import { showToastError } from '../Helpers/ToastHelper'
import { getTimestamp } from '../Helpers/Various'

type AdminDataContextProps = {
  isLoading: boolean
  week: number | null
  setWeek: (week: number) => void
  year: number | null
  setYear: (week: number) => void
  appUsers: AppUser[] | []
  setIsLoading: (isLoading: boolean) => void
  showBookingDetails: boolean
  setShowBookingDetails: (show: boolean) => void
  agenda: Agenda[] | []
  selectedAgenda: Agenda | null
  setSelectedAgenda: (agenda: Agenda | null) => void
  selectedAgendaSiteManager: AppUser | null
  requestValidation: AdminRequestValidation | null
  setRequestValidation: (data: AdminRequestValidation) => void
  closeWithoutSaving: () => void
  onSelectingDate: (date: string, team: AppUser, slotsUsed: number) => void
  onSaveDate: () => void
  cancelMode: boolean
  setCancelMode: (isCancelMode: boolean) => void
  reportDate: string | null
  onCLickExistingBooking: (items: Agenda[], index: number) => void
  unavailabilityMode: boolean
  setUnavailabilityMode: (unavailability: boolean) => void
  onSelectingUnavailableDate: (
    slots: number,
    reason: string,
    date?: string,
    team?: AppUser,
    alreadyValidated?: boolean
  ) => void
  unavailableAgenda: UnavailableAgenda
  onSaveUnavailableDate: () => void
  onCLickExistingUnavailable: (date: string, teamId: string) => void
  onDeleteExistingUnavailable: () => void
}

export const AdminDataContext = React.createContext<AdminDataContextProps>({
  isLoading: true,
  week: null,
  setWeek: () => {},
  year: null,
  setYear: () => {},
  appUsers: [],
  setIsLoading: () => {},
  showBookingDetails: false,
  setShowBookingDetails: () => {},
  agenda: [],
  selectedAgenda: null,
  setSelectedAgenda: () => {},
  selectedAgendaSiteManager: null,
  requestValidation: null,
  setRequestValidation: () => {},
  closeWithoutSaving: () => {},
  onSelectingDate: () => {},
  onSaveDate: () => {},
  cancelMode: false,
  setCancelMode: () => {},
  reportDate: null,
  onCLickExistingBooking: () => {},
  unavailabilityMode: false,
  setUnavailabilityMode: () => {},
  onSelectingUnavailableDate: () => {},
  unavailableAgenda: {
    slots: 1,
    reason: '',
    alreadyValidated: false,
    maxSlots: 5,
  },
  onSaveUnavailableDate: () => {},
  onCLickExistingUnavailable: () => {},
  onDeleteExistingUnavailable: () => {},
})

export const AdminDataProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const originalUnavailableAgenda = {
    slots: 1,
    reason: '',
    alreadyValidated: false,
    maxSlots: 5,
  }

  const [isLoading, setIsLoading] = useState(true)

  const [year, setYear] = useState(dayjs().get('year'))
  const [week, setWeek] = useState(dayjs().week())
  const [appUsers, setAppUsers] = useState<AppUser[]>([])
  const [showBookingDetails, setShowBookingDetails] = useState(false)

  const [initialAgenda, setInitialAgenda] = useState<Agenda[]>([])
  const [agenda, setAgenda] = useState<Agenda[]>([])
  const [selectedAgenda, setSelectedAgenda] = useState<Agenda | null>(null)

  const [selectedAgendaSiteManager, setSelectedAgendaSiteManager] =
    useState<AppUser | null>(null)

  const [requestValidation, setRequestValidation] =
    useState<AdminRequestValidation | null>(null)

  const [cancelMode, setCancelMode] = useState(false)
  const [reportDate, setRepportDate] = useState<string | null>(null)

  const [unavailabilityMode, setUnavailabilityMode] = useState(false)
  const [unavailableAgenda, setUnavailableAgenda] = useState<UnavailableAgenda>(
    originalUnavailableAgenda
  )

  const location = useLocation<{ reloadData: boolean }>()
  const [reloadData, setReloadData] = useState<boolean>(false)

  useEffect(() => {
    const fetchBookings = async () => {
      Firestore.collection(`calendar/${year}/S${week}`)
        .get()
        .then(async (snapshot) => {
          const results = await Promise.all(
            snapshot.docs.map(async (item) => {
              const data = item.data() as AgendaRequest
              if (!data) {
                return
              }

              const worksiteDocument = await Firestore.collection('worksites')
                .doc(data.worksite)
                .get()

              const requestDocument = await Firestore.collection(
                `worksites/${data.worksite}/bookings`
              )
                .doc(item.id)
                .get()

              const teamDocument = await Firestore.collection('users')
                .doc(data.team)
                .get()

              const photosUrl: string[] = []

              const worksite = worksiteDocument.data() as Worksite
              worksite.uid = worksiteDocument.id
              const request = requestDocument.data() as BookingRequest

              const team = data.team
                ? (teamDocument.data() as AppUser)
                : undefined

              if (request) {
                const storageRef = Storage.ref(
                  `${data.worksite}/P${request.phase}/client`
                )
                storageRef.listAll().then((res) => {
                  res.items.forEach((itemRef) => {
                    itemRef.getDownloadURL().then((url) => {
                      photosUrl.push(url)
                    })
                  })
                })
              }

              return {
                request: { ...request, requestId: requestDocument.id },
                worksite,
                date: data.date,
                slots: (data.slots && +data.slots) || undefined,
                team: { ...team, id: teamDocument.id },
                photosUrl: photosUrl,
                lock: data.lock || false,
                lockReason: data.lockReason || '',
              }
            })
          )

          const response = results.filter((v) => v != null) as Agenda[]
          setAgenda(response)
          setInitialAgenda(response)
        })
        .catch(() => {
          showToastError(
            'Un problème est survenu durant la récupération des prestations'
          )
        })
    }

    const fetchUsers = async () => {
      Firestore.collection('users')
        .where('inactive', '==', false)
        .get()
        .then(async (snapshot) => {
          const results = await Promise.all(
            snapshot.docs.map(async (item) => {
              const data = item.data() as AppUserRequest
              if (!data) {
                return
              }
              const { company: companyId, ...user } = data
              const companySnapShot = await Firestore.collection('companies')
                .doc(companyId)
                .get()

              const company = companySnapShot.data() as Company

              return {
                ...user,
                company: { ...company, id: data.company },
                id: item.id,
              }
            })
          )

          const users = results.filter((v) => v != null) as AppUser[]
          setAppUsers(users)
        })
        .catch(() =>
          showToastError(
            'Un problème est survenu durant la récupération des utilisateurs'
          )
        )
    }

    fetchBookings()
      .then(() => fetchUsers())
      .finally(() => {
        if (!cancelMode) {
          setShowBookingDetails(false)
          setCancelMode(false)
        } else {
          setRepportDate(`${week}/${year}`)
        }
        setReloadData(false)
        setIsLoading(false)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [year, week, reloadData, location.state?.reloadData])

  useEffect(() => {
    if (selectedAgenda) {
      const ownerId = selectedAgenda.worksite.owner
      const owner = appUsers.find((u) => u.id === ownerId)
      setSelectedAgendaSiteManager(owner || null)
      setRequestValidation({
        request: selectedAgenda.request,
        slots: requestValidation
          ? requestValidation?.slots
          : selectedAgenda.slots || 1,
        teamNote: selectedAgenda.request.staffNote || '',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAgenda])

  useEffect(() => {
    if (!cancelMode) {
      setRepportDate(null)
    }
  }, [cancelMode])

  const onSelectingDate = (date: string, team: AppUser, slotsUsed: number) => {
    if (
      selectedAgenda &&
      requestValidation &&
      5 - slotsUsed + (Number(selectedAgenda?.originalSlots) || 0) >=
        requestValidation?.slots
    ) {
      let slots = requestValidation?.slots

      agenda.forEach((j) => {
        if (j.request.requestId === selectedAgenda.request.requestId) {
          j.slots = slots
          j.date = date
        }
      })

      setSelectedAgenda({
        ...selectedAgenda,
        date,
        team,
        slots,
        originalSlots: requestValidation?.slots,
        alreadyValidated: false,
      })
    }
  }

  const onSelectingUnavailableDate = (
    slots: number,
    reason: string,
    date?: string,
    team?: AppUser,
    alreadyValidated?: boolean
  ) => {
    let slotsUsed = 0
    if (date && team) {
      agenda.forEach((a) => {
        if (a.date === date && a.team?.id === team.id && a.lock === false) {
          slotsUsed += a.slots || 0
        }
      })
    }

    const isAlreadyValidated =
      alreadyValidated !== undefined
        ? alreadyValidated
        : unavailableAgenda.alreadyValidated

    setUnavailableAgenda({
      date,
      team,
      slots,
      reason: reason,
      alreadyValidated: isAlreadyValidated,
      maxSlots: 5 - slotsUsed,
    })
  }

  const onSaveDate = () => {
    if (year && week && !cancelMode) {
      Firestore.collection('calendar')
        .doc(year.toString())
        .collection(`S${week.toString()}`)
        .doc(selectedAgenda?.request.requestId)
        .update({
          team: selectedAgenda?.team?.id,
          slots: selectedAgenda?.slots,
          date: selectedAgenda?.date,
          updatedAt: getTimestamp(),
        })
        .catch(() =>
          showToastError(
            'Un problème est survenu durant la sauvegarde de la prestation'
          )
        )

      Firestore.collection(`worksites/${selectedAgenda?.worksite.uid}/bookings`)
        .doc(selectedAgenda?.request.requestId)
        .update({
          status: 'accepted',
          staffNote: requestValidation?.teamNote,
          staffDate: selectedAgenda?.date,
          updatedAt: getTimestamp(),
        })
        .catch(() =>
          showToastError(
            'Un problème est survenu durant le changement de status'
          )
        )
        .finally(() => {
          setSelectedAgenda(null)
          setShowBookingDetails(false)
          setCancelMode(false)
          setRepportDate(null)
          setIsLoading(true)
          setReloadData(true)
        })
      if (selectedAgenda?.date !== selectedAgenda?.originalDate) {
        Functions.httpsCallable('SendMailStatusAccepted')({
          worksiteId: selectedAgenda?.worksite.uid,
          week: week.toString(),
          date: selectedAgenda?.date,
        })
      }
    }
    if (cancelMode) {
      const worksiteModifications: {
        status: string
        weekRequested?: string
        yearRequested?: string
        staffDate?: string
        updatedAt: Timestamp
      } = { status: 'canceled', updatedAt: getTimestamp() }

      if (reportDate) {
        const splitReport = reportDate.split('/')
        worksiteModifications.staffDate = undefined
        worksiteModifications.weekRequested = splitReport[0]
        worksiteModifications.yearRequested = splitReport[1]
        //worksiteModifications.status = 'deferred'

        Firestore.collection(`calendar/${splitReport[1]}/S${splitReport[0]}`)
          .doc(selectedAgenda?.request.requestId)
          .set({
            worksite: selectedAgenda?.worksite.uid,
            createdAt: getTimestamp(),
          })
          .catch(() =>
            showToastError(
              'Un problème est survenu durant le changement de semaine'
            )
          )
      }

      // Delete old request
      Firestore.collection(
        `calendar/${selectedAgenda?.request.yearRequested}/S${selectedAgenda?.request.weekRequested}`
      )
        .doc(selectedAgenda?.request.requestId)
        .delete()
        .catch(() => {
          showToastError(
            "Un problème est survenu durant la suppression de l'ancienne reservation dans le calendrier"
          )
        })
        .finally(() => {
          // Update worksite data
          Firestore.collection(
            `worksites/${selectedAgenda?.worksite.uid}/bookings`
          )
            .doc(selectedAgenda?.request.requestId)
            .update(worksiteModifications)
            .catch(() =>
              showToastError(
                'Un problème est survenu durant la mise a jour de la reservation'
              )
            )
            .finally(() => {
              setSelectedAgenda(null)
              setShowBookingDetails(false)
              setCancelMode(false)
              setRepportDate(null)
              setIsLoading(true)
              setReloadData(true)
            })
        })

      Functions.httpsCallable('SendMailAdminRepportDate')({
        cancelMode: true,
        worksiteId: selectedAgenda?.worksite.uid,
        phase: selectedAgenda?.request.phase,
        week: worksiteModifications.weekRequested,
      })
    }
  }

  const onSaveUnavailableDate = () => {
    if (unavailableAgenda.date && unavailableAgenda.team) {
      const parts = unavailableAgenda.date.split('/')

      const week = dayjs()
        .set('year', +parts[2]!)
        .set('month', +parts[1]!)
        .set('date', +parts[0]!)
        .subtract(1, 'month')
        .week()

      const item = {
        date: unavailableAgenda.date,
        lock: true,
        lockReason: unavailableAgenda.reason,
        slots: unavailableAgenda.slots,
        team: unavailableAgenda.team.id,
        worksite: 'virtual-worksite',
      }

      if (!unavailableAgenda.alreadyValidated) {
        Firestore.collection('calendar')
          .doc(parts[2]!)
          .collection(`S${week.toString()}`)
          .doc()
          .set(item)
          .finally(() => {
            onSelectingUnavailableDate(1, '', undefined, undefined, false)
            setIsLoading(true)
            setReloadData(true)
          })
      } else {
        Firestore.collection('calendar')
          .doc(parts[2]!)
          .collection(`S${week.toString()}`)
          .where('team', '==', unavailableAgenda.team.id)
          .where('date', '==', unavailableAgenda.date)
          .where('lock', '==', true)
          .get()
          .then((snapshot) => {
            const item = snapshot.docs[0]
            Firestore.collection('calendar')
              .doc(parts[2]!)
              .collection(`S${week.toString()}`)
              .doc(item.id)
              .update({
                slots: unavailableAgenda.slots,
                lockReason: unavailableAgenda.reason,
              })
          })
          .finally(() => {
            setIsLoading(true)
            setReloadData(true)
          })
      }
    }
  }

  const closeWithoutSaving = () => {
    agenda.map(async (a) => {
      if (a.request.requestId === selectedAgenda?.request.requestId) {
        const original = await Firestore.collection(
          `calendar/${selectedAgenda?.request.yearRequested}/S${selectedAgenda?.request.weekRequested}`
        )
          .doc(selectedAgenda?.request.requestId)
          .get()
        const data = original.data() as AgendaRequest
        const newAgenda = a
        newAgenda.date = data.date
        newAgenda.slots = +data.slots!
        setAgenda((prevAgenda) => {
          return prevAgenda
            .filter((item) => a.request.requestId != item.request.requestId)
            .concat(newAgenda)
        })
      }
    })
    setAgenda(initialAgenda)
    setSelectedAgenda(null)
    setRequestValidation(null)
    setCancelMode(false)
  }

  const onCLickExistingBooking = (items: Agenda[], index: number) => {
    if (items.length === 0 || items.length < index) {
      return
    }
    const item = items[index]
    item.alreadyValidated = true
    setSelectedAgenda({
      ...item,
      originalSlots: item.slots,
      originalDate: item.date,
    })
    setShowBookingDetails(true)
  }

  const onCLickExistingUnavailable = (date: string, teamId: string) => {
    const item = agenda.find(
      (a) =>
        a.date === date &&
        a.team?.id === teamId &&
        a.lock === true &&
        a.worksite.uid === 'virtual-worksite'
    )

    let slotsUsed = 0

    agenda.forEach((a) => {
      if (a.date === date && a.team?.id === teamId && a.lock === false) {
        slotsUsed += a.slots || 0
      }
    })

    if (item) {
      setUnavailableAgenda({
        date,
        team: item.team,
        slots: item.slots!,
        reason: item.lockReason,
        alreadyValidated: true,
        maxSlots: 5 - slotsUsed,
      })
      setUnavailabilityMode(true)
    }
  }

  const onDeleteExistingUnavailable = () => {
    if (unavailableAgenda.date && unavailableAgenda.team) {
      const parts = unavailableAgenda.date.split('/')

      Firestore.collection('calendar')
        .doc(parts[2]!)
        .collection(`S${week.toString()}`)
        .where('team', '==', unavailableAgenda.team.id)
        .where('date', '==', unavailableAgenda.date)
        .where('lock', '==', true)
        .get()
        .then((snapshot) => {
          const item = snapshot.docs[0]
          Firestore.collection('calendar')
            .doc(parts[2]!)
            .collection(`S${week.toString()}`)
            .doc(item.id)
            .delete()
        })
        .finally(() => {
          setUnavailabilityMode(false)
          setUnavailableAgenda(originalUnavailableAgenda)
          setIsLoading(true)
          setReloadData(true)
        })
    }
  }

  return (
    <AdminDataContext.Provider
      value={{
        isLoading,
        setIsLoading,
        week,
        setWeek,
        year,
        setYear,
        appUsers,
        showBookingDetails,
        setShowBookingDetails,
        agenda,
        selectedAgenda,
        setSelectedAgenda,
        selectedAgendaSiteManager,
        requestValidation,
        setRequestValidation,
        closeWithoutSaving,
        onSelectingDate,
        onSaveDate,
        cancelMode,
        setCancelMode,
        reportDate,
        onCLickExistingBooking,
        unavailabilityMode,
        setUnavailabilityMode,
        onSelectingUnavailableDate,
        unavailableAgenda,
        onSaveUnavailableDate,
        onCLickExistingUnavailable,
        onDeleteExistingUnavailable,
      }}
    >
      {children}
    </AdminDataContext.Provider>
  )
}
