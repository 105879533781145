import React, { useContext, useEffect, useState } from 'react'
import { Form, Button, Container } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { Worksite } from '../../../../../shared/Interfaces'
import { Auth, Firestore } from '../../../Firebase/Firebase'
import { showToastError } from '../../../Helpers/ToastHelper'
import { getTimestamp } from '../../../Helpers/Various'
import { AuthContext } from '../../../Provider/AuthProvider'
import {
  UiButton,
  UiFormControl,
  UiFormSelect,
  UiHeader,
  UiTopContainer,
} from '../../UI'

const initialFormValues: Worksite = {
  uid: undefined,
  orderNumber: '',
  customerLastname: '',
  customerFirstName: '',
  customerAddress: '',
  gpsMapLink: '',
  area: 0,
  floor: false,
  owner: '',
  createdAt: getTimestamp(),
}

const AddWorksitePage = () => {
  const history = useHistory()

  const { user, appUser, authenticated } = useContext(AuthContext)

  const [formValues, setFormValues] = useState({
    ...initialFormValues,
    owner: user?.uid!,
  })
  const [formIsValid, setFormIsValid] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const floorOptions = [
    {
      key: 'Non',
      value: '0',
    },

    {
      key: 'Oui',
      value: '1',
    },
  ]

  const EventHandler = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    if (event.target.id === 'floor') {
      setFormValues({
        ...formValues,
        floor: event.target.value.toLocaleLowerCase() === 'oui',
      })
    } else {
      setFormValues({ ...formValues, [event.target.name]: event.target.value })
    }
  }

  const WorksiteActions = async (next: boolean) => {
    setIsLoading(true)
    const data = { ...formValues } as Worksite
    data.createdAt = getTimestamp()
    if (data.gpsMapLink) {
      data.gpsMapLink = `https://${data.gpsMapLink.split('https://').pop()}`
    }
    delete data.uid
    await Firestore.collection('worksites')
      .add(data)
      .then((docRef) => {
        if (!next) {
          history.push({
            pathname: '/dashboard',
          })
        } else {
          history.push({
            pathname: `/booking/${docRef.id}/add`,
          })
        }
      })
      .catch(() =>
        // eslint-disable-next-line prettier/prettier
        showToastError('Un problème est survenu durant j\'ajout du chantier')
      )
  }

  useEffect(() => {
    setFormIsValid(
      formValues.orderNumber.length > 0 &&
        formValues.customerFirstName.length > 0 &&
        formValues.customerLastname.length > 0 &&
        formValues.customerAddress.length > 0 &&
        formValues.area > 0
    )
  }, [formValues])

  return (
    <>
      <UiHeader
        isDashboardHeader={false}
        isAuthenticated={authenticated}
        history={history}
        role={appUser?.role}
        firesbaseAuth={Auth}
        title="Nouvelle fiche chantier"
        callbackHandler={() => history.push('/dashboard')}
      />
      <UiTopContainer
        className="mt-4 w-100 mw-100 overflow-auto"
        isLoading={isLoading}
      >
        <PageContainer className="pr-2 pl-2">
          <UiFormControl
            type="text"
            name="orderNumber"
            value={formValues.orderNumber.toString()}
            label="N° du bon de commande"
            placeholder="Saisissez le N° du bon de commande"
            required={true}
            onChange={(e) => EventHandler(e)}
          />
          <UiFormControl
            type="text"
            name="customerFirstName"
            value={formValues.customerFirstName}
            label="Nom du client"
            placeholder="Saisissez le nom du client"
            required={true}
            onChange={(e) => EventHandler(e)}
          />
          <UiFormControl
            type="text"
            name="customerLastname"
            value={formValues.customerLastname}
            label="Prénom du client"
            placeholder="Saisissez le prénom du client"
            required={true}
            onChange={(e) => EventHandler(e)}
          />
          <UiFormControl
            type="text"
            name="customerAddress"
            value={formValues.customerAddress}
            label="Adresse du chantier"
            placeholder="Saisissez l'adresse du chantier"
            required={true}
            onChange={(e) => EventHandler(e)}
          />
          <UiFormControl
            type="text"
            name="gpsMapLink"
            value={formValues.gpsMapLink || ''}
            label="Lien Google Map (facultatif)"
            placeholder="Lien Google Map"
            required={false}
            onChange={(e) => EventHandler(e)}
          />
          <UiFormSelect
            name="floor"
            label="Etage"
            options={floorOptions}
            onChange={(e) => EventHandler(e)}
          />
          <UiFormControl
            type="number"
            name="area"
            value={formValues.area.toString()}
            label="Surface utile"
            placeholder="Saisissez la surface utile"
            required={true}
            onChange={(e) => EventHandler(e)}
            withAppend={true}
            appendValue="M2"
          />
        </PageContainer>
      </UiTopContainer>
      <ActionButtons className="d-flex flex-column pr-0 pl-0 w-100 mw-100">
        <UiButton
          element={ActionButton}
          variant="secondary"
          type="button"
          onClick={() => WorksiteActions(true)}
          disabled={!formIsValid}
          value="Réserver une prestation"
        />
        <UiButton
          element={ActionButton}
          variant="link"
          type="button"
          onClick={() => WorksiteActions(false)}
          disabled={!formIsValid}
          value="Créer la fiche sans réserver"
        />
      </ActionButtons>
    </>
  )
}

export default AddWorksitePage

const PageContainer = styled(Form)`
  label {
    font-weight: bold;
  }
`

const ActionButton = styled(Button)`
  border: 0px;
  border-radius: 0;
  text-transform: uppercase;
  :nth-child(2) {
    margin-top: 0.5rem;
  }
`

const ActionButtons = styled(Container)`
  flex: 0 0 auto;
  background-color: #fff;
`
