import { Form } from 'react-bootstrap'
interface Props {
  type: string
  name: string
  value: string
  placeholder: string
  required: boolean
  onChange: (event: any) => void
  inputKey?: string
  disabled?: boolean
  min?: number
  max?: number
}

const UiFormInput = (props: Props) => {
  return (
    <Form.Control
      type={props.type}
      name={props.name}
      placeholder={props.placeholder}
      value={props.value}
      onChange={props.onChange}
      required={props.required}
      min={props.min ? props.min : undefined}
      max={props.max ? props.max : undefined}
      key={props.inputKey ? props.inputKey : undefined}
      disabled={props.disabled || false}
    />
  )
}

export default UiFormInput
