import { useContext, useState, useEffect } from 'react'
import { Button, Container, ListGroup, Table } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { ServibatDocument } from '../../../../../shared/Interfaces'
import { Auth, Firestore, Storage } from '../../../Firebase/Firebase'
import { showToastError } from '../../../Helpers/ToastHelper'
import { AuthContext } from '../../../Provider/AuthProvider'
import { AppColors } from '../../../Ressources/AppColors'
import { IconDownload } from '../../../Ressources/AppIcons'
import { UiButton, UiFormLabel, UiHeader, UiTopContainer } from '../../UI'

const MyAccountPage = () => {
  const history = useHistory()
  const { appUser, authenticated, user } = useContext(AuthContext)

  const [isLoading, _setIsLoading] = useState(true)
  const [documents, setDocuments] = useState<ServibatDocument[]>()

  const handleLogout = (event: any) => {
    event.preventDefault()
    Auth.signOut().then((_res) => {
      history.push('/auth')
    })
  }

  // TODO: Soucis de CORS
  const _documentClickHandler = (file: ServibatDocument) => {
    if (file.url) {
      fetch(file.url)
        .then((resp) => resp.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.style.display = 'none'
          a.href = url
          a.download = file.clearName
          document.body.appendChild(a)
          a.click()
          window.URL.revokeObjectURL(url)
        })
        .catch((e) => console.log('Ohhhh no', e))
    }
  }

  useEffect(() => {
    const fetchDocuments = async () => {
      Firestore.collection('internal-docs')
        .get()
        .then(async (snapshot) => {
          const documents: ServibatDocument[] = []
          const results = await Promise.all(
            snapshot.docs.map(async (item) => {
              const data = item.data() as ServibatDocument
              if (!data) {
                return
              }

              const fileExt = data.name.split('.').pop()
              const clearName = data.title.split(' ').join('_').toLowerCase()
              data.clearName = `${clearName}.${fileExt}`

              await Storage.ref(`docs-servibat/${data.name}`)
                .getDownloadURL()
                .then((url: string) => {
                  data.url = url
                })
              return data
            })
          )
          results.forEach((result) => {
            if (result) {
              documents.push(result)
            }
          })
          setDocuments(documents)
        })
        .catch(() =>
          showToastError(
            'Un problème est survenu durant la récupération des documents'
          )
        )
        .finally(() => _setIsLoading(false))
    }

    fetchDocuments()
  }, [])

  return (
    <>
      <UiHeader
        isDashboardHeader={false}
        isAuthenticated={authenticated}
        history={history}
        role={appUser?.role}
        firesbaseAuth={Auth}
        title="Mon compte"
        callbackHandler={() => history.push('/dashboard')}
      />
      <UiTopContainer
        className="pr-0 pl-0 w-100 mw-100 pb-4"
        isLoading={isLoading}
      >
        <div className="content">
          {appUser?.role === 'site-manager' && (
            <>
              <UiFormLabel
                value="Documents Servibat"
                className="bg-grey text-uppercase"
              />
              <ListGroup>
                {documents &&
                  documents.map((document, index) => (
                    <DocumentItem
                      key={index}
                      className="d-flex justify-content-between"
                    >
                      {document.title}
                      <a href={document.url} target="_blank">
                        <IconDownload
                          title={`télécharger le document ${document.title}`}
                        />
                      </a>
                    </DocumentItem>
                  ))}
              </ListGroup>
              <UiFormLabel
                value="Mes informations"
                className="bg-grey text-uppercase mt-3"
              />
              <ListGroup>
                <InfoItem className="d-flex justify-content-between">
                  Nom <span>{appUser.lastName}</span>
                </InfoItem>
                <InfoItem className="d-flex justify-content-between">
                  Prénom <span>{appUser.firstName}</span>
                </InfoItem>
                <InfoItem className="d-flex justify-content-between">
                  Fonction <span>{appUser.function}</span>
                </InfoItem>
                <InfoItem className="d-flex justify-content-between">
                  Entreprise{' '}
                  <span>{appUser.company && appUser.company.name}</span>
                </InfoItem>
                <InfoItem className="d-flex justify-content-between">
                  Adresse{' '}
                  <span>{appUser.company && appUser.company.address}</span>
                </InfoItem>
                <InfoItem className="d-flex justify-content-between">
                  Téléphone <span>{appUser.phoneNumber}</span>
                </InfoItem>
                <InfoItem className="d-flex justify-content-between">
                  E-mail <span>{(user && user.email) || ''}</span>
                </InfoItem>
                <InfoItem className="d-flex justify-content-between">
                  Siret <span>{appUser.siret}</span>
                </InfoItem>
                <InfoItem className="d-flex justify-content-between">
                  N° TVA <span>{appUser.tvaNumber}</span>
                </InfoItem>
              </ListGroup>
              <UiFormLabel
                value="Nos réseaux sociaux"
                className="bg-grey text-uppercase"
              />
              <SocialNetworksContainer size="sm">
                <tbody>
                  <tr>
                    <td className="sn-logo">
                      <img
                        src="/images/mails/web.png"
                        alt="Web image"
                        width="30"
                        height="30"
                      />
                    </td>
                    <td className="sn-right">
                      <a href="https://servibat-valluth.fr/" target="_blank">
                        Site web
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td className="sn-logo">
                      <img
                        src="/images/mails/facebook.png"
                        alt="facebook image"
                        width="30"
                        height="30"
                      />
                    </td>
                    <td className="sn-right">
                      <a
                        href="https://www.facebook.com/Servibat.Valluth"
                        target="_blank"
                      >
                        Facebook
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td className="sn-logo">
                      <img
                        src="/images/mails/instagram.png"
                        alt="instagram image"
                        width="30"
                        height="30"
                      />
                    </td>
                    <td className="sn-right">
                      <a
                        href="https://www.instagram.com/servibat.svbt/?hl=fr"
                        target="_blank"
                      >
                        Instagram
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td className="sn-logo">
                      <img
                        src="/images/mails/linkedin.png"
                        alt="Web image"
                        width="30"
                        height="30"
                      />
                    </td>
                    <td className="sn-right">
                      <a
                        href="https://www.linkedin.com/company/servibat-svbt"
                        target="_blank"
                      >
                        Linkedin
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td className="sn-logo">
                      <img
                        src="/images/mails/youtube.png"
                        alt="Web image"
                        width="30"
                        height="30"
                      />
                    </td>
                    <td className="sn-right">
                      <a
                        href="https://www.youtube.com/channel/UCrlUMuB1p3Bz1Hj1SkQGoRQ"
                        target="_blank"
                      >
                        Youtube
                      </a>
                    </td>
                  </tr>
                </tbody>
              </SocialNetworksContainer>
            </>
          )}
        </div>
      </UiTopContainer>
      <ActionButtons className="d-flex flex-column pr-0 pl-0 w-100 mw-100">
        <UiButton
          element={Button}
          variant="secondary"
          type="button"
          onClick={handleLogout}
          disabled={false}
          value="Déconnexion"
          className="text-uppercase btn-full"
        />
      </ActionButtons>
    </>
  )
}

export default MyAccountPage

const ActionButtons = styled(Container)`
  background-color: #fff;
`

const DocumentItem = styled(ListGroup.Item)`
  height: 50px;
  border-radius: unset !important;
  border: unset;
  border-bottom: 1px solid ${AppColors.greyDark};
  font-weight: bold;

  svg {
    cursor: pointer;
  }
`
const InfoItem = styled(ListGroup.Item)`
  height: 40px;
  border-radius: unset !important;
  border: unset;
  font-weight: bold;

  span {
    font-weight: normal;
  }
`
const SocialNetworksContainer = styled(Table)`
  .sn-logo {
    width: 55px;
  }

  .sn-right {
    vertical-align: middle;
  }
`
