import { Form } from 'react-bootstrap'

interface Value {
  key: string
  value: string
}

interface Props {
  name: string
  label: string
  options: Value[]
  selectedOption?: string
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void
  className?: string
  disabled?: boolean
}

const UiFormSelect = (props: Props) => {
  const defaultValue = !props.selectedOption
    ? props.options[0].value
    : props.options.find(
        (o) =>
          o.key.toLocaleLowerCase() ===
          props.selectedOption?.toLocaleLowerCase()
      )?.value

  return (
    <Form.Group
      controlId={`${props.name}`}
      className={`text-left ${props.className ? `${props.className}` : ''}`}
    >
      <Form.Label>{props.label}</Form.Label>
      <Form.Control
        as="select"
        custom
        onChange={props.onChange}
        defaultValue={defaultValue}
        disabled={props.disabled || false}
      >
        {props.options.map((option, index) => {
          return (
            <option
              key={index}
              defaultValue={option.value}
              selected={option.value === props.selectedOption}
            >
              {option.key}
            </option>
          )
        })}
      </Form.Control>
    </Form.Group>
  )
}

export default UiFormSelect
